const defaultOptions = {
  includeFractionDigits: false,
};

export function formatPrice(price, options = defaultOptions) {
  if (!price) return "-";
  const centsStr = price.amount.toString();
  const fraction = centsStr.slice(-2).padStart(2, "0");
  const integer = centsStr.slice(0, -2);
  const currency = price.currency === "cad" ? "usd" : price.currency;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: options.includeFractionDigits ? 2 : fraction !== "00" ? 2 : 0,
  });
  return formatter.format(`${integer}.${fraction}`);
}

export function formatPriceWithoutCurrency(price) {
  if (!price) return "";
  const centsStr = price.amount.toString();
  const fraction = centsStr.slice(-2).padStart(2, "0");
  const integer = centsStr.slice(0, -2);
  return `${integer}.${fraction}`;
}
