import styled, {css} from "styled-components";
import React from "react";

export const buttonStyle = css`
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  background-color: transparent;
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    outline: none;
  }
`;

const ButtonContainer = styled.button`
  ${buttonStyle}
`;

export default function (props) {
  return <ButtonContainer {...props} />;
}
