class User {
  userObject = null;

  constructor(userObject) {
    this.userObject = userObject;
    Object.freeze(userObject);
  }
}

export default User;
