import React from 'react';

function IconButton({
  styles,
  onClick,
  AltIcon,
  Icon,
  showAltIcon,
  iconColor,
  ButtonComponent
}) {
  return (
    <ButtonComponent styles={styles} onClick={onClick}>
      {showAltIcon ? <AltIcon color={iconColor} /> : <Icon color={iconColor} />}
    </ButtonComponent>
  );
}

export default IconButton;
