import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import Checkbox from "@ui/components/Checkbox";

export default function FilterOption({
  label,
  value,
  count,
  isSelected,
  onClick,
  disabled,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container disabled={disabled} onClick={() => onClick(value)} styles={_styles.root}>
      <Checkbox checked={isSelected} styles={_styles.checkbox} />
      <Label htmlFor={value} disabled={disabled} styles={_styles.root}>
        {label}
        {count && ` (${count})`}
      </Label>
    </Container>
  );
}

const Container = styled.li`
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0 0 10px;
`;

const Label = styled.label`
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  root: {
    margin: "0",
    padding: "4px 0",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      sm: "14px",
    },
    fontWeight: 400,
  },
};

FilterOption.defaultProps = {
  styles: defaultStyles,
};

FilterOption.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  count: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.shape({}),
};
