import {useEffect} from "react";
import {document} from "browser-monads";

export default function useClickOutside(wrapperRef, onClickOutside) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const element = wrapperRef.current;
      if (element && !element.contains(event.target) && !element.contains(event.target)) {
        onClickOutside(event);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside, wrapperRef]);
}
