export class CheckoutDocumentConstraint {
  static DocumentTypes = {
    driversLicense: "drivers_license",
    medicalId: "medical_id",
    selfieId: "selfie_id",
  };

  static DocumentLabels = {
    drivers_license: "Driver's License",
    medical_id: "Medical ID",
    selfie_id: "Selfie ID",
  };

  static ConstraintTypes = {
    optional: "optional",
    required: "required",
    hidden: "hidden",
  };

  constructor(type, constraint = "optional") {
    this._type = type;
    this._constraint = constraint;
  }

  validate(user, toggles, deliveryType) {
    if (this.isRequired()) {
      const documentLabel = CheckoutDocumentConstraint.DocumentLabels[this._type];
      return this.userHasDocument(user, this._type, toggles, deliveryType)
        ? []
        : [{document: this._type, error: "required", documentLabel}];
    }

    return [];
  }

  type() {
    return this._type;
  }

  userHasDocument = (user, type, toggles, deliveryType) => {
    if (!user) return false;
    switch (type) {
      case CheckoutDocumentConstraint.DocumentTypes.driversLicense:
        return user.hasVerifiedDocs(deliveryType);
      case CheckoutDocumentConstraint.DocumentTypes.medicalId:
        if (!toggles) return user.hasVerifiedDocs(deliveryType);

        if (toggles.displayMedicalIdFields()) {
          return user.getMedicalIdNumber() && user.getMedicalIdExpirationDate();
        } else if (toggles.displayMedicalIdDocument()) {
          return user.hasMedicalId();
        } else {
          return true;
        }
      case CheckoutDocumentConstraint.DocumentTypes.selfieId:
        return user.hasVerifiedDocs(deliveryType);
      default:
        return false;
    }
  };

  isRequired() {
    return CheckoutDocumentConstraint.ConstraintTypes.required === this._constraint;
  }
  isOptional() {
    return CheckoutDocumentConstraint.ConstraintTypes.optional === this._constraint;
  }
  isHidden() {
    return CheckoutDocumentConstraint.ConstraintTypes.hidden === this._constraint;
  }
}
