import styled from "styled-components";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";

export const widthLimit = "1440px";

export default function widthLimited(Component) {
  return styled(Component)`
    box-sizing: border-box;
    max-width: ${widthLimit};

    padding: ${({styles}) => styles?.padding?.lg || `0 ${spacing(4)}`};

    ${media.down("md")} {
      padding: ${({styles}) => styles?.padding?.md || `0 ${spacing(2)}`};
    }

    ${media.down("sm")} {
      padding: ${({styles}) => styles?.padding?.sm || `0 ${spacing(2)}`};
    }
  `;
}
