import {useEffect, useState} from "react";
import useCartState from "src/core/checkout/hooks/useCartState";

export default function useCartProduct({product}) {
  const [cartState] = useCartState();
  const cart = cartState.data;
  const cartItem = cart && product && cart.getItem(product);

  useEffect(() => {
    if (cartItem) {
      setAddToCartQuantity(cartItem.getQuantity());
    }
  }, [cartItem]);

  const [addToCartQuantity, setAddToCartQuantity] = useState(1);

  return {
    addToCartQuantity,
    setAddToCartQuantity,
    product,
    cartItem,
  };
}
