import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import media from '@ui/utils/media';
import spacing from '@ui/utils/spacing';

function ProductListHeaderSkeleton({ styles }) {
  return (
    <div style={{ width: '100%' }}>
      <TitleSkeleton styles={styles} width={'200px'} />
      <DescriptionSkeleton styles={styles} count={2} />
    </div>
  );
}

const TitleSkeleton = styled(Skeleton)`
  border-radius: 5px;
  height: calc(${({ styles }) => styles.title.fontSize.lg} * 2);
  ${media.down('md')} {
    height: calc(${({ styles }) => styles.title.fontSize.md} * 2);
  }
  ${media.down('sm')} {
    height: calc(${({ styles }) => styles.title.fontSize.sm} * 2);
  }
  margin-bottom: ${spacing(2)};
`;
const DescriptionSkeleton = styled(TitleSkeleton)`
  height: ${({ styles }) => styles.title.fontSize.lg};
  width: ${({ styles }) => styles.descriptionContainer.maxWidth.lg};
  ${media.down('md')} {
    height: ${({ styles }) => styles.title.fontSize.md};
    width: ${({ styles }) => styles.descriptionContainer.maxWidth.md};
  }
  ${media.down('sm')} {
    height: ${({ styles }) => styles.title.fontSize.sm};
    width: ${({ styles }) => styles.descriptionContainer.maxWidth.sm};
  }
  max-width: 100%;
  margin-bottom: 0;
`;

export default ProductListHeaderSkeleton;
