import styled from "styled-components";
import spacing from "../../utils/spacing";
import media from "../../utils/media";

export const InnerContainer = styled.div.attrs(() => ({
  className: "big-quantity-picker big-quantity-picker__inner-container",
}))`
  display: flex;
  justify-content: space-between;

  align-items: ${({hasPriceDisplay, isWeightedProduct}) =>
    hasPriceDisplay && !isWeightedProduct ? "stretch" : "center"};
  height: ${({hasPriceDisplay, isWeightedProduct}) =>
    hasPriceDisplay && !isWeightedProduct ? "100%" : "70px"};
  margin-top: ${spacing(2)};
  column-gap: 8px;

  ${media.down("sm")} {
    width: 100%;
    flex-direction: ${({styles}) => styles?.flexDirection?.sm || "row"};
    align-items: ${({styles}) => styles?.alignItems?.sm || "center"};
    height: ${({styles}) => (styles?.flexDirection?.sm === "column" ? "100px" : "70px")};
    row-gap: 8px;
  }

  max-width: 100vw;
`;

export const Controller = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30%;
  min-height: 30%;
  text-align: center;
  cursor: pointer;

  ${media.up("lg")} {
    width: ${({styles}) => styles.controllersWidth.lg};
    height: ${({styles}) => styles.controllersHeight.lg};
  }
  ${media.between("md", "lg")} {
    width: ${({styles}) => styles.controllersWidth.md};
    height: ${({styles}) => styles.controllersHeight.md};
  }
  ${media.down("sm")} {
    width: ${({styles}) => styles.controllersWidth.sm};
    height: ${({styles}) => styles.controllersHeight.sm};
  }

  > * {
    color: ${({styles}) => styles.controllersColor};
  }
`;

export const RemoveIconComponentContainer = styled.div`
  > svg {
    color: ${({styles}) => styles.color};
  }
`;

export const Savings = styled.div`
  margin-top: ${spacing(1)};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
`;
