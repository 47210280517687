import {useEffect, useState} from "react";

export class TimeBoundary {
  constructor(startDateTime, waitTimeInSeconds) {
    this.startDateTime = startDateTime;
    this.waitTimeInSeconds = waitTimeInSeconds;
  }

  get waitTime() {
    return {
      seconds: this.waitTimeInSeconds,
    };
  }
}

function useTimeBoundEffect(timeBoundary, action, isDisabled) {
  const enabled = !isDisabled;
  const initialState = enabled ? {elapsedSeconds: 0} : null;
  const [elapsedTime, setElapsedTime] = useState(initialState);

  const calculateElapsedTime = (startDatetime) => {
    const difference = +new Date() - startDatetime;
    const elapsedSeconds = Math.floor((difference / 1000) % 60);
    return {elapsedSeconds};
  };
  useEffect(() => {
    if (enabled) {
      const timeoutId = setTimeout(() => {
        const elapsedTime = calculateElapsedTime(timeBoundary.startDateTime);
        if (elapsedTime.elapsedSeconds < timeBoundary.waitTime.seconds) {
          setElapsedTime(elapsedTime);
        } else {
          action();
        }
      }, 300);
      return function cleanup() {
        clearTimeout(timeoutId);
      };
    }
  }, [elapsedTime, action, timeBoundary, isDisabled, enabled]);

  return [elapsedTime, setElapsedTime];
}

export default useTimeBoundEffect;
