class Backend {
  loadCart(cartId) {
    throw new Error("Not Implemented");
  }

  createCart(cart) {
    throw new Error("Not implemented");
  }

  addItem(cart, item) {
    throw new Error("Not implemented");
  }

  deleteItem(cart, item) {
    throw new Error("Not implemented");
  }

  updateItem(cart, item) {
    throw new Error("Not implemented");
  }

  createOrder(cart, additionalInfo) {
    throw new Error("Not implemented");
  }

  getOrder(orderId) {
    throw new Error("Not implemented");
  }

  validateCart(cart, additionalInfo) {
    throw new Error("Not implemented");
  }

  setBillingAddress(cart, billingAddress) {
    throw new Error("Not implemented");
  }

  setShippingAddress(cart, shippingAddress) {
    throw new Error("Not implemented");
  }

  setShippingMode(cart, shippingMode) {
    throw new Error("Not implemented");
  }

  pay(cart, payment) {
    throw new Error("Not implemented");
  }

  setPromoCodes(cart, promoCodes) {
    throw new Error("Not implemented");
  }

  setReward(cart, reward) {
    throw new Error("Not implemented");
  }

  setSchedule(cart, schedule) {
    throw new Error("Not implemented");
  }

  setPaymentOption(cart, paymentOption) {
    throw new Error("Not implemented");
  }
  handleValidationErrors(currentCart, error) {
    return {cart: currentCart, errors: error};
  }

  hasCart() {
    return true;
  }
}

export default Backend;
