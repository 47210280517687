import React from "react";
import dynamic from "next/dynamic";
import BaseThemeSortFilters from "src/themes/baseTheme/elements/filters/SortFilters/SortFilters";

const ProductFiltersWrapper = dynamic(() =>
  import("src/themes/flight/elements/filters/DrawerProductFilters")
);

export default function FlightActionsSortFilter({
  filters,
  onChangeFilter,
  filtersDisplayOptions,
}) {
  return (
    <>
      <ProductFiltersWrapper
        displayOptions={filtersDisplayOptions}
        onChangeFilter={onChangeFilter}
      />
      <BaseThemeSortFilters filters={filters} onChangeFilter={onChangeFilter} />
    </>
  );
}
