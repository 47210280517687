import useRouter from "src/core/common/hooks/useRouter";
import {useRouter as useNextRouter} from "next/router";
import {useHost} from "src/core/common/hostContext";
import {getSiteInCluster} from "src/server/clusters";

function useSchemaContext() {
  const router = useRouter();
  const nextRouter = useNextRouter();
  const currentHost = useHost();
  const canonicalHost = getSiteInCluster(currentHost)?.canonicalDomain;

  return {router, basePath: nextRouter.basePath, host: canonicalHost};
}

export default useSchemaContext;
