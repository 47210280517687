import mediaHelper from "styled-media-helper";

const media = mediaHelper({
  sm: 320,
  md: 768,
  lg: 1024,
});

export const contentMaxWidth = "1440px";

export default media;
