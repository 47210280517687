import React, {useState} from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import PropTypes from "prop-types";
import {DisplayOnly} from "@ui/components/Media";
import merge from "lodash/merge";

export default function DeliveryTypeOption({
  condition,
  optionType,
  optionText,
  OptionIcon,
  onClick,
  counterCondition,
  counter,
  selectedDeliveryType,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const [isHover, setIsHover] = useState(false);

  if (!condition) return null;

  return (
    <OptionContainer
      styles={_styles}
      selected={selectedDeliveryType === optionType}
      hasCounter={counterCondition}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <DisplayOnly dims={["lg", "md"]}>
        <span>{optionText}</span>
      </DisplayOnly>
      <DisplayOnly dims={["sm"]}>
        {OptionIcon ? (
          <OptionIcon
            color={
              selectedDeliveryType === optionType || isHover
                ? `${_styles.deliveryPickers.hoverTextColor}`
                : `${_styles.deliveryPickers.iconColor}`
            }
            padding="0"
            size="24px"
          />
        ) : (
          <span>{optionText}</span>
        )}
      </DisplayOnly>
      {counterCondition && (
        <DeliveryTypeCounter
          styles={_styles}
          selected={selectedDeliveryType === optionType}
        >
          {counter}
        </DeliveryTypeCounter>
      )}
    </OptionContainer>
  );
}
const DeliveryTypeCounter = styled.div`
  font-family: ${({styles}) => styles.root.fontFamily};
  background-color: ${({styles, selected}) =>
    !selected
      ? styles.deliveryPickers.hoverSelectedBackgroundColor
      : styles.deliveryPickers.hoverTextColor};
  color: ${({styles, selected}) =>
    !selected
      ? styles.deliveryPickers.hoverTextColor
      : styles.productsCounter.selectedColor};

  font-size: ${({styles}) => styles.productsCounter.fontSize.sm};
  padding: 4px;
  min-width: 24px;
  box-sizing: border-box;
  height: 24px;
  border-radius: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2px;
`;

const OptionContainer = styled.div.attrs(() => ({
  className: "new-delivery-type-picker__option",
}))`
  min-width: 100px;
  display: flex;
  column-gap: 8px;
  text-align: center;
  height: 42px;
  cursor: pointer;
  font-weight: ${({styles}) => styles.root.fontWeight};
  color: ${({styles, selected}) =>
    selected
      ? `${styles.deliveryPickers.selectedColor}`
      : `${styles.deliveryPickers.color}`};
  background-color: ${({styles, selected}) =>
    selected ? `${styles.deliveryPickers.hoverSelectedBackgroundColor}` : "transparent"};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  padding: 1px 5px;
  border-radius: ${({styles}) => styles.deliveryPickers.borderRadius || "4px"};

  &:hover {
    background-color: ${({styles}) =>
      styles.deliveryPickers.hoverSelectedBackgroundColor};
    color: ${({styles}) => styles.deliveryPickers.hoverTextColor};

    ${DeliveryTypeCounter} {
      background-color: ${({styles}) => styles.deliveryPickers.hoverTextColor};
      color: ${({styles}) => styles.root.color};
    }
  }

  font-size: ${({styles}) => (styles.root.fontSize ? styles.root.fontSize.lg : "16px")};

  ${media.down("md")} {
    padding: 1px 8px;
    font-size: ${({styles}) => (styles.root.fontSize ? styles.root.fontSize.lg : "14px")};
  }

  ${media.down("sm")} {
    padding: 1px 8px;
    width: ${({hasCounter}) => (hasCounter ? "auto" : "30px")};
    min-width: ${({hasCounter}) => (hasCounter ? "45px" : "30px")};
    font-size: ${({styles}) => (styles.root.fontSize ? styles.root.fontSize.lg : "12px")};
    border-radius: ${({styles}) => styles.deliveryPickers.borderRadius || "4px"};
  }
`;

const defaultStyles = {
  productsCounter: {
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
  },
};

DeliveryTypeOption.defaultProps = {
  styles: defaultStyles,
};

DeliveryTypeOption.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      color: PropTypes.string,
    }),
    deliveryPickers: PropTypes.shape({
      hoverTextColor: PropTypes.string,
    }),
    fontFamily: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    selectedColor: PropTypes.string,
    hoverSelectedBackgroundColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    hoverTextColor: PropTypes.string,
    iconColor: PropTypes.string,
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
    counter: PropTypes.shape({
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
  }),
  condition: PropTypes.bool,
  optionType: PropTypes.string,
  optionText: PropTypes.string,
  OptionIcon: PropTypes.elementType,
  onClick: PropTypes.func,
  counterCondition: PropTypes.bool,
  counter: PropTypes.number,
  selectedDeliveryType: PropTypes.oneOf(["pickup", "kiosk", "delivery", "unavailable"]),
};
