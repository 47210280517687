import styled from 'styled-components';
import spacing from '@ui/utils/spacing';

export default styled.div.attrs(() => ({
  className: 'delivery-banner delivery-banner__picker-container'
}))`
  display: flex;
  align-items: center;
  color: ${({ styles }) => styles.color};
  font-weight: ${({ styles }) => styles.fontWeight};
  text-decoration: ${({ styles }) => styles.textDecoration};
  column-gap: ${spacing(1)};
  a {
    white-space: nowrap;
    cursor: pointer;
    color: ${({ styles }) => styles.color};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    ${({ ellipsize }) =>
      ellipsize
        ? `
      max-width: 45vw;
      text-overflow: ellipsis;
      overflow: hidden;
    `
        : ''}
  }

  > svg {
    width: 24px;
    height: 24px;
  }
`;
