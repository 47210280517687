import get from "lodash/get";
import set from "lodash/set";

export function populateReference(obj, basePath) {
  const propValue = basePath ? get(obj, basePath, {}) : "";
  if (isReference(propValue)) {
    set(obj, basePath, getPropValueRec(obj, basePath, [basePath]));
  }
}

function isReference(maybeRef) {
  if (typeof maybeRef !== "string") return false;

  const refRegexp = /^{.*}$/g;
  return maybeRef.match(refRegexp);
}

function getPropValueRec(obj, propPath, dependingProps = []) {
  const refPropValue = get(obj, propPath, "");
  if (dependingProps.indexOf(refPropValue) > -1) {
    throw new Error(`Circular dependency found in theme object.`);
  } else if (isReference(refPropValue)) {
    return getPropValueRec(obj, cleanRef(refPropValue), [...dependingProps, propPath]);
  } else {
    return refPropValue;
  }
}

function cleanRef(maybeRef) {
  if (typeof maybeRef !== "string") return maybeRef;
  return maybeRef.replace("{", "").replace("}", "");
}
