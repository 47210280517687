export default {
  REQUEST_ADDRESS_AUTO_COMPLETE_SUCCESSFUL:
    "deliveries/request-address-auto-complete-failure",
  REQUEST_ADDRESS_AUTO_COMPLETE_FAILURE:
    "deliveries/request-address-auto-complete-successful",
  REQUEST_ADDRESS_AUTO_COMPLETE: "deliveries/request-address-auto-complete",

  REQUEST_VERIFY_ADDRESS_SUCCESSFUL: "deliveries/request-verify-address-successful",
  REQUEST_VERIFY_ADDRESS_FAILURE: "deliveries/request-verify-address-failure",
  REQUEST_VERIFY_ADDRESS: "deliveries/request-verify-address",

  SELECT_DELIVERY_TYPE: "deliveries/select-delivery-type",
  SELECT_DELIVERY_TYPE_SUCCESSFUL: "deliveries/select-delivery-type-successful",

  SELECT_DELIVERY_ADDRESS: "deliveries/select-delivery-address",
  SELECT_DELIVERY_ADDRESS_SUCCESSFUL: "deliveries/select-delivery-address-successful",

  SET_DELIVERY_MODE: "deliveries/set-delivery-mode",
};
