import React from "react";
import ShoppingCartButton from "../../../../../checkout/components/ShoppingCartButton";
import CartModalService from "../../../../../checkout/components/cart/cartModalService";

function ShoppingCart({
  icon,
  width,
  height,
  componentsColor,
  cartButtonColor,
  className,
  showItems,
  themeProperties = {},
}) {
  function onClickShoppingCart() {
    CartModalService.show();
  }
  return (
    <ShoppingCartButton
      showItems={showItems}
      icon={icon}
      width={width}
      height={height}
      className={className}
      onClick={onClickShoppingCart}
      componentsColor={componentsColor}
      cartButtonColor={cartButtonColor}
      themeProperties={themeProperties}
    />
  );
}

export default ShoppingCart;
