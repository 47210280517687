export const DeliveryModes = {
  ASAP: "asap",
  SCHEDULED: "scheduled",
  EXPRESS: "express",
};

export const DeliveryModeUnavailableReason = {
  RESTRICTED_ZIP_CODE: "restricted_zip_code",
  NO_PRODUCTS: "no_products",
  CLOSED: "closed",
};

export const DeliveryModeUnavailableMessage = {
  DELIVERIES: "Deliveries are not available for this address.",
  SCHEDULED: {
    CLOSED: "We are currently closed for scheduled deliveries to this address.",
    NO_PRODUCTS: "We don't do scheduled deliveries to that location.",
  },
  EXPRESS: {
    RESTRICTED_ZIP_CODE: "Express delivery is not available for this address.",
    CLOSED: "Express delivery is closed at this time.",
    NO_PRODUCTS: "We don't have products available near this area for express delivery.",
  },
};

export const DeliveryAlternativeMessage = {
  DELIVERIES: {
    SCHEDULED: "Try scheduled delivery, instead.",
    EXPRESS: "Try express delivery, instead.",
  },
  PICKUP: "If offered, try ordering for pickup instead.",
  FALLBACK: {
    LINK: "Go to the store location or try using another address.",
    NONE: "Try using another location.",
  },
};
