import {getPriceAndCurrencyFromProduct} from "../utils";

function makePriceSpecification(product, ctx) {
  const {price, currency} = getPriceAndCurrencyFromProduct(product, ctx);
  return {
    price: price,
    priceCurrency: currency,
    valueAddedTaxIncluded: product.isTaxIncluded().toString(),
  };
}

export default makePriceSpecification;
