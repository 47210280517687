import merge from "lodash/merge";
import base from "./base";
import makeThemeV2, {fromV1} from "./makeThemeV2";
import get from "lodash/get";

export default function makeTheme(themeObj, baseTheme = null, themeName = "default") {
  let _baseThemeV1 = get(baseTheme, "v1", baseTheme);
  let _themeObjV1 = get(themeObj, "v1", themeObj);
  let _themeObjV2 = get(themeObj, "v2");
  let _themeObjV3 = get(themeObj, "v3");
  const v1 = merge({}, base, _baseThemeV1 || {}, _themeObjV1 || {});
  let v2;
  if (!_themeObjV2 || themeName === "default") {
    v2 = fromV1(v1);
    if (_themeObjV2) {
      v2 = merge({}, v2, makeThemeV2(_themeObjV2));
    }
  } else {
    v2 = makeThemeV2(_themeObjV2);
  }

  const v3 = _themeObjV3 ? makeThemeV2(_themeObjV3) : null;

  return {
    v1,
    v2,
    v3,
  };
}
