import * as React from "react";
import PropTypes from "prop-types";

const EraserIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox={`0 0 ${width || "20"}  ${height || "20"}`}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2401 1.55998L19.1901 6.49998C19.9701 7.28998 19.9701 8.54998 19.1901 9.33998L10.0001 18.53C8.4401 20.09 5.9101 20.09 4.3401 18.53L0.810098 15C0.0300977 14.21 0.0300977 12.95 0.810098 12.16L11.4101 1.55998C12.2001 0.779976 13.4601 0.779976 14.2401 1.55998ZM2.2201 13.58L5.7601 17.11C6.5401 17.9 7.8001 17.9 8.5901 17.11L12.1201 13.58L7.1701 8.62998L2.2201 13.58Z"
        fill={color}
      />
    </svg>
  );
};

EraserIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default EraserIcon;
