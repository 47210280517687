import React, {useState} from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import styled from "styled-components";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import SideOption from "./SideOption";
import spacing from "@ui/utils/spacing";
import {CloseIcon} from "@ui/components/Icons";

export default function SideMenu({styles, isOpen, close, image, options, LinkComponent}) {
  const _styles = merge({}, defaultStyles, styles);
  const [selectedOption, setSelectedOption] = useState();

  return (
    <div>
      <Overlay styles={_styles.root.overlay} isOpen={isOpen} onClick={close} />
      {isOpen && <CloseMenuIcon styles={_styles.root.close} onClick={close} />}
      <Container isOpen={isOpen}>
        <LogoSection styles={_styles.root.logo}>
          <ConfigurableImage source={image} alt="" width={90} />
        </LogoSection>
        <OptionsContainer show={selectedOption}>
          {!selectedOption &&
            options &&
            options.map((option, index) => (
              <SideOption
                key={index}
                close={close}
                LinkComponent={LinkComponent}
                option={option}
                setSelectedOption={setSelectedOption}
                styles={_styles.options}
              />
            ))}
        </OptionsContainer>
        <SubOptionsContainer show={selectedOption}>
          {selectedOption?.options && (
            <div>
              <SideOption
                selected={true}
                LinkComponent={LinkComponent}
                option={selectedOption}
                setSelectedOption={setSelectedOption}
                back={true}
                styles={_styles.options}
              />
              {selectedOption.options.map((option, index) => (
                <SideOption
                  key={index}
                  close={close}
                  LinkComponent={LinkComponent}
                  option={option}
                  setSelectedOption={() => {}}
                  styles={_styles.options}
                />
              ))}
            </div>
          )}
        </SubOptionsContainer>
      </Container>
    </div>
  );
}

const defaultStyles = {
  root: {
    overlay: {
      backgroundColor: "#000",
      opacity: "1",
      width: "100vw",
      height: "100vh",
    },
    close: {
      color: "#fff",
      width: "40px",
      height: "40px",
      right: "15px",
      top: "10px",
    },
    logo: {
      padding: "8px 20px",
      borderWidth: "0px 0px 1px 0px",
      borderColor: "rgba(0, 0, 0, 0.1)",
      maxWidth: "90px",
    },
  },
  options: {
    padding: "15px 10px 15px 20px",
    borderWidth: "0px 0px 1px 0px",
    borderColor: "rgba(0, 0, 0, 0.1)",
    selectedBorderColor: "rgba(0, 0, 0, 1)",
    color: "#000",
    fontFamily: "sans-serif",
    fontWeight: "400",
    textTransform: "uppercase",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
  },
};

const Overlay = styled.div.attrs(() => ({
  className: "side-menu__overlay",
}))`
  width: ${({styles}) => styles.width};
  height: ${({styles}) => styles.height};
  background-color: ${({styles}) => styles.backgroundColor};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  transform: translate3d(${({isOpen}) => (isOpen ? "0" : "100%")}, 0, 0);
  opacity: ${({isOpen, styles}) => (isOpen ? styles.opacity : "0")};
  transition: opacity 300ms ease-out;
`;

const CloseMenuIcon = styled(CloseIcon).attrs(() => ({
  className: "side-option__close-menu-icon",
}))`
  color: ${({styles}) => styles.color};
  position: fixed;
  right: ${({styles}) => styles.right};
  top: ${({styles}) => styles.top};
  z-index: 8;
  width: ${({styles}) => styles.width};
  height: ${({styles}) => styles.height};
`;

const Container = styled.div.attrs(() => ({
  className: "side-menu__container",
}))`
  display: flex;
  flex-direction: column;

  width: 400px;
  box-sizing: border-box;
  @media (max-width: 420px) {
    width: calc(100vw - ${spacing(8)});
  }
  background-color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  transform: translate3d(${({isOpen}) => (isOpen ? "0" : "-100%")}, 0, 0);
  box-shadow: ${(isOpen) => (isOpen ? "0 2px 18px 0 rgba(0, 0, 0, 0.3)" : "none")};
  transition: transform 300ms ease-out;
`;

const LogoSection = styled.div.attrs(() => ({
  className: "side-menu__logoSection",
}))`
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: ${({styles}) => styles.padding};
  box-sizing: border-box;
  border: solid;
  border-width: ${({styles}) => styles.borderWidth};
  border-color: ${({styles}) => styles.borderColor};

  > img {
    max-width: ${({styles}) => styles.maxWidth};
  }
`;

const OptionsContainer = styled.div.attrs(() => ({
  className: "side-menu__optionsContainer",
}))`
  transform: translate3d(${({show}) => (!show ? "0" : "-100%")}, 0, 0);
  transition: transform 300ms ease-out;
`;

const SubOptionsContainer = styled.div.attrs(() => ({
  className: "side-menu__optionsContainer",
}))`
  transform: translate3d(${({show}) => (show ? "0" : "100%")}, 0, 0);
  transition: transform 300ms ease-out;
`;

SideMenu.propTypes = {
  close: PropTypes.func,
  image: PropTypes.string,
  isOpen: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClickHandler: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    })
  ),
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      overlay: PropTypes.shape({
        backgroundColor: PropTypes.string,
        opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        width: PropTypes.string,
        height: PropTypes.string,
      }),
      close: PropTypes.shape({
        color: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      logo: PropTypes.shape({
        padding: PropTypes.string,
        borderWidth: PropTypes.string,
        borderColor: PropTypes.string,
        maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    options: PropTypes.shape({
      padding: PropTypes.string,
      borderWidth: PropTypes.string,
      borderColor: PropTypes.string,
      selectedBorderColor: PropTypes.string,
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      textTransform: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
};

SideMenu.defaultProps = {
  styles: defaultStyles,
  LinkComponent: ({children}) => <a href="#">{children}</a>,
  isOpen: true,
};
