import ActionTypes from "./actionTypes";
import {makeAction} from "src/core/common/utils";

export const requestLoadCart = cartId =>
  makeAction(ActionTypes.REQUEST_LOAD_CART, {cartId});
export const requestLoadCartFailure = error =>
  makeAction(ActionTypes.REQUEST_LOAD_CART_FAILURE, {error});
export const requestLoadCartSuccessful = cart =>
  makeAction(ActionTypes.REQUEST_LOAD_CART_SUCCESSFUL, {cart});

export const requestCreateCart = cart =>
  makeAction(ActionTypes.REQUEST_CREATE_CART, {cart});
export const requestCreateCartFailure = error =>
  makeAction(ActionTypes.REQUEST_CREATE_CART_FAILURE, {error});
export const requestCreateCartSuccessful = cart =>
  makeAction(ActionTypes.REQUEST_CREATE_CART_SUCCESSFUL, {cart});

export const requestAddItem = (cart, item) =>
  makeAction(ActionTypes.REQUEST_ADD_ITEM, {cart, item});
export const requestAddItemFailure = error =>
  makeAction(ActionTypes.REQUEST_ADD_ITEM_FAILURE, {error});
export const requestAddItemSuccessful = (cart, item) =>
  makeAction(ActionTypes.REQUEST_ADD_ITEM_SUCCESSFUL, {cart, item});

export const requestDeleteItem = (cart, item) =>
  makeAction(ActionTypes.REQUEST_DELETE_ITEM, {cart, item});
export const requestDeleteItemFailure = error =>
  makeAction(ActionTypes.REQUEST_DELETE_ITEM_FAILURE, {error});
export const requestDeleteItemSuccessful = (cart, item) =>
  makeAction(ActionTypes.REQUEST_DELETE_ITEM_SUCCESSFUL, {cart, item});

export const requestUpdateItem = (cart, item) =>
  makeAction(ActionTypes.REQUEST_UPDATE_ITEM, {cart, item});
export const requestUpdateItemFailure = error =>
  makeAction(ActionTypes.REQUEST_UPDATE_ITEM_FAILURE, {error});
export const requestUpdateItemSuccessful = (cart, item, oldCart) => {
  return makeAction(ActionTypes.REQUEST_UPDATE_ITEM_SUCCESSFUL, {cart, item, oldCart});
};

export const requestPayment = payload => makeAction(ActionTypes.REQUEST_PAYMENT, payload);
export const requestPaymentFailure = error =>
  makeAction(ActionTypes.REQUEST_PAYMENT_FAILURE, {error});
export const requestPaymentSuccessful = order =>
  makeAction(ActionTypes.REQUEST_PAYMENT_SUCCESSFUL, {order});

export const requestLoadOrder = (orderId, refresh = false) =>
  makeAction(ActionTypes.REQUEST_LOAD_ORDER, {orderId, refresh});
export const requestLoadOrderSuccessful = order =>
  makeAction(ActionTypes.REQUEST_LOAD_ORDER_SUCCESSFUL, {order});
export const requestLoadOrderFailure = error =>
  makeAction(ActionTypes.REQUEST_LOAD_ORDER_FAILURE, {error});

export const requestCreateOrderFromCart = (cart, additionalInfo) =>
  makeAction(ActionTypes.REQUEST_CREATE_ORDER_FROM_CART, {cart, additionalInfo});
export const requestCreateOrderFromCartSuccessful = order =>
  makeAction(ActionTypes.REQUEST_CREATE_ORDER_FROM_CART_SUCCESSFUL, {order});
export const requestCreateOrderFromCartFailure = error =>
  makeAction(ActionTypes.REQUEST_CREATE_ORDER_FROM_CART_FAILURE, {error});

export const requestCartValidation = (payload = {}) =>
  makeAction(ActionTypes.REQUEST_CART_VALIDATION, payload);
export const requestCartValidationSuccessful = cart =>
  makeAction(ActionTypes.REQUEST_CART_VALIDATION_SUCCESSFUL, {cart});
export const requestCartValidationFailure = error =>
  makeAction(ActionTypes.REQUEST_CART_VALIDATION_FAILURE, {error});

export const clearCart = () => makeAction(ActionTypes.CLEAR_CART, {});
export const setCart = cart => makeAction(ActionTypes.SET_CART, {cart});
export const clearOrder = () => makeAction(ActionTypes.CLEAR_ORDER, {});
export const setLoading = loading => makeAction(ActionTypes.SET_LOADING, {loading});

export const requestSetPromoCode = (cart, promoCode) =>
  makeAction(ActionTypes.REQUEST_SET_PROMO_CODE, {cart, promoCode});
export const requestSetPromoCodeSuccessful = cart =>
  makeAction(ActionTypes.REQUEST_SET_PROMO_CODE_SUCCESSFUL, {cart});
export const requestSetPromoCodeFailure = error =>
  makeAction(ActionTypes.REQUEST_SET_PROMO_CODE_FAILURE, {error});

export const requestSetPromoCodes = (cart, promoCodes) =>
  makeAction(ActionTypes.REQUEST_SET_PROMO_CODES, {cart, promoCodes});
export const requestSetPromoCodesSuccessful = cart =>
  makeAction(ActionTypes.REQUEST_SET_PROMO_CODES_SUCCESSFUL, {cart});
export const requestSetPromoCodesFailure = error =>
  makeAction(ActionTypes.REQUEST_SET_PROMO_CODES_FAILURE, {error});

export const requestSetReward = (cart, reward) =>
  makeAction(ActionTypes.REQUEST_SET_REWARD, {cart, reward});
export const requestSetRewardSuccessful = cart =>
  makeAction(ActionTypes.REQUEST_SET_REWARD_SUCCESSFUL, {cart});
export const requestSetRewardFailure = error =>
  makeAction(ActionTypes.REQUEST_SET_REWARD_FAILURE, {error});

export const requestSetPaymentOption = (cart, paymentOption) =>
  makeAction(ActionTypes.REQUEST_SET_PAYMENT_OPTION, {cart, paymentOption});
export const requestSetPaymentOptionSuccessful = cart =>
  makeAction(ActionTypes.REQUEST_SET_PAYMENT_OPTION_SUCCESSFUL, {cart});
export const requestSetPaymentOptionFailure = error =>
  makeAction(ActionTypes.REQUEST_SET_PAYMENT_OPTION_FAILURE, {error});

export const requestSetSchedule = (cart, schedule) =>
  makeAction(ActionTypes.REQUEST_SET_SCHEDULE, {cart, schedule});
export const requestSetScheduleSuccessful = cart =>
  makeAction(ActionTypes.REQUEST_SET_SCHEDULE_SUCCESSFUL, {cart});
export const requestSetScheduleFailure = error =>
  makeAction(ActionTypes.REQUEST_SET_SCHEDULE_FAILURE, {error});

export const requestOrdersList = () => makeAction(ActionTypes.REQUEST_ORDERS_LIST, {});
export const requestOrdersListSuccessful = ordersList =>
  makeAction(ActionTypes.REQUEST_ORDERS_LIST_SUCCESSFUL, {ordersList});
export const requestOrdersListFailure = error =>
  makeAction(ActionTypes.REQUEST_ORDERS_LIST_FAILURE, {error});

export const requestCreateOrderReview = (orderId, rating, comment) =>
  makeAction(ActionTypes.REQUEST_CREATE_ORDER_REVIEW, {orderId, rating, comment});
export const requestCreateOrderReviewSuccessful = review =>
  makeAction(ActionTypes.REQUEST_CREATE_ORDER_REVIEW_SUCCESSFUL, {review});
export const requestCreateOrderReviewFailure = error =>
  makeAction(ActionTypes.REQUEST_CREATE_ORDER_REVIEW_FAILURE, {error});

export const setOnBoardingData = payload =>
  makeAction(ActionTypes.SET_ON_BOARDING_DATA, payload);
