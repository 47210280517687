import {getPriceValidity, getProductAvailability} from "../../utils";
import {getProductDetailUrl} from "../../meta";
import makeSeller from "./seller";
import makePriceSpecification from "./priceSpecification";
import {getPriceAndCurrencyFromProduct} from "../utils";

function makeOffer(product, site, ctx) {
  const {price, currency} = getPriceAndCurrencyFromProduct(product, ctx);
  return {
    "@type": "Offer",
    price: price,
    priceValidUntil: getPriceValidity(product, ctx),
    priceSpecification: makePriceSpecification(product, ctx),
    priceCurrency: currency,
    availability: getProductAvailability(product, ctx),
    url: getProductDetailUrl(product, ctx),
    seller: makeSeller(site, ctx),
  };
}

export default makeOffer;
