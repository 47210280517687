class UpdateAddressBuilder {
  setNewAddress(address) {
    this.address = address;
    return this;
  }
  build() {
    return {
      address: this.address,
    };
  }
}

export default UpdateAddressBuilder;
