import React from 'react';
import styled from 'styled-components';
import merge from 'lodash/merge';
import media from '@ui/utils/media';

function PotencyTag({ text, styles }) {
  const _styles = merge({}, defaultStyles, styles);
  return <Container styles={_styles}>{text}</Container>;
}

const defaultStyles = {
  fontWeight: '400',
  padding: {
    lg: '4px 8px',
    md: '6px 14px',
    sm: '6px 14px'
  },
  margin: '0',
  border: '1px solid #ebebeb',
  borderRadius: '12px',
  fontSize: {
    lg: '9px',
    md: '9px',
    sm: '9px'
  },
  color: '#333',
  fontFamily: 'sans-serif',
  backgroundColor: '#fff',
  textTransform: 'uppercase',
  letterSpacing: '0'
};

const Container = styled.div.attrs(() => ({
  className: 'potency-tag potency-tag__container',
  'data-keep-cart': 'true'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
  white-space: nowrap;

  background-color: ${({ styles }) => styles.backgroundColor};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  margin: ${({ styles }) => styles.margin};
  border: ${({ styles }) => styles.border};
  border-radius: ${({ styles }) => styles.borderRadius};
  color: ${({ styles }) => styles.color};
  height: ${({ styles }) => styles.height};
  letter-spacing: ${({ styles }) => styles.letterSpacing};
  text-transform: ${({ styles }) => styles.textTransform};

  ${media.up('lg')} {
    font-size: ${({ styles }) => styles.fontSize.lg};
    padding: ${({ styles }) => styles.padding.lg};
  }
  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
    padding: ${({ styles }) => styles.padding.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
    padding: ${({ styles }) => styles.padding.sm};
  }
`;

PotencyTag.defaultProps = {
  styles: {}
};

export default PotencyTag;
