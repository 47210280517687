import React from "react";
import BasePage from "./BasePage";
import styled from "styled-components";
import useRouter from "src/core/common/hooks/useRouter";
import FlightProductDetail from "src/themes/flight/components/ProductDetail";

export default function FlightProductDetailPage(props) {
  const router = useRouter();
  const {productId, noBrandProductId} = router.query;
  const resolvedProductId = productId || noBrandProductId;

  return (
    <BasePage {...props}>
      <Container>
        <FlightProductDetail productId={resolvedProductId} />
      </Container>
    </BasePage>
  );
}

const Container = styled.div``;
