import React from "react";
import styled from "styled-components";
import Sort from "@ui/components/SortFilters/Sort";
import spacing from "@ui/utils/spacing";
import responsiveStyle from "@ui/utils/responsiveStyle";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

export default function SortingProductsListHeader({
  styles,
  orderOptions,
  currentOrder,
  onChangeOrder,
  DropdownComponent,
  numberOfResults,
  SortFiltersComponent,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container styles={_styles.root}>
      <ResultsNo styles={_styles.results}>
        {typeof numberOfResults === "number"
          ? `${numberOfResults} Result${numberOfResults === 1 ? "" : "s"}`
          : ""}
      </ResultsNo>
      {SortFiltersComponent ? (
        renderNodeOrComponent(SortFiltersComponent)
      ) : (
        <SortSection>
          <Label styles={_styles.sort.label}>Sort By:</Label>
          <Sort
            orderOptions={orderOptions}
            currentOrder={currentOrder}
            onChangeOrder={onChangeOrder}
            DropdownComponent={DropdownComponent}
            styles={_styles.sort}
          />
        </SortSection>
      )}
    </Container>
  );
}

const defaultStyles = {
  root: {
    fontFamily: "sans-serif",
    color: "#000000",
    fontSize: {
      lg: "16px",
      md: "14px",
      sm: "14px",
    },
  },
  results: {
    fontFamily: "sans-serif",
    color: "#000000",
    fontSize: {
      lg: "16px",
      md: "14px",
      sm: "14px",
    },
  },
  sort: {
    label: {
      fontFamily: "sans-serif",
      color: "#000000",
      fontSize: {
        lg: "16px",
        md: "14px",
        sm: "14px",
      },
    },
  },
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};
  ${({styles}) => responsiveStyle("font-size", styles.fontSize)};
`;

const ResultsNo = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  ${({styles}) => responsiveStyle("font-size", styles.fontSize)};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
`;

const SortSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(2)};
`;

const Label = styled.label`
  font-family: ${({styles}) => styles.fontFamily};
  ${({styles}) => responsiveStyle("font-size", styles.fontSize)};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
`;

SortingProductsListHeader.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontFamily: PropTypes.string,
      color: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    sort: PropTypes.object,
  }),
  orderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  currentOrder: PropTypes.string,
  onChangeOrder: PropTypes.func,
  DropdownComponent: PropTypes.elementType,
  numberOfResults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  SortFiltersComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
};
