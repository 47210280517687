import isEqual from "lodash/isEqual";

class PaginationManager {
  constructor(pageObj) {
    this.pageObj = pageObj;
  }

  get page() {
    return this.pageObj.pageNumber;
  }

  get totalPages() {
    return this.pageObj.totalPages;
  }

  get limit() {
    return this.pageObj.limit;
  }

  get offset() {
    return this.limit * (this.page - 1);
  }

  hasAdditionalPages() {
    return this.totalPages > 1;
  }

  hasMorePages() {
    return this.page < this.totalPages;
  }

  getLinkToPage() {
    return null;
  }

  isSinglePage() {
    return false;
  }

  getResponse(previous, current, previousElements) {
    if (this.resetPage(previous, current)) return current.elements;

    if (
      previous.offset === current.offset &&
      previous.elements.length === current.elements.length
    )
      return previousElements;

    return [...previousElements, ...current.elements];
  }

  resetPage(previous, current) {
    return (
      previous.order !== current.order ||
      previous.search !== current.search ||
      !this.filterEquals(previous.filters, current.filters) ||
      previous.offset > current.offset
    );
  }

  filterEquals = (filters1, filters2) => {
    if (filters1 && filters1.equals) {
      return filters1.equals(filters2);
    } else {
      return isEqual(filters1, filters2);
    }
  };
}

class SinglePagePaginationManager extends PaginationManager {
  constructor(pageObj) {
    super(pageObj);
    this.pageParam = pageObj.pageParam;
  }

  getLinkToPage(n) {
    if (n > 0 && n <= this.totalPages) {
      return {
        query: {
          [this.pageParam]: n,
        },
        paramsWhiteList: "__ALL__",
      };
    } else {
      return {
        paramsWhiteList: "__ALL__",
      };
    }
  }

  isSinglePage() {
    return true;
  }

  getResponse(_1, current, _2) {
    return current.elements;
  }
}

export function makePaginationManager(type, ...args) {
  switch (type) {
    case "infinite":
      return new PaginationManager(...args);
    case "single_page":
      return new SinglePagePaginationManager(...args);
    default:
      return new SinglePagePaginationManager(...args);
  }
}
