import {useEffect, useState} from "react";
import useDeliveryMode from "src/core/deliveries/hooks/useDeliveryMode";
import useFeatureToggles from "../../common/hooks/useFeatureToggles";
import useDeliveryAddress from "../../deliveries/hooks/useDeliveryAddress";
import {DeliveryModes} from "../../common/models/deliveryMode";
import useVerifyAddress from "../../deliveries/hooks/useVerifyAddress";
import useShop from "../../shops/hooks/useShop";
import UpdateAddressBuilder from "../../authentication/components/updateAddressBuilder";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useDeliveryType from "../hooks/useDeliveryType";
import {DeliveryTypes, makeDeliveryType} from "../../common/models/DeliveryType";
import useAppLoader from "../../common/hooks/useAppLoader";
import {useRefresh} from "../../common/hooks/useRefresh";
import useRouter from "../../common/hooks/useRouter";
import Address from "../../common/models/address";
import useSiteOptions from "../../sites/hooks/useSiteOptions";
import * as Notifications from "src/core/notifications";

export default function useAddressVerificationForm(handleVerification = true) {
  const refresh = useRefresh();
  const {switchStore} = useAppLoader();
  const [, authApi] = useAuthentication();
  const [builder] = useState(new UpdateAddressBuilder());
  const [, verify, getByAddress] = useVerifyAddress();
  const toggles = useFeatureToggles();
  const [, selectDeliveryType] = useDeliveryType();
  const [deliveryMode, setDeliveryMode] = useDeliveryMode();
  const [, selectDeliveryAddress] = useDeliveryAddress();
  const [shop] = useShop();
  const router = useRouter();
  const options = useSiteOptions();

  const [selectedMode, setSelectedDeliveryMode] = useState(deliveryMode || "none");
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [switchingStores, setSwitchingStore] = useState(false);
  const [deliversToAddress, setDeliversToAddress] = useState(undefined);

  //onSuccess is used to close the modal, go back on the routing, ...
  function onSubmit(formValues = {}, onSuccess) {
    const {mode} = formValues;
    const completeAddress = Address.fromPrototype(formValues.address, {
      address_line2: formValues.number,
    });
    builder.setNewAddress(completeAddress);

    authApi.isLoggedIn() && authApi.updateUser(builder.build());

    const verification = getByAddress(completeAddress).data;
    if (!verification) return;

    if (verification.shopHandlesAddress(toggles, shop.data, mode)) {
      updateDeliveryOptions(mode, completeAddress);
      onSuccess();

      // Waits until mode and delivery type sagas are executed
      setTimeout(() => {
        switchStoreOrRefresh(verification, shop.data, mode);
      });
    } else {
      goToOtherStoreSite(verification, mode, completeAddress);
    }
  }

  function onSelectAddress(address) {
    setAddress(address);
  }

  function onChangeAddress() {
    setSelectedAddress(undefined);
    setDeliversToAddress(undefined);
  }

  function updateDeliveryOptions(newDeliveryMode, newDeliveryAddress) {
    selectDeliveryType(makeDeliveryType(DeliveryTypes.DELIVERY));
    selectDeliveryAddress(newDeliveryAddress);
    if (newDeliveryMode !== deliveryMode || newDeliveryMode === DeliveryModes.EXPRESS) {
      setDeliveryMode({completeAddress: newDeliveryAddress, mode: newDeliveryMode});
    }
  }

  function switchStoreOrRefresh(verification, shop, mode) {
    if (verification.deliversToShop(shop, mode)) {
      refresh();
    } else {
      // This means we're on a omni-delivery site. So we must switch the active store
      // to the one that delivers to the selected address
      const anyShop = verification.getAnyDeliveryShop(mode);
      switchStore(anyShop.getId());
    }
  }

  function goToOtherStoreSite(verification, mode, address) {
    const url = verification.getAnyDeliveryShopUrl(
      {
        deliveryType: DeliveryTypes.DELIVERY,
        deliveryMode: mode,
        address: address,
      },
      toggles
    );
    if (url) {
      setSwitchingStore(true);
      selectDeliveryAddress(address);
      router.pushExternal(url);
    }
  }

  const addressVerification = getByAddress(selectedAddress)?.data;
  useEffect(() => {
    if (!handleVerification) return;

    const {loading, data} = getByAddress(selectedAddress);
    const verification = data;

    if (loading || !verification) return;

    if (verification.deliversToAny(selectedMode)) {
      setDeliversToAddress(true);
    } else {
      onFailure(verification);
    }
    // eslint-disable-next-line
  }, [selectedAddress, addressVerification, selectedMode]);

  function onFailure(verification) {
    const errorMessage = verification.getErrorMessage(
      selectedAddress,
      shop.data,
      selectedMode,
      options.getDirectToConsumerFallbackUrl()
    );

    Notifications.error(errorMessage);

    setDeliversToAddress(false);
  }

  useInitialAddress({setAddress});

  function setAddress(address) {
    if (selectedMode === "none") return;

    verify({address});
    setSelectedAddress(address);
    setDeliversToAddress(undefined);
  }

  useEffect(() => {
    if (selectedMode === "none" && deliveryMode) {
      setSelectedDeliveryMode(deliveryMode);
    }
    // eslint-disable-next-line
  }, [deliveryMode])

  //deliversToAddress can be used to show valid or invalid address messages
  return {
    selectedMode,
    setDeliveryMode: setSelectedDeliveryMode,
    selectedAddress,
    onSubmit,
    onSelectAddress,
    onChangeAddress,
    deliversToAddress,
    successMessage: addressVerification?.getSuccessMessage(shop?.data, selectedMode),
    errorMessage:
      selectedAddress &&
      addressVerification?.getErrorMessage(
        selectedAddress,
        shop?.data,
        selectedMode,
        options?.getDirectToConsumerFallbackUrl()
      ),
    loading: switchingStores || getByAddress(selectedAddress)?.loading,
  };
}

function useInitialAddress({setAddress}) {
  const [selectedAddress] = useDeliveryAddress();
  const [, authApi] = useAuthentication();
  const user = authApi.userProfile();
  const isValidating = authApi.isValidating();
  const isLoggedIn = authApi.isLoggedIn();
  const [deliveryType] = useDeliveryType();

  const userAddress = user ? user.getDeliveryAddress() : undefined;
  const [initialAddress, setInitialAddress] = useState(getInitialAddress());

  function getInitialAddress() {
    if (!deliveryType?.pickupAtShop()) return selectedAddress;

    return userAddress?.isValid() ? userAddress : null;
  }

  /* eslint-disable */
  useEffect(() => {
    if (initialAddress?.isValid()) {
      setAddress && setAddress(initialAddress);
    }
  }, [initialAddress]);

  useEffect(() => {
    if (isValidating || !isLoggedIn || initialAddress) return;

    const userAddress = user.getDeliveryAddress();
    if (userAddress?.isValid()) {
      setInitialAddress(userAddress);
    }
  }, [isValidating]);

  useEffect(() => {
    if (!initialAddress?.isValid()) {
      setInitialAddress(getInitialAddress())
    }
  }, [selectedAddress])
  /* eslint-enable */

  return {initialAddress};
}
