function createECommerceMiddleware() {
  const eventHandlers = [];
  const eCommerceMiddleware = _store => next => action => {
    eventHandlers.forEach(handler => {
      handler(action);
    });
    return next(action);
  };
  eCommerceMiddleware.run = eventHandler => {
    eventHandlers.push(eventHandler);
  };
  return eCommerceMiddleware;
}

export default createECommerceMiddleware();
