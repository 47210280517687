import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '@ui/utils/media';
import StoreOption from './StoreOption';

const StoreSelector = ({ styles, storeList, setCurrentShop, defaultStore }) => {
  return (
    <Container>
      <Title styles={styles.title}>Select a store</Title>
      <Field
        name="store"
        // validate={}
        initialValue={defaultStore}
        render={({ input, meta }) => {
          return (
            <StoreContainer>
              {storeList &&
                storeList.map((store) => (
                  <StoreOption
                    {...input}
                    styles={styles.options}
                    store={store}
                    isSelected={input.value === store}
                    changeSelectedStore={input.onChange}
                    setCurrentShop={setCurrentShop}
                  />
                ))}
            </StoreContainer>
          );
        }}
      />
    </Container>
  );
};

const Container = styled.div.attrs(() => ({
  className: 'store-selector__container'
}))`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
`;

const StoreContainer = styled.div.attrs(() => ({
  className: 'store-selector__storeContainer'
}))`
  margin: 0 0 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
`;

const Title = styled.div.attrs(() => ({
  className: 'store-selector__title'
}))`
  margin: 0 0 20px 0;
  text-align: center;
  color: ${({ styles }) => styles.color};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  font-size: ${({ styles }) => styles.fontSize.lg};

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

StoreSelector.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  })
};

export default StoreSelector;
