import {useMemo} from "react";
import useFeatureToggles from "../../common/hooks/useFeatureToggles";
import {Deliveries} from "../api";

function useAvailableDeliveryTypes() {
  const toggles = useFeatureToggles();

  return useMemo(() => Deliveries.getAvailableDeliveryTypes(toggles), [toggles]);
}

export default useAvailableDeliveryTypes;
