import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '@ui/utils/media';

const StoreOption = ({
  styles,
  store,
  isSelected,
  changeSelectedStore,
  setCurrentShop
}) => {
  const address = store.getAddress();

  const onChange = () => {
    changeSelectedStore(store);
    setCurrentShop(store);
  };

  return (
    <TextContainer
      styles={styles}
      selected={isSelected}
      onClick={() => onChange()}
    >
      <Selector selected={isSelected} />
      <div>
        <StoreText styles={styles}>{store.getName()}</StoreText>
        <StoreText styles={styles}>{address.address}</StoreText>
        <StoreText styles={styles}>
          {address.city}, {address.state} {address.zipCode}
        </StoreText>
      </div>
    </TextContainer>
  );
};

const Selector = styled.div.attrs(() => ({
  className: 'store-option__selector'
}))`
  border: ${({ selected }) =>
    selected ? '6px solid #000' : '1px solid #D9D9D9'};
  border-radius: 10px;
  width: ${({ selected }) => (selected ? '8px' : '18px')};
  height: ${({ selected }) => (selected ? '8px' : '18px')};
  margin-right: 10px;
`;

const TextContainer = styled.div.attrs(() => ({
  className: 'store-option__textContainer'
}))`
  background: ${({ selected, styles }) =>
    selected ? styles.selectedColor : 'transparent'};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 3px solid;
  border-color: ${({ styles }) => styles.borderColor};
  border-radius: 8px;
  padding: 15px;
`;

const StoreText = styled.div.attrs(() => ({
  className: 'store-option__storeText'
}))`
  color: ${({ styles }) => styles.color};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  line-height: 1.8;
  font-size: ${({ styles }) => styles.fontSize.lg};

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

StoreOption.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  })
};

export default StoreOption;
