import {useMediaQuery} from "react-responsive";
import {useEffect, useState} from "react";

function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(false);
  const _isDesktop = useMediaQuery({minWidth: 1025});

  useEffect(() => {
    setIsDesktop(_isDesktop);
  }, [_isDesktop]);

  return isDesktop;
}

export default useIsDesktop;
