import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TranslucentOnHover from "@ui/components/Decorators/TranslucentOnHover";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";

function CategoryButton({styles, LinkComponent, selected, categoryName, categoryUrl}) {
  const _styles = merge({}, defaultStyles, styles);
  return (
    <TranslucentOnHover>
      <OuterContainer styles={_styles.root}>
        <LinkComponent to={categoryUrl}>
          <Container selected={selected} styles={_styles.root}>
            <span>{categoryName}</span>
          </Container>
        </LinkComponent>
      </OuterContainer>
    </TranslucentOnHover>
  );
}

const defaultStyles = {
  root: {
    color: "rgb(131, 131, 131)",
    selectedColor: "black",
    selectedBorder: "rgb(237, 28, 36)",
    fontWeight: "500",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "10px",
    },
    selectedFontSize: "",
    textTransform: "capitalize",
    borderRadius: "0",
    height: {
      lg: "55px",
      md: "55px",
      sm: "55px",
    },
    borderBottomStyle: "solid",
  },
};
const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
    color: ${({styles}) => styles.color};
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: ${({styles}) => styles.height.lg};
  box-shadow: ${({styles}) => styles.boxShadow};

  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  text-transform: ${({styles}) => styles.textTransform};
  color: ${({styles, selected}) => (selected ? styles.selectedColor : styles.color)};
  font-size: ${({styles, selected}) =>
    selected ? styles.selectedFontSize : styles.fontSize.lg};
  font-weight: ${({styles, selected}) => (selected ? "bold" : styles.fontWeight)};
  font-family: ${({styles}) => styles.fontFamily};

  column-gap: ${spacing(1)};

  > span {
    border-width: ${({styles}) => styles.borderWidth};
    border-bottom-color: ${({styles, selected}) =>
      selected ? styles.selectedBorder : "transparent"};
    border-radius: ${({styles}) => styles.borderRadius};
    border-bottom-style: ${({styles, selected}) =>
      selected ? styles.borderBottomStyle : "solid"};
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
  }
`;

CategoryButton.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      color: PropTypes.string,
      selectedColor: PropTypes.string,
      selectedBorder: PropTypes.string,
      boxShadow: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      borderBottom: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      textTransform: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      selectedBorderColor: PropTypes.string,
      selectedFontSize: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
  LinkComponent: PropTypes.elementType,
  selected: PropTypes.bool,
  categoryName: PropTypes.string,
  categoryUrl: PropTypes.string,
};
CategoryButton.defaultProps = {
  LinkComponent: ({children}) => <a href={"#"}>{children}</a>,
};
export default CategoryButton;
