import React from "react";
import styled from "styled-components";
import {getSearchIconComponent, SearchIcon} from "@ui/components/Icons";
import PropTypes from "prop-types";

function SearchIconButton({alt = "search", searchIcon, ...rest}) {
  const SearchIcon = getSearchIconComponent(searchIcon);
  return (
    <SearchImage alt={alt} {...rest}>
      <SearchIcon alt={alt} {...rest} />
    </SearchImage>
  );
}

const SearchImage = styled.div`
  > *:first-child {
    width: 24px;
    height: 24px;
    border: 0;
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    padding: 16px;
    color: black;
    &:hover {
      opacity: 0.6;
    }
  }
`;

SearchIconButton.propTypes = {
  alt: PropTypes.string,
  searchIcon: PropTypes.string,
};

SearchIconButton.defaultProps = {
  searchIcon: SearchIcon,
};

export default SearchIconButton;
