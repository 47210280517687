import React from 'react';
import { useInView } from 'react-intersection-observer';
import { isClient } from '@ui/utils/isClient';

function LazyRender({
  children,
  forceDisplay,
  minHeight,
  minWidth,
  triggerOnce,
  ...props
}) {
  const { ref, inView } = useInView({
    triggerOnce: triggerOnce === undefined ? true : triggerOnce,
    root: props.ref ? props.ref.current : undefined
  });

  if (!isClient() || forceDisplay || inView || !children)
    return <>{children}</>;

  const styles = {
    minHeight,
    minWidth
  };

  return (
    <div ref={ref} style={!inView ? styles : undefined}>
      {inView ? children : null}
    </div>
  );
}

export default LazyRender;
