import React from "react";
import SenseRecommendedProductsList from "src/themes/sense/elements/RecommendedProductsList/RecommendedProductsList";

export default function RecommendedProductsList({className, title, filters, linkToAll}) {
  return (
    <SenseRecommendedProductsList
      className={className}
      title={title}
      filters={filters}
      linkToAll={linkToAll}
    />
  );
}
