import * as ProductsApi from "src/core/api/products";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import Page from "src/core/common/models/page";

export async function fetchProducts({limit, offset, order, filters, deliveryInfo}) {
  if (!deliveryInfo) return null;

  return ProductsApi.list(
    prepareParams({limit, offset, order, filters, deliveryInfo})
  ).then(response => {
    return new Page({
      meta: response.meta,
      objects: response.data.map(
        element => new Product(populateRelations(element, response.included))
      ),
    });
  });
}

function prepareParams({limit, offset, order, filters}) {
  const _filters = filters?.serialize ? filters.serialize() : filters;
  return pickBy({limit, offset, ..._filters, order}, identity);
}
