const ActionTypes = {
  REQUEST_CREATE_CART: "checkout/request_create_cart",
  REQUEST_CREATE_CART_FAILURE: "checkout/request_create_cart_failure",
  REQUEST_CREATE_CART_SUCCESSFUL: "checkout/request_create_cart_successful",

  REQUEST_LOAD_CART: "checkout/request_load_cart",
  REQUEST_LOAD_CART_FAILURE: "checkout/request_load_cart_failure",
  REQUEST_LOAD_CART_SUCCESSFUL: "checkout/request_load_cart_successful",

  REQUEST_ADD_ITEM: "checkout/request_add_item",
  REQUEST_ADD_ITEM_FAILURE: "checkout/request_add_item_failure",
  REQUEST_ADD_ITEM_SUCCESSFUL: "checkout/request_add_item_successful",

  REQUEST_DELETE_ITEM: "checkout/delete_item",
  REQUEST_DELETE_ITEM_FAILURE: "checkout/delete_item_failure",
  REQUEST_DELETE_ITEM_SUCCESSFUL: "checkout/delete_item_successful",

  REQUEST_UPDATE_ITEM: "checkout/update_item",
  REQUEST_UPDATE_ITEM_FAILURE: "checkout/update_item_failure",
  REQUEST_UPDATE_ITEM_SUCCESSFUL: "checkout/update_item_successful",

  CONFIRM_ITEMS: "checkout/confirm_items",

  REQUEST_PAYMENT: "request_payment",
  REQUEST_PAYMENT_FAILURE: "request_payment_failure",
  REQUEST_PAYMENT_SUCCESSFUL: "request_payment_successful",

  REQUEST_CREATE_ORDER_FROM_CART: "checkout/create_order_from_cart",
  REQUEST_CREATE_ORDER_FROM_CART_SUCCESSFUL: "checkout/create_order_from_cart_successful",
  REQUEST_CREATE_ORDER_FROM_CART_FAILURE: "checkout/create_order_from_cart_failure",

  REQUEST_LOAD_ORDER: "checkout/load_order",
  REQUEST_LOAD_ORDER_SUCCESSFUL: "checkout/load_order_successful",
  REQUEST_LOAD_ORDER_FAILURE: "checkout/load_order_failure",

  REQUEST_CART_VALIDATION: "checkout/request_cart_validation",
  REQUEST_CART_VALIDATION_SUCCESSFUL: "checkout/request_cart_validation_successful",
  REQUEST_CART_VALIDATION_FAILURE: "checkout/request_cart_validation_failure",

  REQUEST_SET_PROMO_CODES: "checkout/request_set_promo_codes",
  REQUEST_SET_PROMO_CODES_SUCCESSFUL: "checkout/request_set_promo_codes_successful",
  REQUEST_SET_PROMO_CODES_FAILURE: "checkout/request_set_promo_codes_failure",

  REQUEST_SET_REWARD: "checkout/request_set_reward",
  REQUEST_SET_REWARD_SUCCESSFUL: "checkout/request_set_reward_successful",
  REQUEST_SET_REWARD_FAILURE: "checkout/request_set_reward_failure",

  REQUEST_SET_PAYMENT_OPTION: "checkout/request_set_payment_option",
  REQUEST_SET_PAYMENT_OPTION_SUCCESSFUL: "checkout/request_set_payment_option_successful",
  REQUEST_SET_PAYMENT_OPTION_FAILURE: "checkout/request_set_payment_option_failure",

  REQUEST_SET_SCHEDULE: "checkout/request_set_schedule",
  REQUEST_SET_SCHEDULE_SUCCESSFUL: "checkout/request_set_schedule_successful",
  REQUEST_SET_SCHEDULE_FAILURE: "checkout/request_set_schedule_failure",

  REQUEST_ORDERS_LIST: "orders/load_orders_list",
  REQUEST_ORDERS_LIST_SUCCESSFUL: "orders/load_orders_list_successful",
  REQUEST_ORDERS_LIST_FAILURE: "orders/load_orders_list_failure",

  REQUEST_CREATE_ORDER_REVIEW: "orders/create_order_review",
  REQUEST_CREATE_ORDER_REVIEW_SUCCESSFUL: "orders/create_order_review_successful",
  REQUEST_CREATE_ORDER_REVIEW_FAILURE: "orders/create_order_review_failure",

  CLEAR_CART: "checkout/clear_cart",

  SET_CART: "checkout/set_cart",

  CLEAR_ORDER: "checkout/clear_order",

  SET_LOADING: "checkout/set_loading",

  SET_ON_BOARDING_DATA: "onboarding/set_on_boarding_data",
};

export default ActionTypes;
