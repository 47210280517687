import {combineReducers} from "redux";
import ActionTypes from "./actionTypes";

const authenticationInitialState = {
  loading: false,
  error: null,
  data: null,
  validating: false,
};
function authentication(state = authenticationInitialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_USER_LOGIN:
    case ActionTypes.REQUEST_USER_REGISTRATION:
    case ActionTypes.REQUEST_USER_LOGOUT:
      return {...state, loading: true, error: null, data: null};
    case ActionTypes.REQUEST_CHANGE_PASSWORD:
    case ActionTypes.REQUEST_DEACTIVATE_USER:
      return {...state, loading: true, error: null};
    case ActionTypes.REQUEST_USER_LOGIN_SUCCESS:
    case ActionTypes.REQUEST_USER_REGISTRATION_SUCCESS:
    case ActionTypes.REQUEST_UPDATE_USER_SUCCESS:
    case ActionTypes.LOAD_USER:
      return {
        ...state,
        loading: false,
        validating: true,
        error: null,
        data: action.payload,
      };
    case ActionTypes.LOAD_USER_PROFILE_SUCCESS:
      return {...state, validating: false, loading: false};
    case ActionTypes.REQUEST_ACCOUNT_VERIFICATION_CHECK_SUCCESS:
    case ActionTypes.REQUEST_RESET_PASSWORD_SUCCESS:
    case ActionTypes.REQUEST_CHANGE_PASSWORD_SUCCESS:
      return {...state, loading: false, error: null, data: action.payload};
    case ActionTypes.REQUEST_USER_LOGIN_FAILURE:
    case ActionTypes.REQUEST_USER_REGISTRATION_FAILURE:
      return {...state, loading: false, error: action.payload.error, data: null};
    case ActionTypes.REQUEST_UPDATE_USER_FAILURE:
    case ActionTypes.REQUEST_CHANGE_PASSWORD_FAILURE:
    case ActionTypes.REQUEST_DEACTIVATE_USER_FAILURE:
      return {...state, loading: false, error: action.payload.error};
    case ActionTypes.REQUEST_USER_LOGOUT_SUCCESS:
    case ActionTypes.REQUEST_DEACTIVATE_USER_SUCCESS:
      return authenticationInitialState;
    case ActionTypes.RESET_ERRORS:
      return {...state, error: null};
    default:
      return state;
  }
}

const profileInitialState = {
  loading: false,
  error: null,
  data: null,
};
function profile(state = profileInitialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_UPDATE_USER_FAILURE:
      return {...state, loading: false, error: action.payload.error};
    case ActionTypes.LOAD_USER_PROFILE:
    case ActionTypes.REQUEST_UPDATE_USER:
    case ActionTypes.REQUEST_USER_LOGOUT:
      return {...state, loading: true, error: null};
    case ActionTypes.LOAD_USER_PROFILE_SUCCESS:
      return {...state, loading: false, error: null, data: action.payload.profile};
    case ActionTypes.LOAD_USER_PROFILE_FAILURE:
      return {...state, loading: false, error: action.payload.error, data: null};
    case ActionTypes.REQUEST_USER_LOGOUT_SUCCESS:
      return profileInitialState;
    default:
      return state;
  }
}

const recoverPasswordInitialState = {
  loading: false,
  error: null,
  data: null,
};
function recoverPassword(state = recoverPasswordInitialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_RESET_PASSWORD:
    case ActionTypes.REQUEST_RECOVER_PASSWORD:
      return {...state, loading: true, error: null, data: null};
    case ActionTypes.REQUEST_RESET_PASSWORD_SUCCESS:
    case ActionTypes.REQUEST_RECOVER_PASSWORD_SUCCESS:
      return {...state, loading: false, error: null, data: action.payload};
    case ActionTypes.REQUEST_RESET_PASSWORD_FAILURE:
    case ActionTypes.REQUEST_RECOVER_PASSWORD_FAILURE:
      return {...state, loading: false, error: action.payload.error, data: null};
    default:
      return state;
  }
}

const accountVerificationInitialState = {
  loading: false,
  error: null,
  data: null,
};
function accountVerification(state = accountVerificationInitialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_ACCOUNT_VERIFICATION:
    case ActionTypes.REQUEST_ACCOUNT_VERIFICATION_CHECK:
      return {...state, loading: true, error: null, data: null};
    case ActionTypes.REQUEST_ACCOUNT_VERIFICATION_SUCCESS:
    case ActionTypes.REQUEST_ACCOUNT_VERIFICATION_CHECK_SUCCESS:
      return {...state, loading: false, error: null, data: action.payload};
    case ActionTypes.REQUEST_ACCOUNT_VERIFICATION_FAILURE:
    case ActionTypes.REQUEST_ACCOUNT_VERIFICATION_CHECK_FAILURE:
      return {...state, loading: false, error: action.payload.error, data: null};
    default:
      return state;
  }
}

const initializedState = false;
function initialized(state = initializedState, action) {
  switch (action.type) {
    case ActionTypes.INITIALIZED:
      return true;
    default:
      return state;
  }
}

export default combineReducers({
  authentication,
  profile,
  recoverPassword,
  accountVerification,
  initialized,
});
