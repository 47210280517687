import React from 'react';
import styled from 'styled-components';
import PickerContainer from '@ui/components/DeliveryBanner/PickerContainer';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from '@ui/components/Icons';

export default function DeliveryLocationPicker({
  displayArrows,
  styles,
  selectedLocation,
  locationOptionsRoute,
  LinkComponent,
  hasDeliveryPicker
}) {
  let selectedLocationText;
  if (locationOptionsRoute) {
    selectedLocationText = (
      <LinkComponent to={locationOptionsRoute}>
        {selectedLocation}
        {displayArrows && (
          <ChevronDownIcon
            color={styles.color}
            padding={styles.padding}
            size={'12px'}
          />
        )}
      </LinkComponent>
    );
  } else {
    selectedLocationText = <NoLink>{selectedLocation}</NoLink>;
  }
  return (
    <PickerContainer styles={styles} ellipsize={hasDeliveryPicker}>
      {selectedLocationText}
    </PickerContainer>
  );
}

const NoLink = styled.span`
  text-decoration: none;
  cursor: default;
`;

DeliveryLocationPicker.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({ to, children }) => <a href={'#'}>{children}</a>
};
DeliveryLocationPicker.propTypes = {
  styles: PropTypes.shape({
    color: PropTypes.string,
    padding: PropTypes.string
  }),
  selectedLocation: PropTypes.string,
  locationOptionsRoute: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  LinkComponent: PropTypes.elementType,
  hasDeliveryPicker: PropTypes.bool,
  displayArrows: PropTypes.bool
};
