import React from 'react';
import styled from 'styled-components';
import merge from 'lodash/merge';
import SlideIndicator from '@ui/components/SlideIndicator/SlideIndicator';

function LineSlideIndicator(props) {
  const _styles = merge({}, defaultStyles, props.styles);

  return (
    <SlideIndicator
      {...props}
      SlideIndicatorComponent={Line}
      styles={_styles}
    />
  );
}

const defaultStyles = {
  indicator: {
    color: '#272727',
    inactiveColor: '#f29994'
  }
};

const Line = styled.div.attrs(() => ({
  className: 'slide-dot-indicator slide-dot-indicator__indicator'
}))`
  flex: 1;
  height: 3px;
  margin-top: 5px;
  background-color: ${({ styles, active }) =>
    active ? styles.color : styles.inactiveColor};
  cursor: pointer;
`;

LineSlideIndicator.defaultProps = {
  styles: defaultStyles,
  maxIndicators: 100
};

export default LineSlideIndicator;
