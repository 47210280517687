import React from "react";
import SenseBasePage from "./BasePage";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import CategorizedProductsBrowser from "@menu/components/CategorizedProductsBrowser";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";

const AdBanner = dynamic(() =>
  import("@themes/default/components/AdBanner").catch(logModuleLoadError("adBanner"))
);

export default function SenseProductsPage(props) {
  const {showAdBanner} = useAdBanner();

  return (
    <SenseBasePage {...props}>
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <CategorizedProductsBrowser />
    </SenseBasePage>
  );
}
