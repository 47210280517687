import React from 'react';
import { MenuOutline } from '@styled-icons/evaicons-outline/MenuOutline';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function MenuButton({ onClick }) {
  return <MenuIcon onClick={onClick} />;
}

const MenuIcon = styled(MenuOutline).attrs(() => ({
  className: 'menu-btn menu-btn__icon'
}))`
  height: 24px;
  cursor: pointer;
`;

MenuButton.propTypes = {
  onClick: PropTypes.func
};

export default MenuButton;
