class NotificationsConfig {
  config = {
    defaultOptions: {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      limit: 1,
    },
  };

  setConfig(config) {
    this.config = {...this.config, ...config};
  }

  setDefaultOptions(options) {
    this.config.defaultOptions = options;
    return this;
  }
  getDefaultOptions() {
    return this.config.options;
  }
}

export default new NotificationsConfig();
