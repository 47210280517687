import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import media from '@ui/utils/media';
import spacing from '@ui/utils/spacing';

export default function FixedQuantityPicker({ containerId, children, styles }) {
  const [containerElement, setContainerElement] = useState(null);

  useEffect(() => {
    if (!containerElement) {
      let element = document.getElementById(containerId);
      if (!element) {
        element = document.createElement('div');
        element.id = containerId;
        document.body.appendChild(element);
      }
      setContainerElement(element);
    }
  }, [containerElement]);

  if (!containerElement) return <div />;
  return ReactDOM.createPortal(
    <FixedContainer styles={styles}>{children}</FixedContainer>,
    containerElement
  );
}

const FixedContainer = styled.div`
  display: none;
  ${media.down('sm')} {
    position: fixed;
    bottom: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: ${spacing(5)} 0 ${spacing(1)};
    z-index: 99;
    background: ${({ styles }) => styles.background};
  }
`;

FixedQuantityPicker.defaultProps = {
  containerId: 'quantity-picker-container'
};
