import AnalyticsConfig from "./config";

export default {
  identify: (...params) => {
    AnalyticsConfig.getTracker().identify(...params);
  },
  track: (...params) => {
    AnalyticsConfig.getTracker().track(...params);
  },
  pageView: (...params) => {
    AnalyticsConfig.getTracker().pageView(...params);
  },
  setConfig: AnalyticsConfig.setConfig,
};
