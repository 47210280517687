import ActionTypes from "src/core/checkout/state/actionTypes";
import Analytics from "../analytics";
import {Authentication} from "src/core/authentication";
import EcommerceActions from "src/core/checkout/actions";

export const events = {
  IN_SITE_SEARCH: "Search Used",
  PRODUCT_DETAIL_VIEW: "Product Detail View",
  CATEGORY_VIEW: "Category View",
  ADD_ITEM_SUCCESSFUL: "Add Product To Cart Successful",
  REMOVE_ITEM_SUCCESSFUL: "Remove Product From Cart Successful",
  UPDATE_ITEM_SUCCESSFUL: "Update Product In Cart Successful",
  CLEAR_CART: "Cart Cleared",
  CHECKOUT_STARTED: "Checkout Started",
  ORDER_SUCCESSFUL: "Order Successful",
};
export const handlers = {
  [ActionTypes.REQUEST_ADD_ITEM_SUCCESSFUL]: trackAddToCartSuccessful,
  [ActionTypes.REQUEST_DELETE_ITEM_SUCCESSFUL]: trackRemoveFromCartSuccessful,
  [ActionTypes.REQUEST_UPDATE_ITEM_SUCCESSFUL]: trackUpdateProductCartSuccessful,
  [ActionTypes.CLEAR_CART]: trackClearCart,
  [ActionTypes.REQUEST_CREATE_ORDER_FROM_CART_SUCCESSFUL]: trackOrderSuccessful,
};

export function trackSearch(payload) {
  Analytics.track(events.IN_SITE_SEARCH, payload);
}

export function trackProductDetailView(payload) {
  Analytics.track(events.PRODUCT_DETAIL_VIEW, payload);
}

export function trackCategoryView(payload) {
  Analytics.track(events.CATEGORY_VIEW, payload);
}

function trackAddToCartSuccessful(payload) {
  Analytics.track(events.ADD_ITEM_SUCCESSFUL, payload);
}

function trackRemoveFromCartSuccessful(payload) {
  Analytics.track(events.REMOVE_ITEM_SUCCESSFUL, payload);
}

function trackUpdateProductCartSuccessful(payload) {
  Analytics.track(events.UPDATE_ITEM_SUCCESSFUL, payload);
}

function trackClearCart() {
  Analytics.track(events.CLEAR_CART);
}

export function trackCheckoutStarted(payload) {
  Analytics.track(events.CHECKOUT_STARTED, payload);
}

function trackOrderSuccessful(payload) {
  const loggedInProfile = Authentication.userProfile();
  const onBoardingProfile = EcommerceActions.getOnBoarding().data;

  const profile = {
    firstName: loggedInProfile
      ? loggedInProfile.getFirstName()
      : onBoardingProfile.firstName,
    lastName: loggedInProfile
      ? loggedInProfile.getLastName()
      : onBoardingProfile.lastName,
    email: loggedInProfile ? loggedInProfile.getEmail() : onBoardingProfile.email,
    phoneNumber: loggedInProfile
      ? loggedInProfile.getPhoneNumber()
      : onBoardingProfile.phoneNumber,
  };

  Analytics.track(events.ORDER_SUCCESSFUL, {...payload, profile});
}
