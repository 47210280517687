import React from "react";
import styled from "styled-components";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import media from "@ui/utils/media";

const PromotionContainer = styled.div.attrs(() => ({
  className: "product-card product-card__promotion-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({styles}) => styles.width};
  position: absolute;

  top: ${({styles}) => styles.position.top};
  left: ${({styles}) => styles.position.left};
  border-radius: ${({styles}) => styles.borderRadius};
  font-family: ${({styles}) => styles.fontFamily};
  background-color: ${({styles}) => styles.backgroundColor};
  color: ${({styles}) => styles.color};
  margin: ${({styles}) => styles.margin.lg};
  padding: ${({styles}) => styles.padding.lg};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    margin: ${({styles}) => styles.margin.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
    left: ${({styles}) => styles.left};
    top: ${({styles}) => styles.top};
  }
`;

const PromotionIndicator = ({promotionText, styles}) => {
  const _styles = merge({}, styles);

  return <PromotionContainer styles={_styles}>{promotionText}</PromotionContainer>;
};

PromotionIndicator.propTypes = {
  promotionText: PropTypes.string,
  styles: PropTypes.object,
  margin: PropTypes.shape({
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PromotionIndicator.defaultProps = {
  promotionText: "",
};

export default PromotionIndicator;
