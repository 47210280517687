import React from "react";
import styled from "styled-components";

function SearchResults({className, results, visible, onSelect, loading, renderEntry}) {
  const hasResults = results && results.length > 0;

  if (!visible) return null;

  return (
    <Container className={className}>
      {visible && hasResults && (
        <ResultsContainer>
          {results.map((entry, index) => (
            <Entry
              key={index}
              onClick={() => onSelect(entry)}
              data-cy={`result-${index}`}
            >
              {renderEntry({entry})}
            </Entry>
          ))}
        </ResultsContainer>
      )}
      {visible && !hasResults && !loading && (
        <Entry>
          <span>No Results</span>
        </Entry>
      )}
    </Container>
  );
}

const Container = styled.div`
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.3);
`;
const ResultsContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const Entry = styled.li`
  display: flex;
  cursor: pointer;

  > a,
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({theme}) => theme.v2.color.base.grey["900"]};
    text-decoration: none;
    margin: ${({theme}) => theme.v2.spacing(4)};
    width: 100%;
    box-sizing: border-box;
    line-height: 1.5;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &:hover {
    background-color: #dbe5e7;
    > a {
      text-decoration: underline;
    }
  }
  span {
    text-align: left;
  }
`;

export default SearchResults;
