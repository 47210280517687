import {formatPriceWithoutCurrency} from "src/core/common/price";

export function getPriceAndCurrencyFromProduct(product) {
  const productPrice = product.getUnitPrice();
  const formattedPrice = formatPriceWithoutCurrency(product.getUnitPrice());

  if (!productPrice) return {price: formattedPrice, currency: ""};

  const currency = productPrice.currency.toUpperCase();
  return {price: formattedPrice, currency};
}
