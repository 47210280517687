import React from "react";
import styled from "styled-components";
import {CarouselProductsList, ProductListHeader} from "@ui";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import useTheme from "@mock/hooks/useTheme";
import useShowcaseProductsList from "@mock/hooks/useShowcaseProductsList";
import styles, {
  categoryCardStyles,
} from "@themes/boost/components/ShowcaseProductsList/ProductsList/styles";
import Link from "@mock/components/modules/Link";

export default function BoostThemeProductsList({
  categoryName,
  description,
  url,
  slug,
  elementsPerRow,
  type,
  ...props
}) {
  const {Title, CategoryCard, ViewAllLink} = useThemeComponents();
  const theme = useTheme();
  const {site, ...baseProps} = useShowcaseProductsList({
    inView: true,
    slug,
    type,
    url,
    filters: props.filters,
  });

  const {showHeader, showCategoryCard, productsPerLine} =
    site.getUiConfiguration().showcasedProductsList;
  const headerProps = {
    category: categoryName,
    url,
  };
  let categoryCardProps = {
    styles: categoryCardStyles(theme, site),
    title: categoryName,
    LinkComponent: Link,
    url,
  };

  const HeaderComponent =
    "HeaderComponent" in props ? (
      props.HeaderComponent
    ) : (
      <ProductListHeader
        ViewAllComponent={
          <ViewAllLinkContainer>
            <ViewAllLink
              accessibilityIdentifier={slug}
              to={url}
              keepQuery={true}
              underlined={false}
              theme={theme}
            />
          </ViewAllLinkContainer>
        }
        TitleComponent={Title}
        title={categoryName}
        description={description}
      />
    );

  return (
    <CarouselProductsList
      {...props}
      {...baseProps}
      styles={styles(theme, site)}
      maxProductsPerSlide={productsPerLine}
      showHeader={showHeader}
      headerProps={headerProps}
      showCategoryCard={showCategoryCard}
      HeaderComponent={HeaderComponent}
      CategoryCardComponent={CategoryCard}
      categoryCardProps={categoryCardProps}
    />
  );
}

const ViewAllLinkContainer = styled.div`
  > * {
    color: ${({theme}) => theme.v2?.color?.onSurface};
    text-decoration: none !important;
  }
`;
