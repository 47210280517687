import {useEffect, useState} from "react";
import store from "../state/store";
import {Authentication} from "../index";

function useAuthentication() {
  const [state, setState] = useState(store.getState());

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return [state.authentication, Authentication];
}

export default useAuthentication;
