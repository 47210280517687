function makeListItem(item, position) {
  return {
    "@type": "ListItem",
    position: position.toString(),
    item: {
      name: item.name,
      "@id": item.id,
    },
  };
}

export default makeListItem;
