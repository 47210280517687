import React from "react";
import styled from "styled-components";
import {AppliedFiltersList, media} from "@ui";
import useTheme from "@mock/hooks/useTheme.js";
import useAppliedFilters from "@mock/hooks/useAppliedFilters";
import styles from "@themes/boost/components/AppliedFilters/styles";

export default function BoostThemeAppliedFilters({filters}) {
  const theme = useTheme();
  const [, setFilter, clearFilters, , FilterParams, , site, weights, hideFilters] =
    useAppliedFilters();

  return (
    <div>
      {!hideFilters && filters.hasFilters() && (
        <FiltersContainer theme={theme}>
          <AppliedFiltersList
            styles={styles(theme, site)}
            filters={filters}
            setFilter={setFilter}
            clearFilters={clearFilters}
            FilterParams={FilterParams}
            weights={weights}
          />
        </FiltersContainer>
      )}
    </div>
  );
}

const FiltersContainer = styled.div`
  display: flex;
  padding: 0
    ${({theme}) => `${theme.v2.spacing(8)} ${theme.v2.spacing(8)} ${theme.v2.spacing(8)}`};

  ${media.down("md")} {
    padding: 0
      ${({theme}) =>
        `${theme.v2.spacing(4)} ${theme.v2.spacing(8)} ${theme.v2.spacing(4)}`};
  }
`;
