import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import merge from "lodash/merge";
import DeliveryTypePicker from "@ui/components/NewDeliveryBanner/DeliveryTypePicker";
import DeliveryModePicker from "@ui/components/NewDeliveryBanner/DeliveryModePicker";
import InventoryTypePicker from "@ui/components/NewDeliveryBanner/InventoryTypePicker";

export default function NewDeliveryBanner({
  fixed,
  deliveryModePickerProps,
  styles,
  LinkComponent,
  selectedDeliveryType,
  deliveryAddress,
  deliveryLocation,
  locationOptionsRoute,
  deliveryTypePickerProps,
  inventoryType,
  inventoryTypePickerPath,
  showInventoryPicker,
}) {
  const hasDeliveryPlace = Boolean(deliveryAddress && deliveryLocation);
  const deliveryType = selectedDeliveryType && selectedDeliveryType.code;

  const deliveryBannerStyles = {
    root: styles?.root,
    deliveryPickers: deliveryModePickerProps?.styles,
    productsCounter: merge({}, styles?.root, styles?.productsCounter),
  };

  const _styles = merge({}, defaultStyles, deliveryBannerStyles);

  const [displayShadow, setDisplayShadow] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      setDisplayShadow(fixed && window.scrollY > 0);
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [fixed]);

  return (
    <Container fixed={fixed} styles={_styles.root} displayShadow={displayShadow}>
      <Content styles={_styles.root}>
        <LeftSection hasInventoryPicker={showInventoryPicker}>
          <DeliveryTypePicker
            {...deliveryTypePickerProps}
            styles={_styles}
            hasDeliveryPlace={hasDeliveryPlace}
            selectedDeliveryType={deliveryType}
          />
          {deliveryType && (
            <DeliveryModePicker
              styles={_styles}
              selectedDeliveryType={deliveryType}
              selectedMode={deliveryModePickerProps.selectedMode}
              selectedLocation={deliveryLocation}
              locationOptionsRoute={locationOptionsRoute}
              LinkComponent={LinkComponent}
            />
          )}
        </LeftSection>
        {showInventoryPicker && (
          <RightSection>
            <InventoryTypePicker
              styles={_styles}
              showInventoryPicker={showInventoryPicker}
              LinkComponent={LinkComponent}
              inventoryType={inventoryType}
              inventoryTypePickerPath={inventoryTypePickerPath}
            />
          </RightSection>
        )}
      </Content>
    </Container>
  );
}

const defaultStyles = {
  root: {
    backgroundColor: "#fafafa",
    color: "#000",
    padding: "0px 0px 0px 5px",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "14px",
      sm: "12px",
    },
    fontWeight: "600",
  },
  productsCounter: {
    selectedColor: "#000",
  },
  deliveryPickers: {
    iconColor: "#000",
    backgroundColor: "#bcbcbc",
    color: "#272727",
    selectedColor: "#fafafa",
    hoverSelectedBackgroundColor: "#1E4526",
    pickupColor: "#1E4526",
    hoverBackgroundColor: "green",
    hoverTextColor: "#fafafa",
    borderRadius: "4px",
    maxWidth: {
      sm: "130px",
    },
  },
};

const Container = styled.div.attrs(() => ({
  className: "new-delivery-banner new-delivery-banner__container",
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({styles}) => styles.fontFamily};
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  background-color: ${({styles}) => styles.backgroundColor};
  color: ${({styles}) => styles.color};
  z-index: 2;
  border-bottom: ${({displayShadow}) => (!displayShadow ? "1px solid #E1E1E1" : "none")};
  position: ${({fixed}) => (fixed ? "fixed" : "relative")};
  box-shadow: ${({displayShadow}) =>
    displayShadow ? "rgb(0 0 0 / 20%) 0px 2px 5px 0px" : "none"};
  transition: box-shadow 0.3s ease-in-out;
`;

const Content = styled.div.attrs(() => ({
  className: "new-delivery-banner new-delivery-banner__content",
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${spacing(1)};
  width: 100%;
  box-sizing: border-box;
  max-width: 1440px;
  padding: 0 ${spacing(4)};
  font-size: ${({styles}) => styles.fontSize.lg};

  ${media.down("md")} {
    padding: 0 ${spacing(2)};
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    padding: 4px;
    font-size: ${({styles}) => styles.fontSize.sm};
    justify-content: space-between;
    > * {
      flex: 1;
    }
  }
`;

const RightSection = styled.div.attrs(() => ({
  className: "new-delivery-banner new-delivery-banner__right-section",
}))`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  ${media.down("sm")} {
    width: 100%;
    justify-content: flex-end;
    > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const LeftSection = styled.div.attrs(() => ({
  className: "new-delivery-banner new-delivery-banner__left-section",
}))`
  display: flex;
  box-sizing: border-box;
  column-gap: ${spacing(1)};

  ${media.down("sm")} {
    width: 100%;
    max-width: ${({hasInventoryPicker}) =>
      hasInventoryPicker ? "calc(100% - 70px)" : "100%"};
    min-width: ${({hasInventoryPicker}) =>
      hasInventoryPicker ? "calc(100% - 70px)" : "100%"};
    justify-content: flex-start;
    > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

NewDeliveryBanner.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    productsCounter: PropTypes.shape({
      selectedColor: PropTypes.string,
    }),
    deliveryModePicker: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      hoverSelectedBackgroundColor: PropTypes.string,
      hoverBackgroundColor: PropTypes.string,
      hoverTextColor: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  selectedDeliveryType: PropTypes.shape({
    label: PropTypes.string,
    code: PropTypes.oneOf(["pickup", "kiosk", "delivery", "unavailable"]),
  }),
  deliveryAddress: PropTypes.object,
  deliveryLocation: PropTypes.string,
  locationOptionsRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  deliveryModePickerProps: PropTypes.object,
  deliveryTypePickerProps: PropTypes.object,
  fixed: PropTypes.bool,
  LinkComponent: PropTypes.elementType,
  inventoryType: PropTypes.string,
  showInventoryPicker: PropTypes.bool,
  inventoryTypePickerPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
