import autoBind from "auto-bind";
import IdentityVerification from "src/integrations/identityVerification/IdentityVerification";

class TymberIdentityVerification extends IdentityVerification {
  constructor(identityVerificationObject) {
    super();
    autoBind(this);
    this.identityVerificationObject = identityVerificationObject;
  }
}

export default TymberIdentityVerification;
