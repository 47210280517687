import React from "react";
import useThemeConfig from "src/themes/useThemeConfig";

function RecommendedProductsList({className, title, filters, linkToAll}) {
  const config = useThemeConfig();
  const ThemedRecommendedProductsList = config.components.RecommendedProductsList;

  return (
    <ThemedRecommendedProductsList
      className={className}
      title={title}
      filters={filters}
      linkToAll={linkToAll}
    />
  );
}

export default RecommendedProductsList;
