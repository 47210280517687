import {getSiteURL} from "../../meta";
import makeLogoSchemaObject from "./logo";

function makeOrganizationSchemaObject(site, ctx) {
  const siteUrl = getSiteURL(ctx);
  return {
    "@type": "Organization",
    "@id": `${siteUrl}#organization`,
    name: site.getName(),
    url: siteUrl,
    logo: makeLogoSchemaObject(site, ctx),
    image: {
      "@id": `${siteUrl}#logo`,
    },
  };
}

export default makeOrganizationSchemaObject;
