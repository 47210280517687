class AuthenticationHandler {
  onLoginSuccess(user) {}

  onLoginFailure(error) {}

  onRegistrationSuccess(newUser) {}

  onRegistrationFailure(error) {}

  onUserProfileFetched(userProfile) {}

  onLogout() {}

  recoverPasswordSuccess(data) {}

  recoverPasswordFailure(error) {}
}

export default AuthenticationHandler;
