import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import merge from 'lodash/merge';
import media from '@ui/utils/media';

export default function ClearFilters({ label, onClick, styles }) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container onClick={onClick} styles={_styles}>
      {label}
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: 'filters-panel__container',
  'data-keep-cart': 'true'
}))`
  cursor: pointer;
  color: ${({ styles }) => styles.color};
  text-decoration: ${({ styles }) => styles.textDecoration};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  font-size: ${({ styles }) => styles.fontSize.lg};

  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  fontFamily: 'sans-serif',
  fontSize: {
    lg: '14px',
    sm: '14px'
  },
  fontWeight: 400,
  color: '#000',
  textDecoration: 'none'
};

ClearFilters.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.shape({
    size: PropTypes.shape({
      lg: PropTypes.string,
      sm: PropTypes.string
    }),
    color: PropTypes.string,
    textDecoration: PropTypes.string
  })
};
