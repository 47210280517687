import React from "react";
import Link from "src/core/common/components/modules/Link";
import {DropdownMenuButton, DropdownPanel} from "@ui";

function HeaderShopOptions(groups, styles) {
  return {
    component: () => (
      <DropdownMenuButton
        label="SHOP"
        MenuComponent={({offsetX, offsetY}) => (
          <DropdownPanel
            groups={groups}
            offsetX={offsetX}
            offsetY={offsetY}
            LinkComponent={Link}
            styles={styles}
          />
        )}
        styles={styles}
      />
    ),
  };
}

export default HeaderShopOptions;
