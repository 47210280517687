import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import addImgixParams from "@ui/utils/imgix";

export default function PromotionalImage({image, hasLink, dimensions}) {
  return (
    <ImageContainer hasLink={hasLink} dimensions={dimensions}>
      <Image image={image} hasLink={hasLink} dimensions={dimensions} />
    </ImageContainer>
  );
}

const Image = styled.div`
  background-size: cover;

  background-image: ${({image, dimensions}) =>
    `image-set(url(${addImgixParams(
      image.srcDesktop,
      dimensions.lg
    )}) 1x, url(${addImgixParams(
      image.srcDesktop,
      dimensions.lg,
      2
    )}) 2x, url(${addImgixParams(image.srcDesktop, dimensions.lg, 3)}) 3x)`};
  ${media.down("md")} {
    background-image: ${({image, dimensions}) =>
      `image-set(url(${addImgixParams(
        image.srcDesktop,
        dimensions.md
      )}) 1x, url(${addImgixParams(
        image.srcDesktop,
        dimensions.md,
        2
      )}) 2x, url(${addImgixParams(image.srcDesktop, dimensions.md, 3)}) 3x)`};
  }
  ${media.down("sm")} {
    background-image: ${({image, dimensions}) =>
      `image-set(url(${addImgixParams(
        image.srcMobile,
        dimensions.sm
      )}) 1x, url(${addImgixParams(
        image.srcMobile,
        dimensions.sm,
        2
      )}) 2x, url(${addImgixParams(image.srcMobile, dimensions.sm, 3)}) 3x)`};
  }

  height: 100%;

  cursor: ${({hasLink}) => (hasLink ? "pointer" : "default")};
  min-width: 100%;
  max-height: 100%;
  width: auto !important;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0px, 0px);
`;
const ImageContainer = styled.div.attrs(() => ({
  className: "promotional-banner__image-container",
}))`
  position: relative;
  overflow: hidden;
  &:hover {
    cursor: ${({hasLink}) => `${hasLink ? "pointer" : "auto"}`};
  }
  background-color: black;
  padding-bottom: ${({dimensions}) =>
    `${(dimensions.lg.height / dimensions.lg.width) * 100}%`};
  ${media.down("md")} {
    padding-bottom: ${({dimensions}) =>
      `${(dimensions.md.height / dimensions.md.width) * 100}%`};
  }
  ${media.down("sm")} {
    padding-bottom: ${({dimensions}) =>
      `${(dimensions.sm.height / dimensions.sm.width) * 100}%`};
  }
`;

PromotionalImage.propTypes = {
  hasLink: PropTypes.bool,
  image: PropTypes.shape({
    srcDesktop: PropTypes.string,
    srcMobile: PropTypes.string,
  }),
  dimensions: PropTypes.shape({
    lg: PropTypes.shape({}),
    md: PropTypes.shape({}),
    sm: PropTypes.shape({}),
  }),
};
