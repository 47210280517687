import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import useDeliveryMode from "src/core/deliveries/hooks/useDeliveryMode";
import useDeliveryAddress from "src/core/deliveries/hooks/useDeliveryAddress";
import useVerifyAddress from "src/core/deliveries/hooks/useVerifyAddress";
import {useCallback, useEffect, useState} from "react";
import {useShop} from "src/core/shops";
import useRouter from "src/core/common/hooks/useRouter";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useAppLoader from "src/core/common/hooks/useAppLoader";
import routes from "src/core/common/routes";
import {DeliveryTypes, makeDeliveryType} from "src/core/common/models/DeliveryType";
import {DeliveryModes} from "src/core/common/models/deliveryMode";

function useDeliveryModeSelector() {
  const [, setDeliveryMode] = useDeliveryMode();
  const [address] = useDeliveryAddress();
  const [, verify, getByAddress] = useVerifyAddress();
  const [submitted, setSubmitted] = useState(false);
  const [shop] = useShop();
  const router = useRouter();
  const [, selectDeliveryType] = useDeliveryType();
  const toggles = useFeatureToggles();
  const {switchStore} = useAppLoader();

  const goToDeliveryCheck = useCallback(
    (hideError, mode) =>
      router.push({
        pathname: routes.deliveryCheck,
        query: {
          deliveryMode: mode,
        },
      }),
    [router]
  );

  /* eslint-disable */
  useEffect(() => {
    const mode = submitted;
    const {loading, data} = getByAddress(address);
    const verification = data;
    if (!verification) return;

    if (submitted && !loading && verification) {
      setSubmitted(false);
      if (
        verification.deliversToShop(shop.data, mode) ||
        (toggles.deliveriesOnly() && verification.deliversToAny(mode))
      ) {
        const deliveryType = makeDeliveryType(DeliveryTypes.DELIVERY);
        selectDeliveryType(deliveryType);
        setDeliveryMode({address, mode});

        // Waits until mode and delivery type sagas are executed
        setTimeout(() => {
          if (!verification.deliversToShop(shop.data, mode)) {
            const anyShop = verification.getAnyDeliveryShop(mode);
            switchStore(anyShop.getId());
          }
        });
      } else if (verification.deliversToAny(mode)) {
        const url = verification.getAnyDeliveryShopUrl(
          {
            deliveryType: DeliveryTypes.DELIVERY,
            deliveryMode: mode,
            address: address,
          },
          toggles
        );
        if (url) {
          router.pushExternal(url);
        } else {
          goToDeliveryCheck(false, mode);
        }
      } else {
        goToDeliveryCheck(false, mode);
      }
    }
  }, [shop.data, getByAddress, submitted, goToDeliveryCheck]);
  /* eslint-enable */

  function verifyExpressAddress() {
    if (address) {
      setSubmitted(DeliveryModes.EXPRESS);
      verify({address, mode: DeliveryModes.EXPRESS});
    } else {
      goToDeliveryCheck(true, DeliveryModes.EXPRESS);
    }
  }

  function setDefaultDeliveryMode() {
    if (address) {
      setSubmitted(DeliveryModes.SCHEDULED);
      verify({address, mode: DeliveryModes.SCHEDULED});
    } else {
      goToDeliveryCheck(true, DeliveryModes.SCHEDULED);
    }
  }

  const verification = getByAddress(address).data;

  return {
    goToDeliveryCheck,
    verifyExpressAddress,
    setDefaultDeliveryMode,
    verification,
  };
}

export default useDeliveryModeSelector;
