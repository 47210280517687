import store from "../store/store";
import {useEffect, useState} from "react";
import Shop from "../shop";

function useShop() {
  const [state, setState] = useState(store.getState());

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return [state.shop, Shop];
}

export default useShop;
