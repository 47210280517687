import React, {useState} from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import ConfirmationModal from "@ui/components/ConfirmationModal";

export default function FieldWithConfirmation({
  title,
  subtitle,
  helperText,
  cancelText,
  confirmText,
  modalStyles,
  fieldRender,
  ...fieldProps
}) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [callback, setCallback] = useState(null);

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const onConfirm = () => {
    closeConfirmationModal();
    callback && callback.call();
  };

  const onCancel = () => {
    closeConfirmationModal();
  };

  const onChange = (requiresConfirmation, callback) => {
    if (!requiresConfirmation) {
      callback();
    } else {
      setIsConfirmationModalOpen(true);
      setCallback({call: callback});
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onRequestClose={onCancel}
        onCancel={onCancel}
        onConfirm={onConfirm}
        title={title}
        subtitle={subtitle}
        helperText={helperText}
        cancelText={cancelText}
        confirmText={confirmText}
        styles={modalStyles}
      />
      <Field
        {...fieldProps}
        render={(form) => fieldRender({...form, confirmation: {onChange}})}
      />
    </>
  );
}

FieldWithConfirmation.defaultProps = {
  cancelText: "Cancel",
  confirmText: "Confirm",
};

FieldWithConfirmation.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  helperText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  modalStyles: PropTypes.shape({}),
  fieldRender: PropTypes.func,
};
