import React from 'react';
import styled from 'styled-components';

function SlideIndicator({
  styles,
  numSlides,
  maxIndicators,
  currentSlide,
  onSelectSlide,
  SlideIndicatorComponent
}) {
  let _maxIndicators = maxIndicators;
  if (_maxIndicators % 2 === 0) {
    _maxIndicators += 1;
  }

  return (
    <Container
      maxIndicators={_maxIndicators}
      currentSlide={currentSlide}
      numSlides={numSlides}
    >
      {[...new Array(numSlides)].map((_, index) => (
        <SlideIndicatorComponent
          active={currentSlide === index}
          key={index}
          styles={styles.indicator}
          onClick={() => onSelectSlide(index)}
        />
      ))}
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: 'slide-indicator slide-indicator__container'
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  > .slide-dot-indicator.slide-dot-indicator__indicator {
    display: none;
    ${({ maxIndicators, currentSlide, numSlides }) =>
      visibleChildrenSelector(maxIndicators, currentSlide, numSlides)} {
      display: block;
    }
  }
`;

const visibleChildrenSelector = (maxIndicators, currentSlide, numSlides) => {
  const [start, end] = slide([1, maxIndicators], currentSlide + 1, numSlides);
  let selectors = [];
  for (let i = start; i <= end; i++) {
    selectors.push(`&:nth-child(${i})`);
  }
  return selectors.join(',');
};
function slide([start, end], position, numSlides) {
  const size = end - start;
  const middle = start + size / 2;
  if (position <= middle) {
    return [start, end];
  } else {
    const diffFromMiddle = position - middle;
    return [
      Math.min(start + diffFromMiddle, numSlides - size),
      Math.min(end + diffFromMiddle, numSlides)
    ];
  }
}

SlideIndicator.defaultProps = {
  maxIndicators: 5
};

export default SlideIndicator;
