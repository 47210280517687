import React, {useState} from "react";
import PropTypes from "prop-types";
import InfoNotification from "./InfoNotification";
import useTimeBoundEffect, {TimeBoundary} from "@ui/utils/useTimeBoundEffect";

export default function TimedInfoNotification(props) {
  const [timeBoundary] = useState(new TimeBoundary(+new Date(), 30));

  const [elapsedTime] = useTimeBoundEffect(timeBoundary, props.onTimerFinish, false);

  const remainingTimeToRedirect =
    elapsedTime !== null
      ? timeBoundary.waitTime.seconds - elapsedTime.elapsedSeconds
      : null;

  return (
    <InfoNotification
      {...props}
      description={props.descriptionFn(remainingTimeToRedirect)}
    />
  );
}

TimedInfoNotification.propTypes = {
  ...InfoNotification.propTypes,
  descriptionFn: PropTypes.func,
  onTimerFinish: PropTypes.func,
};
