import {getBrandDetailUrl} from "src/core/seo/meta";

function makeBrandSchemaObject(brand, ctx) {
  const brandDetailUrl = getBrandDetailUrl(brand, ctx);
  return {
    "@context": "https://schema.org",
    "@type": "Brand",
    "@id": `${brandDetailUrl}#brand`,
    name: brand.getName(),
    url: brandDetailUrl,
    description: brand.getDescription(),
    image: brand.getLogo(),
    // sku: product.getSku(),
    // offers: [makeOffer(brand, site)],
  };
}

export default makeBrandSchemaObject;
