import ActionTypes from "./actionTypes";
import {combineReducers} from "redux";
import {getError} from "src/core/common/utils";

const cartInitialState = {
  loading: false,
  data: null,
  error: null,
};
function cart(state = cartInitialState, action) {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {...state, loading: action.payload.loading};
    case ActionTypes.REQUEST_SET_PROMO_CODES:
    case ActionTypes.REQUEST_SET_REWARD:
    case ActionTypes.REQUEST_SET_PAYMENT_OPTION:
    case ActionTypes.REQUEST_CART_VALIDATION:
    case ActionTypes.REQUEST_LOAD_CART:
    case ActionTypes.REQUEST_CREATE_CART:
    case ActionTypes.REQUEST_ADD_ITEM:
    case ActionTypes.REQUEST_DELETE_ITEM:
    case ActionTypes.REQUEST_UPDATE_ITEM:
      return {...state, loading: true};
    case ActionTypes.REQUEST_SET_PROMO_CODES_FAILURE:
    case ActionTypes.REQUEST_SET_REWARD_FAILURE:
    case ActionTypes.REQUEST_SET_PAYMENT_OPTION_FAILURE:
    case ActionTypes.REQUEST_LOAD_CART_FAILURE:
    case ActionTypes.REQUEST_CREATE_CART_FAILURE:
    case ActionTypes.REQUEST_UPDATE_ITEM_FAILURE:
    case ActionTypes.REQUEST_ADD_ITEM_FAILURE:
    case ActionTypes.REQUEST_DELETE_ITEM_FAILURE:
      if (state.error && state.errorFromValidation) {
        return {...state, loading: false};
      } else {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          errorFromValidation: false,
        };
      }
    case ActionTypes.REQUEST_CART_VALIDATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        errorFromValidation: true,
      };
    case ActionTypes.REQUEST_SET_PAYMENT_OPTION_SUCCESSFUL:
    case ActionTypes.REQUEST_SET_PROMO_CODES_SUCCESSFUL:
    case ActionTypes.REQUEST_SET_REWARD_SUCCESSFUL:
    case ActionTypes.REQUEST_LOAD_CART_SUCCESSFUL:
    case ActionTypes.REQUEST_CREATE_CART_SUCCESSFUL:
    case ActionTypes.REQUEST_ADD_ITEM_SUCCESSFUL:
    case ActionTypes.REQUEST_DELETE_ITEM_SUCCESSFUL:
    case ActionTypes.REQUEST_UPDATE_ITEM_SUCCESSFUL:
      return {...state, loading: false, data: action.payload.cart};
    case ActionTypes.SET_CART:
      return {...state, loading: false, data: action.payload.cart.clone()};
    case ActionTypes.REQUEST_CART_VALIDATION_SUCCESSFUL:
      return {...state, loading: false, error: null, data: action.payload.cart};
    case ActionTypes.CLEAR_CART:
      return cartInitialState;
    default:
      return state;
  }
}

const initialOrderState = {
  loading: false,
  error: null,
  data: null,
};
function order(state = initialOrderState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_CREATE_ORDER_FROM_CART:
    case ActionTypes.REQUEST_LOAD_ORDER:
      return {
        ...state,
        loading: true,
        error: null,
        data: action.payload.refresh ? state.data : null,
      };
    case ActionTypes.REQUEST_PAYMENT:
      return {...state, loading: true};
    case ActionTypes.REQUEST_CREATE_ORDER_FROM_CART_SUCCESSFUL:
    case ActionTypes.REQUEST_LOAD_ORDER_SUCCESSFUL:
    case ActionTypes.REQUEST_PAYMENT_SUCCESSFUL:
      return {...state, loading: false, error: null, data: action.payload.order};
    case ActionTypes.REQUEST_CREATE_ORDER_FROM_CART_FAILURE:
    case ActionTypes.REQUEST_LOAD_ORDER_FAILURE:
      return {...state, loading: false, error: action.payload.error, data: null};
    case ActionTypes.REQUEST_PAYMENT_FAILURE:
      return {...state, loading: false, error: action.payload.error};
    case ActionTypes.CLEAR_CART:
      const error = getError("cart_already_submitted", state.error);
      if (error) {
        return {...state, error: null};
      }
      return state;
    case ActionTypes.CLEAR_ORDER:
      return initialOrderState;
    default:
      return state;
  }
}

const initialOrderReviewState = {
  loading: false,
  error: null,
  data: null,
};
function orderReview(state = initialOrderReviewState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_CREATE_ORDER_REVIEW:
      return {...state, loading: true, error: null, data: null};
    case ActionTypes.REQUEST_CREATE_ORDER_REVIEW_SUCCESSFUL:
      return {...state, loading: false, error: null, data: action.payload.review};
    case ActionTypes.REQUEST_CREATE_ORDER_REVIEW_FAILURE:
      return {...state, loading: false, error: action.payload.error, data: null};
    default:
      return state;
  }
}

const initialOnBoardingState = {
  data: undefined,
};
function onBoarding(state = initialOnBoardingState, action) {
  switch (action.type) {
    case ActionTypes.SET_ON_BOARDING_DATA:
      return {...state, data: action.payload};
    default:
      return state;
  }
}

export default combineReducers({
  order,
  orderReview,
  cart,
  onBoarding,
});
