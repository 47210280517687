import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useQueryParams from "src/core/common/hooks/useQueryParams";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import {IdentityVerification} from "src/integrations/identityVerification/factory";
import {ErrorTags} from "src/core/api/errorHandlers";
import routes from "src/core/common/routes";

export default function useIdentityVerificationProps() {
  const [params] = useQueryParams([ERROR_CODE]);
  const toggles = useFeatureToggles();
  const [, authApi] = useAuthentication();

  const hasVerificationRequiredQueryParam =
    params[ERROR_CODE] === ErrorTags.VERIFICATION_REQUIRED;
  const identityVerificationService = toggles.getIdentityVerificationServiceName();
  const isLoggedIn = authApi.isLoggedIn();

  const signupModalTitle =
    identityVerificationService === IdentityVerification.AGECHECKER &&
    hasVerificationRequiredQueryParam
      ? "ID verification required"
      : "Sign Up";

  const signupModalSubtitle =
    identityVerificationService === IdentityVerification.AGECHECKER &&
    hasVerificationRequiredQueryParam
      ? "As per regulation, we require you to verify you are of legal age to view our Website and Shop online. "
      : null;

  const signupButtonLabel =
    identityVerificationService === IdentityVerification.AGECHECKER &&
    hasVerificationRequiredQueryParam
      ? "Continue to verification"
      : "Sign Up";

  const loginProps = {
    loginLabel: "login to your account",
    verifyLabel: "verify your age",
    loginUrl: !isLoggedIn
      ? {
          pathname: routes.login,
        }
      : null,
    verifyUrl: !isLoggedIn
      ? {
          pathname: routes.signup,
          query: {
            [ERROR_CODE]: ErrorTags.VERIFICATION_REQUIRED,
          },
        }
      : null,
  };

  return {
    hasVerificationRequiredQueryParam,
    signupModalTitle,
    signupModalSubtitle,
    signupButtonLabel,
    loginProps,
  };
}

export const ERROR_CODE = "error_code";
