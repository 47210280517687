import React from "react";
import styled from "styled-components";

// TODO: move to UI
function Error({children, ...props}) {
  return <ErrorContainer {...props}>{children}</ErrorContainer>;
}

const ErrorContainer = styled.div`
  color: ${({theme}) => theme.v2?.color?.error || "red"};
  font-size: ${({theme}) => theme.v2.typography.bodyText2.size.lg};
  font-family: ${({theme}) => theme.v2.typography.bodyText2.family};
  font-weight: ${({theme}) => theme.v2.typography.bodyText2.weight};
  line-height: 150%;
  letter-spacing: -0.2px;
`;

export const ErrorsList = styled.ul`
  list-style-type: none;
  margin: 0 auto ${({theme}) => theme.v2.spacing(4)} auto;
  padding: 0;
  text-align: center;
`;

export default Error;
