import ThemedSativaFlowerTypeIndicator from "src/core/common/components/elements/flowerTypeIndicator/ThemedFlowerTypeSativaIndicator";
import ThemedIndicaFlowerTypeIndicator from "src/core/common/components/elements/flowerTypeIndicator/ThemedFlowerTypeIndicaIndicator";
import ThemedHybridFlowerTypeIndicator from "src/core/common/components/elements/flowerTypeIndicator/ThemedFlowerTypeHybridIndicator";

class FlowerType {
  constructor(name = "", color = "", icon = null) {
    this._color = color;
    this._icon = icon;
    this._name = name;
  }

  get name() {
    return this._name;
  }

  get color() {
    return this._color;
  }

  get icon() {
    return this._icon;
  }
}

function makeFlowerType(type) {
  if (!type) return null;

  switch (type.toLowerCase().trim()) {
    case "sativa":
      return new FlowerType("Sativa", "#fd6f71", ThemedSativaFlowerTypeIndicator);
    case "sativa dominant":
      return new FlowerType(
        "Sativa Dominant",
        "#fd6f71",
        ThemedSativaFlowerTypeIndicator
      );
    case "indica":
      return new FlowerType("Indica", "#575990", ThemedIndicaFlowerTypeIndicator);
    case "indica dominant":
      return new FlowerType(
        "Indica Dominant",
        "#575990",
        ThemedIndicaFlowerTypeIndicator
      );
    case "hybrid":
      return new FlowerType("Hybrid", "#5ec291", ThemedHybridFlowerTypeIndicator);
    default:
      return null;
  }
}

export default makeFlowerType;
