class SEOConfig {
  config = {
    router: null,
    routes: null,
    meta: null,
  };

  setConfig(config) {
    this.config = {...this.config, ...config};
  }

  init(config) {
    this.setConfig(config);
  }

  setRouter(router) {
    this.config.router = router;
    return this;
  }
  getRouter() {
    return this.config.router;
  }

  setRoutes(routes) {
    this.config.routes = routes;
    return this;
  }
  getRoutes() {
    return this.config.routes;
  }

  setMeta(meta) {
    this.config.meta = meta;
    return this;
  }
  getMeta() {
    return this.config.meta;
  }
}

export default new SEOConfig();
