import React, {useContext} from "react";

const HostContext = React.createContext({
  host: null,
});

export const useHost = () => {
  return useContext(HostContext).host;
};

export default HostContext;
