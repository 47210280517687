import React from 'react';
import styled from 'styled-components';
import media from '@ui/utils/media';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';

export default function DeliveryModePicker({
  styles,
  scheduledCounter,
  expressCounter,
  selectedMode,
  onSelectExpress,
  onSelectScheduled,
  allowScheduledDelivery,
  allowExpressDelivery,
  hasDeliveryPlace
}) {
  return (
    <DeliveryModeContainer styles={styles}>
      {allowExpressDelivery && (
        <DeliveryModeOption
          styles={styles}
          selected={selectedMode === 'express'}
          onClick={onSelectExpress}
        >
          <span>Express</span>
          {isNumber(expressCounter) && hasDeliveryPlace && (
            <DeliveryModeCounter
              styles={styles}
              selected={selectedMode === 'express'}
            >
              {expressCounter}
            </DeliveryModeCounter>
          )}
        </DeliveryModeOption>
      )}

      {allowScheduledDelivery && (
        <>
          <span>&nbsp;</span>
          <DeliveryModeOption
            styles={styles}
            selected={selectedMode === 'scheduled'}
            onClick={onSelectScheduled}
          >
            <span>Scheduled</span>
            {isNumber(scheduledCounter) && hasDeliveryPlace && (
              <DeliveryModeCounter
                styles={styles}
                selected={selectedMode === 'scheduled'}
              >
                {scheduledCounter}
              </DeliveryModeCounter>
            )}
          </DeliveryModeOption>
        </>
      )}
    </DeliveryModeContainer>
  );
}

const DeliveryModeCounter = styled.div`
  font-family: ${({ styles }) => styles.fontFamily};
  background-color: ${({ styles, selected }) =>
    !selected ? styles.backgroundColor : '#fff'};
  color: ${({ styles, selected }) => (!selected ? '#fff' : styles.color)};

  font-size: 12px;
  padding: 4px;
  min-width: 24px;
  box-sizing: border-box;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeliveryModeContainer = styled.div.attrs(() => ({
  className: 'delivery-mode-picker__container'
}))`
  border: 1px solid ${({ styles }) => `${styles.backgroundColor}`};
  border-radius: ${({ styles }) => styles.borderRadius || '4px'};
  font-family: ${({ styles }) => styles.fontFamily};
  padding: 3px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  ${media.down('sm')} {
    min-width: 250px;
    width: 100%;
  }
`;

const DeliveryModeOption = styled.div.attrs(() => ({
  className: 'delivery-mode-picker__option'
}))`
  display: flex;
  column-gap: 8px;
  text-align: center;
  width: 120px;
  height: 35px;
  cursor: pointer;
  font-weight: 800;
  color: ${({ styles, selected }) => (selected ? '#fff' : `${styles.color}`)};
  background-color: ${({ styles, selected }) =>
    selected ? `${styles.backgroundColor}` : 'transparent'};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  padding: 1px 8px;

  &:first-of-type {
    border-top-left-radius: ${({ styles }) => styles.borderRadius || '4px'};
    border-bottom-left-radius: ${({ styles }) => styles.borderRadius || '4px'};
  }

  &:last-of-type {
    border-top-right-radius: ${({ styles }) => styles.borderRadius || '4px'};
    border-bottom-right-radius: ${({ styles }) => styles.borderRadius || '4px'};
  }

  &:hover {
    background-color: ${({ styles, selected }) =>
      selected
        ? `${styles.hoverSelectedBackgroundColor}`
        : `${styles.hoverBackgroundColor}`};
    color: ${({ styles }) => styles.hoverTextColor};

    ${DeliveryModeCounter} {
      background-color: #fff;
      color: ${({ styles, selected }) => styles.color};
    }
  }

  font-size: ${({ styles }) => (styles.fontSize ? styles.fontSize.sm : '16px')};

  ${media.down('md')} {
    font-size: ${({ styles }) =>
      styles.fontSize ? styles.fontSize.sm : '14px'};
  }

  ${media.down('sm')} {
    font-size: ${({ styles }) =>
      styles.fontSize ? styles.fontSize.sm : '12px'};
    border-radius: ${({ styles }) => styles.borderRadius || '4px'};
    width: 50%;
  }
`;

DeliveryModePicker.propTypes = {
  styles: PropTypes.shape({
    fontFamily: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    hoverSelectedBackgroundColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    hoverTextColor: PropTypes.string,
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string
    })
  }),
  allowExpressDelivery: PropTypes.bool,
  allowScheduledDelivery: PropTypes.bool,
  hasDeliveryPlace: PropTypes.bool,
  onSelectScheduled: PropTypes.func,
  onSelectExpress: PropTypes.func,
  selectedMode: PropTypes.string,
  scheduledCounter: PropTypes.number,
  expressCounter: PropTypes.number
};
