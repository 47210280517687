import {getProductCatalogUrl} from "../../meta";

function makeSeller(site, ctx) {
  return {
    "@type": "Organization",
    name: site.getName(),
    url: getProductCatalogUrl(ctx),
  };
}

export default makeSeller;
