import useCartState from "../hooks/useCartState";

function ItemsInCart({render}) {
  const [cartState] = useCartState();
  return render({
    numberOfItems: cartState.data ? cartState.data.getNumberOfItems() : null,
  });
}

export default ItemsInCart;
