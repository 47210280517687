import Tracker from "./trackers/tracker";
import autoBind from "auto-bind";

class AnalyticsConfig {
  constructor() {
    autoBind(this);
  }

  config = {
    tracker: new Tracker(),
  };

  setConfig(config) {
    this.config = {...this.config, ...config};
  }

  setTracker(tracker) {
    this.config.tracker = tracker;
    return this;
  }
  getTracker() {
    return this.config.tracker;
  }
}

export default new AnalyticsConfig();
