import {logEvent} from "src/core/analytics/errorReporting";
import CheckoutActionTypes from "src/core/checkout/state/actionTypes";
import AuthActionTypes from "src/core/authentication/state/actionTypes";

// list of error to be sent to sentry
const filterList = [
  ...Object.values(CheckoutActionTypes).filter(v => v.endsWith("failure")),
  ...Object.values(AuthActionTypes).filter(v => v.endsWith("failure")),
];

function createLoggerMiddleware() {
  return _store => next => action => {
    if (filterList.includes(action.type)) {
      let stringPayload;
      try {
        stringPayload = JSON.stringify(action.payload);
      } catch (e) {
        stringPayload = "";
      }
      logEvent(
        action.type,
        "warning",
        action.payload ? {...action.payload, str: stringPayload} : {str: stringPayload}
      );
    }
    return next(action);
  };
}

export default createLoggerMiddleware();
