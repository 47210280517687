import {ActionTypes} from "../actions";

const initialState = {
  loadingSearchTerm: null,
  searchTerm: null,
  products: {
    loading: false,
    data: [],
    error: null,
    limit: 10,
    offset: 0,
    totalCount: null,
  },
  categories: {
    loading: false,
    data: [],
    error: null,
    limit: 10,
    offset: 0,
    totalCount: null,
  },
  brands: {
    loading: false,
    data: [],
    error: null,
    limit: 10,
    offset: 0,
    totalCount: null,
  },
};

export default function reducer(state = initialState, action) {
  let type = action.payload?.type;
  switch (action.type) {
    case ActionTypes.SEARCH:
      if (!action.payload?.options?.type) {
        return ["products", "categories", "brands"].reduce(
          (acc, type) => {
            return handleSearchRequest(acc, type, action);
          },
          {...state}
        );
      } else {
        return handleSearchRequest(state, action.payload.options.type, action);
      }

    case ActionTypes.SEARCH_SUCCESS:
      if (!type) {
        const result = ["products", "categories", "brands"].reduce(
          (acc, type) => {
            return handleSearchSuccessful(acc, type, action);
          },
          {...state}
        );
        return {
          ...result,
          searchTerm: state.loadingSearchTerm,
          loadingSearchTerm: null,
        };
      } else {
        return handleSearchSuccessful(state, type, action);
      }

    case ActionTypes.SEARCH_FAILURE:
      if (!type) {
        return ["products", "categories", "brands"].reduce(
          (acc, type) => {
            return handleSearchFailure(acc, type, action);
          },
          {...state}
        );
      } else {
        return handleSearchFailure(state, type, action);
      }

    default:
      return state;
  }
}

const handleSearchRequest = (state, type, action) => {
  const reset = action.payload.term !== state.searchTerm || action.payload.options.reset;
  if (reset) {
    return {
      ...state,
      loadingSearchTerm: action.payload.term,
      [type]: {
        loading: true,
        data: [],
        error: null,
        limit: 10,
        offset: 0,
        totalCount: null,
      },
    };
  } else {
    return {
      ...state,
      [type]: {...state[type], loading: true},
    };
  }
};
const handleSearchSuccessful = (state, type, action) => ({
  ...state,
  [type]: {
    loading: false,
    data: [...state[type].data, ...action.payload.data[type].getElements()],
    error: null,
    limit: state[type].limit,
    offset: action.payload.data[type].getPageOffset(),
    totalCount: action.payload.data[type].getTotalCount(),
  },
});

const handleSearchFailure = (state, type, action) => ({
  ...state,
  [type]: {...state[type], loading: false, error: action.payload},
});
