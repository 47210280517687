import React from "react";
import Link from "src/core/common/components/modules/Link";
import {Breadcrumbs} from "@ui";
import useBreadcrumbs from "src/core/common/hooks/useBreadcrumbs";
import styles from "src/themes/flight/components/Breadcrumbs/styles";
import useSite from "src/core/sites/hooks/useSite";
import {useTheme} from "styled-components";

export default function FlightBreadcrumbs() {
  const site = useSite();
  const theme = useTheme();
  const [linkList] = useBreadcrumbs();

  return (
    <Breadcrumbs LinkComponent={Link} links={linkList} styles={styles(theme, site)} />
  );
}
