import {useEffect, useState} from "react";
import store from "../state/store";
import {Deliveries} from "../api";

function useAddressSearch() {
  const [state, setState] = useState(store.getState());

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return [state.addressSearch, Deliveries.searchAddress];
}

export default useAddressSearch;
