import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import DeliveryTypePicker from "@ui/components/DeliveryBanner/DeliveryTypePicker";
import DeliveryLocationPicker from "@ui/components/DeliveryBanner/DeliveryLocationPicker";
import {MapPin2} from "@styled-icons/remix-line/MapPin2";
import merge from "lodash/merge";
import DeliveryModePicker from "@ui/components/DeliveryBanner/DeliveryModePicker";
import widthLimited from "@ui/components/Decorators/widthLimited";

const DeliveryBanner = ({
  fixed,
  displayArrows,
  deliveryModePickerProps,
  allowExpressDelivery,
  allowScheduledDelivery,
  LinkComponent,
  styles,
  deliveryTypeOptionsRoute,
  selectedDeliveryType,
  deliveryAddress,
  deliveryLocation,
  MapIcon,
  locationOptionsRoute,
}) => {
  const isDelivery = !!(selectedDeliveryType && selectedDeliveryType.code === "delivery");
  const showDeliveryPicker = !!((!isDelivery || deliveryAddress) && selectedDeliveryType);
  const hasDeliveryPlace = !!(deliveryAddress && deliveryLocation);
  const requiresAddress = !!(isDelivery && !deliveryAddress);
  const hasDeliveryModePicker = !!(
    isDelivery && deliveryModePickerProps.allowExpressDelivery
  );

  const deliveryModePickerStyles = merge(
    {},
    styles.root,
    styles.deliveryModePicker,
    deliveryModePickerProps.styles || {}
  );

  return (
    <>
      <Container
        fixed={fixed}
        styles={styles.root}
        hasDeliveryModePicker={hasDeliveryModePicker}
      >
        <Content styles={styles.root}>
          <LeftSection>
            {showDeliveryPicker && (
              <DeliveryTypePicker
                displayArrows={displayArrows}
                LinkComponent={LinkComponent}
                deliveryTypeOptionsRoute={deliveryTypeOptionsRoute}
                styles={styles.root}
                selectedDeliveryType={selectedDeliveryType}
              />
            )}
            {hasDeliveryPlace && (
              <div style={{display: "flex", alignItems: "center"}}>
                <span>at</span>
              </div>
            )}
            {requiresAddress && (
              <MapPinContainer styles={styles}>
                <MapIcon />
              </MapPinContainer>
            )}
            <DeliveryLocationPicker
              displayArrows={displayArrows}
              LinkComponent={LinkComponent}
              hasDeliveryPicker={showDeliveryPicker}
              selectedLocation={deliveryLocation}
              styles={styles.root}
              locationOptionsRoute={locationOptionsRoute}
            />
          </LeftSection>
          {hasDeliveryModePicker && (
            <RightSection>
              <DeliveryModePicker
                {...deliveryModePickerProps}
                styles={deliveryModePickerStyles}
                hasDeliveryPlace={hasDeliveryPlace}
              />
            </RightSection>
          )}
        </Content>
      </Container>
      {fixed && <FixedPlaceholder hasDeliveryModePicker={hasDeliveryModePicker} />}
    </>
  );
};

const MapPinContainer = styled.div`
  display: flex;
  align-items: center;
  > * {
    height: 24px;
    width: 24px;
    color: ${({styles}) => styles.color};
  }
`;

const defaultStyles = {
  root: {
    backgroundColor: "#fafafa",
    color: "#272727",
    padding: "0px 0px 0px 5px",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "12px",
    },
    fontWeight: "600",
  },
  deliveryModePicker: {
    backgroundColor: "#272727",
    color: "#272727",
    hoverSelectedBackgroundColor: "#000",
    hoverBackgroundColor: "#272727",
    hoverTextColor: "#fafafa",
    borderRadius: "4px",
  },
};
const Container = styled.div.attrs(() => ({
  className: "delivery-banner delivery-banner__container",
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({styles}) => styles.fontFamily};
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  ${media.down("sm")} {
    height: ${({hasDeliveryModePicker}) => (hasDeliveryModePicker ? "98px" : "53px")};
    padding: ${spacing(1)} 0;
  }
  background-color: ${({styles}) => styles.backgroundColor};
  color: ${({styles}) => styles.color};
  z-index: 2;
  position: ${({fixed}) => (fixed ? "fixed" : "relative")};
`;
const FixedPlaceholder = styled.div`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  ${media.down("sm")} {
    height: ${({hasDeliveryModePicker}) => (hasDeliveryModePicker ? "98px" : "53px")};
    padding: ${spacing(1)} 0;
  }
`;
const Content = widthLimited(styled.div.attrs(() => ({
  className: "delivery-banner delivery-banner__content",
}))`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.down("sm")} {
    justify-content: center;
    flex-direction: column;
    > * {
      flex: 1;
    }
  }

  font-size: ${({styles}) => styles.fontSize.lg};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`);

const LeftSection = styled.div.attrs(() => ({
  className: "delivery-banner delivery-banner__left-section",
}))`
  display: flex;
  box-sizing: border-box;
  > *:not(:last-child) {
    margin-right: ${spacing(1)};
  }

  ${media.down("sm")} {
    width: 100%;
    justify-content: flex-start;
  }
`;
const RightSection = styled.div.attrs(() => ({
  className: "delivery-banner delivery-banner__right-section",
}))`
  display: flex;
  box-sizing: border-box;

  ${media.down("sm")} {
    width: 100%;
    justify-content: center;
  }
`;

DeliveryBanner.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    deliveryModePicker: DeliveryModePicker.propTypes.styles,
  }),
  deliveryOptionsRoute: PropTypes.string,
  selectedDeliveryType: PropTypes.shape({
    label: PropTypes.string,
    code: PropTypes.oneOf(["pickup", "kiosk", "delivery", "unavailable"]),
  }),
  deliveryAddress: PropTypes.object,
  deliveryLocation: PropTypes.string,
  MapIcon: PropTypes.elementType,
  locationOptionsRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  deliveryModePickerProps: PropTypes.shape(DeliveryModePicker.propTypes),
  allowExpressDelivery: PropTypes.bool,
  allowScheduledDelivery: PropTypes.bool,
  displayArrows: PropTypes.bool,
  deliveryTypeOptionsRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fixed: PropTypes.bool,
  LinkComponent: PropTypes.elementType,
};

DeliveryBanner.defaultProps = {
  styles: defaultStyles,
  MapIcon: MapPin2,
  deliveryModePickerProps: {},
};

export default DeliveryBanner;
