export default {
  REQUEST_USER_REGISTRATION: "authentication/request-user-registration",
  REQUEST_USER_REGISTRATION_SUCCESS: "authentication/request-user-registration-success",
  REQUEST_USER_REGISTRATION_FAILURE: "authentication/request-user-registration-failure",

  REQUEST_USER_LOGIN: "authentication/request-user-login",
  REQUEST_USER_LOGIN_SUCCESS: "authentication/request-user-login-success",
  REQUEST_USER_LOGIN_FAILURE: "authentication/request-user-login-failure",

  LOAD_USER: "authentication/load-user",

  LOAD_USER_PROFILE: "authentication/load-user-profile",
  LOAD_USER_PROFILE_SUCCESS: "authentication/load-user-profile-success",
  LOAD_USER_PROFILE_FAILURE: "authentication/load-user-profile-failure",

  REQUEST_RECOVER_PASSWORD: "authentication/request-recover-password",
  REQUEST_RECOVER_PASSWORD_SUCCESS: "authentication/request-recover-password-success",
  REQUEST_RECOVER_PASSWORD_FAILURE: "authentication/request-recover-password-failure",

  REQUEST_RESET_PASSWORD: "authentication/request-reset-password",
  REQUEST_RESET_PASSWORD_SUCCESS: "authentication/request-reset-password-success",
  REQUEST_RESET_PASSWORD_FAILURE: "authentication/request-reset-password-failure",

  REQUEST_CHANGE_PASSWORD: "authentication/request-change-password",
  REQUEST_CHANGE_PASSWORD_SUCCESS: "authentication/request-change-password-success",
  REQUEST_CHANGE_PASSWORD_FAILURE: "authentication/request-change-password-failure",

  REQUEST_UPDATE_USER: "authentication/request-update-user",
  REQUEST_UPDATE_USER_SUCCESS: "authentication/request-update-user-success",
  REQUEST_UPDATE_USER_FAILURE: "authentication/request-update-user-failure",

  REQUEST_DEACTIVATE_USER: "authentication/request-deactivate-user",
  REQUEST_DEACTIVATE_USER_SUCCESS: "authentication/request-deactivate-user-success",
  REQUEST_DEACTIVATE_USER_FAILURE: "authentication/request-deactivate-user-failure",

  REQUEST_ACCOUNT_VERIFICATION: "authentication/request-account-verification",
  REQUEST_ACCOUNT_VERIFICATION_SUCCESS:
    "authentication/request-account-verification-success",
  REQUEST_ACCOUNT_VERIFICATION_FAILURE:
    "authentication/request-account-verification-failure",

  REQUEST_ACCOUNT_VERIFICATION_CHECK: "authentication/request-account-verification-check",
  REQUEST_ACCOUNT_VERIFICATION_CHECK_SUCCESS:
    "authentication/request-account-verification-check-success",
  REQUEST_ACCOUNT_VERIFICATION_CHECK_FAILURE:
    "authentication/request-account-verification-check-failure",

  REQUEST_USER_LOGOUT: "authentication/request-user-logout",
  REQUEST_USER_LOGOUT_SUCCESS: "authentication/request-user-logout-success",
  REQUEST_USER_LOGOUT_FAILURE: "authentication/request-user-logout-failure",

  RESET_ERRORS: "authentication/reset",

  INITIALIZED: "authentication/initialized",
};
