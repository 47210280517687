import {getSiteURL} from "../../meta";

function makeWebSiteSchemaObject(site, ctx) {
  const siteUrl = getSiteURL(ctx);
  return {
    "@type": "WebSite",
    "@id": `${siteUrl}#website`,
    url: siteUrl,
    name: site.getName(),
    publisher: {
      "@id": `${siteUrl}#organization`,
    },
    potentialAction: {
      "@type": "SearchAction",
      target: `${siteUrl}?q={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };
}

export default makeWebSiteSchemaObject;
