import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import media from '@ui/utils/media';

function ProductCardSkeleton({ styles }) {
  return <CustomSkeleton styles={styles.root} />;
}

const CustomSkeleton = styled(Skeleton).attrs(() => ({
  className: 'product-card__skeleton'
}))`
  height: ${({ styles }) => styles.height.lg};
  max-width: ${({ styles }) => styles.maxWidth.lg};
  min-width: ${({ styles }) => styles.minWidth.lg};
  border-radius: ${({ styles }) => styles.borderRadius};
  ${media.down('md')} {
    height: ${({ styles }) => styles.height.md};
    max-width: ${({ styles }) => styles.maxWidth.md};
    min-width: ${({ styles }) => styles.minWidth.md};
  }
  ${media.down('sm')} {
    height: ${({ styles }) => styles.height.sm};
    max-width: ${({ styles }) => styles.maxWidth.sm};
    min-width: ${({ styles }) => styles.minWidth.sm};
  }
`;

export default ProductCardSkeleton;
