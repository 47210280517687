class DeliveryConstants {
  constructor() {
    this.storePrefix = "";
  }
  init({storePrefix}) {
    this.storePrefix = storePrefix;
  }
  get DELIVERY_ADDRESS_QUERY_PARAM() {
    return "delivery_address";
  }
  get DELIVERY_TYPE_QUERY_PARAM() {
    return "delivery_type";
  }
  get DELIVERY_MODE_QUERY_PARAM() {
    return "delivery_mode";
  }
  get DELIVERY_TYPE_LOCAL_STORAGE_KEY() {
    return this.storePrefix + "/tymber-delivery-type";
  }
  get DELIVERY_ADDRESS_LOCAL_STORAGE_KEY() {
    return this.storePrefix + "tymber-delivery-address";
  }
  get DELIVERY_MODE_LOCAL_STORAGE_KEY() {
    return this.storePrefix + "/tymber-delivery-mode";
  }
  getQueryParams() {
    return [
      this.DELIVERY_ADDRESS_QUERY_PARAM,
      this.DELIVERY_TYPE_QUERY_PARAM,
      this.DELIVERY_MODE_QUERY_PARAM,
    ];
  }
}

export default new DeliveryConstants();
