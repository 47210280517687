import React from "react";
import Imgix from "react-imgix";
import Cache from "src/core/common/cache";

export default function ConfigurableImage({source, alt = "", className, ...props}) {
  const cachedSource = imageCache.get(source);

  if (!source) return null;

  if (cachedSource) {
    return <img alt={alt} src={cachedSource} className={className} {...props} />;
  }

  return (
    <Imgix
      className={className}
      src={source}
      htmlAttributes={{
        alt: alt,
      }}
      {...props}
    />
  );
}

export const imageCache = new Cache(Infinity);
