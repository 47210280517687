import BaseOption from "./BaseOption";
import styled from "styled-components";
import React from "react";

const CustomOptionContainer = styled(BaseOption)`
  margin-bottom: 7px;
`;

function CustomOption({html, component, children, ...props}) {
  if (html) {
    return <HTMLCustomOption html={html} {...props} />;
  } else if (component) {
    return <ComponentCustomOption component={component} {...props} />;
  } else {
    return <CustomOptionContainer {...props}>{children}</CustomOptionContainer>;
  }
}

function HTMLCustomOption({html, ...props}) {
  return <CustomOptionContainer dangerouslySetInnerHTML={{__html: html}} {...props} />;
}

function ComponentCustomOption({component, ...props}) {
  return <CustomOptionContainer {...props}>{component}</CustomOptionContainer>;
}

export default CustomOption;
