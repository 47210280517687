import get from "lodash/get";

class Theme {
  constructor(themeObj) {
    this._obj = themeObj;
  }

  get id() {
    return get(this._obj, "id");
  }

  get name() {
    return get(this._obj, "attributes.name");
  }
}

export default Theme;
