import React from "react";
import autoBind from "auto-bind";
import dynamic from "next/dynamic";
import IdentityVerification from "src/integrations/identityVerification/IdentityVerification";
import * as AccountsAPI from "src/core/api/accounts";
import {logModuleLoadError} from "src/core/common/utils";

const BerbixForm = dynamic(
  () => import("./BerbixForm").catch(logModuleLoadError("BerbixForm")),
  {ssr: false}
);

class Berbix extends IdentityVerification {
  constructor(identityVerificationObject) {
    super();
    autoBind(this);
    this.identityVerificationObject = identityVerificationObject;
  }

  displayVerificationStatusOnUserProfile() {
    return true;
  }

  async getClientToken() {
    const transaction = await AccountsAPI.createIdentityVerificationTransaction({
      service: "berbix",
    });
    return {
      clientToken: transaction.data.attributes.public_token_1,
    };
  }

  async getIdVerificationStatus() {
    const transaction = await AccountsAPI.getBerbixTransaction();
    return {
      status: transaction.data.attributes.status,
    };
  }

  renderForm(props) {
    return <BerbixForm berbixInstance={this} {...props} />;
  }
}

export default Berbix;
