import {ActionTypes} from "../actions";

const initialState = {
  products: {
    loading: false,
    data: [],
    error: null,
    totalCount: null,
  },
  categories: {
    loading: false,
    data: [],
    error: null,
    totalCount: null,
  },
  brands: {
    loading: false,
    data: [],
    error: null,
    totalCount: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SEARCH_PREVIEW:
      return {
        ...state,
        products: {
          loading: true,
          data: [],
          error: null,
          totalCount: null,
        },
        categories: {
          loading: true,
          data: [],
          error: null,
          totalCount: null,
        },
        brands: {
          loading: true,
          data: [],
          error: null,
          totalCount: null,
        },
      };

    case ActionTypes.SEARCH_PREVIEW_SUCCESS:
      return {
        ...state,
        products: {
          loading: false,
          data: action.payload.products.getElements(),
          error: null,
          totalCount: action.payload.products.getTotalCount(),
        },
        categories: {
          loading: false,
          data: action.payload.categories.getElements(),
          error: null,
          totalCount: action.payload.categories.getTotalCount(),
        },
        brands: {
          loading: false,
          data: action.payload.brands.getElements(),
          error: null,
          totalCount: action.payload.brands.getTotalCount(),
        },
      };

    case ActionTypes.SEARCH_PREVIEW_FAILURE:
      return {
        ...state,
        products: {...state.products, loading: false, error: action.payload},
        categories: {...state.categories, loading: false, error: action.payload},
        brands: {...state.brands, loading: false, error: action.payload},
      };

    default:
      return state;
  }
}
