import React from 'react';
import styled from 'styled-components';
import PickerContainer from '@ui/components/DeliveryBanner/PickerContainer';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from '@ui/components/Icons';

function DeliveryTypePicker({
  displayArrows,
  styles,
  selectedDeliveryType,
  deliveryTypeOptionsRoute,
  LinkComponent
}) {
  const Icon = selectedDeliveryType.icon;
  let deliveryTypeText;
  if (deliveryTypeOptionsRoute) {
    deliveryTypeText = (
      <LinkComponent to={deliveryTypeOptionsRoute}>
        <span>{selectedDeliveryType.label}</span>
        {displayArrows && (
          <ChevronDownIcon
            color={styles.color}
            padding={styles.padding}
            size={'12px'}
          />
        )}
      </LinkComponent>
    );
  } else {
    deliveryTypeText = <NoLink>{selectedDeliveryType.label}</NoLink>;
  }
  return (
    <PickerContainer styles={styles}>
      {Icon ? <Icon color={styles.color} /> : null}
      {deliveryTypeText}
    </PickerContainer>
  );
}

const NoLink = styled.span`
  text-decoration: none;
  cursor: default;
`;

DeliveryTypePicker.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({ to, children }) => <a href={'#'}>{children}</a>
};

DeliveryTypePicker.propTypes = {
  styles: PropTypes.shape({
    color: PropTypes.string,
    padding: PropTypes.string
  }),
  selectedDeliveryType: PropTypes.shape({
    icon: PropTypes.elementType,
    label: PropTypes.string
  }),
  deliveryTypeOptionsRoute: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.object
  ]),
  LinkComponent: PropTypes.elementType,
  displayArrows: PropTypes.bool
};

export default DeliveryTypePicker;
