import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "../../utils/media";
import CartButton from "./CartButton";
import {
  InnerContainer,
  Controller,
  RemoveIconComponentContainer,
  Savings,
} from "./commonComponents";

export default function QuantityPickerWithPrice({
  styles,
  hasPriceDisplay,
  pricePerUnit,
  savings,
  isInCart,
  loading,
  isProductInStock,
  isProductValid,
  isWeightedProduct,
  quantity,
  IncreaseIconComponent,
  DecreaseIconComponent,
  RemoveIconComponent,
  onIncreaseQuantityHandler,
  onDecreaseQuantityHandler,
  onRemoveItemFromCartHandler,
  onChangeQuantityHandler,
  onAddToCartHandler,
  cartIcon,
  allowQuantityChange,
  addToCartText,
}) {
  return (
    <>
      <InnerContainer
        hasPriceDisplay={hasPriceDisplay}
        isWeightedProduct={isWeightedProduct}
      >
        <Price
          styles={styles.price}
          hasPriceDisplay={hasPriceDisplay}
          isWeightedProduct={isWeightedProduct}
        >
          {pricePerUnit}
        </Price>
        {!isWeightedProduct && (
          <QuantityPickerWithPriceContainer styles={styles.quantityPicker}>
            {allowQuantityChange && (
              <>
                <Controller styles={styles.quantityPicker}>
                  <IncreaseIconComponent
                    onClick={onIncreaseQuantityHandler}
                    size={styles.quantityPicker.plusIconSize}
                  />
                </Controller>
                <label>
                  <input
                    type={"number"}
                    value={quantity}
                    onChange={onChangeQuantityHandler}
                  />
                </label>
              </>
            )}

            <Controller styles={styles.quantityPicker}>
              {!allowQuantityChange || (isInCart && quantity === 1) ? (
                <RemoveIconComponentContainer
                  styles={styles.quantityPicker.removeItemIcon}
                >
                  <RemoveIconComponent
                    onClick={onRemoveItemFromCartHandler}
                    size={styles.quantityPicker.removeItemIcon.size}
                  />
                </RemoveIconComponentContainer>
              ) : (
                <DecreaseIconComponent
                  onClick={onDecreaseQuantityHandler}
                  size={styles.quantityPicker.minusIconSize}
                />
              )}
            </Controller>
          </QuantityPickerWithPriceContainer>
        )}
        <CartButton
          styles={styles.addToCartButton}
          hasPriceDisplay={hasPriceDisplay}
          isWeightedProduct={isWeightedProduct}
          isInCart={isInCart}
          isProductInStock={isProductInStock}
          isProductValid={isProductValid}
          loading={loading}
          onClick={onAddToCartHandler}
          cartIcon={cartIcon}
          addToCartText={addToCartText}
        />
      </InnerContainer>
      {!isWeightedProduct && (
        <PricesPerUnitAnnouncement>Price per unit</PricesPerUnitAnnouncement>
      )}
      {savings && <Savings styles={styles.savings}>You are saving {savings}</Savings>}
    </>
  );
}

QuantityPickerWithPrice.propTypes = {
  styles: PropTypes.shape({
    quantityPicker: {
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      borderRadius: PropTypes.string,
      quantitySize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      minusIconSize: PropTypes.string,
      plusIconSize: PropTypes.string,
    },
    addToCartButton: {
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      padding: PropTypes.string,
    },
    price: {
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
    },
    savings: {
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    },
  }),
  hasPriceDisplay: PropTypes.bool,
  pricePerUnit: PropTypes.string,
  savings: PropTypes.string,
  isInCart: PropTypes.bool,
  loading: PropTypes.bool,
  isProductInStock: PropTypes.bool,
  isProductValid: PropTypes.bool,
  isWeightedProduct: PropTypes.bool,
  quantity: PropTypes.number,
  onChangeQuantityHandler: PropTypes.func,
  onIncreaseQuantityHandler: PropTypes.func,
  onDecreaseQuantityHandler: PropTypes.func,
  onRemoveItemFromCartHandler: PropTypes.func,
  onAddToCartHandler: PropTypes.func,
  IncreaseIconComponent: PropTypes.elementType,
  DecreaseIconComponent: PropTypes.elementType,
  RemoveIconComponent: PropTypes.elementType,
  cartIcon: PropTypes.string,
  addToCartText: PropTypes.string,
};

const Price = styled.div.attrs(() => ({
  className: "big-quantity-picker big-quantity-picker__price",
}))`
  display: flex;
  align-items: center;
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles?.fontFamily};
  color: ${({styles}) => styles.color};
  background-color: ${({styles}) => styles.backgroundColor || "#fafafa"};
  border-radius: ${({styles}) => styles.borderRadius};
  padding: 0 20px;
  height: 100%;
  box-sizing: border-box;
`;

const QuantityPickerWithPriceContainer = styled.div`
  width: 30px;
  height: 100%;
  min-height: 80px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${({styles}) => styles.quantitySize};
  user-select: none;
  box-sizing: border-box;

  input {
    margin: 0;
    border: 0;
    outline: 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-size: 17px;
    box-shadow: none;
    font-weight: 500;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > div:first-child {
    border-bottom: 1px solid #fff;
  }
  > div:last-child {
    border-top: 1px solid #fff;
  }

  > div:first-child {
    background-color: ${({styles}) => styles.backgroundColor};
  }
  > div:last-child {
    background-color: ${({styles}) => styles.backgroundColor};
  }
`;

const PricesPerUnitAnnouncement = styled.div`
  font-size: 10px;
`;
