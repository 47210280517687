import Berbix from "./berbix/Berbix";
import TymberIdentityVerification from "src/integrations/identityVerification/tymber/Tymber";
import AgeChecker from "src/integrations/identityVerification/ageChecker/AgeChecker";

export const IdentityVerification = {
  TYMBER: "tymber",
  BERBIX: "berbix",
  AGECHECKER: "agechecker",
};

export function makeIdentityVerification(type, value = null) {
  switch (type) {
    case IdentityVerification.AGECHECKER:
      return new AgeChecker(value);
    case IdentityVerification.BERBIX:
      return new Berbix(value);
    default:
      return new TymberIdentityVerification(value);
  }
}
