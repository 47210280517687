import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import styled from 'styled-components';
import media from '@ui/utils/media';
import LinkComponent from '@ui/components/Footer/LinkComponent/LinkComponent';

export default function DropdownPanel({
  groups,
  offsetX,
  offsetY,
  LinkComponent,
  styles
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Panel offsetX={offsetX} offsetY={offsetY} styles={_styles.panel}>
      <List styles={_styles.panel}>
        {groups.map((group) => (
          <Group key={group.label} styles={_styles.group.root}>
            <GroupLabel styles={_styles.group.label}>{group.label}</GroupLabel>
            <Options styles={_styles.group.options.root}>
              {group.options.map((option) => (
                <Option
                  key={option.label}
                  styles={_styles.group.options.option}
                >
                  <LinkComponent to={option.url}>
                    <span>{option.label}</span>
                  </LinkComponent>
                </Option>
              ))}
            </Options>
          </Group>
        ))}
      </List>
    </Panel>
  );
}

const Panel = styled.div`
  position: absolute;
  z-index: 5;
  top: calc(100% + ${({ offsetY }) => offsetY}px);
  left: ${({ offsetX }) => -offsetX}px;
  display: flex;
  overflow: hidden;
  width: 100vw;
  max-height: ${({ styles }) => styles.maxHeight};
  background-color: ${({ styles }) => styles.backgroundColor};
  box-shadow: 0 0 4px rgb(0 0 0 / 50%);
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  align-items: initial;
  align-content: baseline;
  flex-flow: column wrap;
  white-space: normal;
  overflow-y: auto;
  width: 100%;
  height: ${({ styles }) => styles.height};
  margin: ${({ styles }) => styles.margin};
  padding: ${({ styles }) => styles.padding};
  line-height: ${({ styles }) => styles.lineHeight};
`;

const Group = styled.li`
  width: 100%;
  max-width: ${({ styles }) => styles.maxWidth};
  margin: ${({ styles }) => styles.margin};
`;

const GroupLabel = styled.span`
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  text-transform: ${({ styles }) => styles.textTransform};
  color: ${({ styles }) => styles.color};
`;

const Options = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: ${({ styles }) => styles.padding};
  font-family: ${({ styles }) => styles.fontFamily};
  font-size: ${({ styles }) => styles.fontSize.lg};
  font-weight: ${({ styles }) => styles.fontWeight};
  text-transform: ${({ styles }) => styles.textTransform};

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }

  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

const Option = styled.li`
  > a {
    cursor: pointer;
    text-decoration: none;
    color: ${({ styles }) => styles.color};
    padding: ${({ styles }) => styles.padding};

    &:hover {
      text-shadow: 0 1px 1px ${({ styles }) => styles.selectedTextShadowColor};
      transition: 0.1s ease;
    }
  }
`;

const defaultStyles = {
  panel: {
    height: '635px',
    maxHeight: '85vh',
    margin: '0',
    padding: '30px 20px',
    backgroundColor: '#fff',
    lineHeight: '24px'
  },
  group: {
    root: {
      maxWidth: '195px',
      margin: '0 0 15px 0'
    },
    label: {
      fontFamily: 'sans-serif',
      fontWeight: 600,
      textTransform: 'none',
      color: '#000'
    },
    options: {
      root: {
        padding: '8px 50px 8px 0',
        fontFamily: 'sans-serif',
        fontSize: {
          lg: '14px',
          md: '14px',
          sm: '14px'
        },
        fontWeight: 400,
        textTransform: 'none'
      },
      option: {
        padding: '2px 0',
        color: '#000',
        selectedTextShadowColor: '#f9d645'
      }
    }
  }
};

DropdownPanel.defaultProps = {
  groups: [],
  offsetX: 0,
  offsetY: 0,
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({ to, children }) => (
    <LinkComponent to={{ pathname: to }}>{children}</LinkComponent>
  ),
  styles: defaultStyles
};

DropdownPanel.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string
        })
      )
    })
  ),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({})
};
