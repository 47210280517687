import {useMemo, useState} from "react";
import {makePaginationManager} from "./manager";

function useInfinitePagination({limit, totalPages}) {
  const [pageNumber, setPage] = useState(1);

  const manager = useMemo(() => {
    return makePaginationManager("infinite", {pageNumber, limit, totalPages});
  }, [pageNumber, limit, totalPages]);

  return [manager, setPage];
}

export default useInfinitePagination;
