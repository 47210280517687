import media from '@ui/utils/media';

function responsiveStyle(cssProperty, value) {
  if (typeof value === 'object') {
    const { lg, md, sm } = value;
    return `
      ${cssProperty}: ${lg};
    
      ${media.down('md')} {
        ${cssProperty}: ${md};
      }
      
      ${media.down('sm')} {
        ${cssProperty}: ${sm};
      }
    `;
  } else {
    return `${cssProperty}: ${value};`;
  }
}

export default responsiveStyle;
