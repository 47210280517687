import ThemeConfig from "src/themes/config";
import merge from "lodash/merge";

class ThemeRegistry {
  themes = {};
  register(themeName, themeObj) {
    this.themes[themeName] = themeObj;
    this.themes[themeName].config = this.makeThemeConfig(themeObj.config);
  }
  get(themeName) {
    return this.themes[themeName];
  }

  getThemeConfig(themeName) {
    const themeObj = this.get(themeName);
    if (!themeObj) return undefined;
    return themeObj.config;
  }

  makeThemeConfig(obj) {
    let parentConfig = obj.inherits ? this.getThemeConfig(obj.inherits) : {};
    if (!parentConfig) parentConfig = {};

    const themeObj = merge({}, parentConfig.themeObj, obj);
    return new ThemeConfig(themeObj);
  }
}

const registry = new ThemeRegistry();

export default registry;
