import merge from 'lodash/merge';
import React from 'react';

export function addSearchButton(
  optionsList,
  { searchBarFirst, styles, props, Component, toggleSearch }
) {
  const searchButtonStyles = merge(
    {},
    styles.root,
    styles.searchButton,
    props.styles || {}
  );
  const option = {
    component: () => (
      <Component
        {...props}
        styles={searchButtonStyles}
        onClick={toggleSearch}
      />
    )
  };

  if (searchBarFirst) {
    optionsList.unshift(option);
  } else {
    optionsList.push(option);
  }
}

export function addLoginButton(optionsList, { styles, props, Component }) {
  const loginButtonStyles = merge(
    {},
    styles.root,
    styles.loginButton,
    props.styles || {}
  );
  optionsList.push({
    component: () => <Component {...props} styles={loginButtonStyles} />
  });
}

export function addCartButton(optionsList, { styles, props, Component }) {
  const cartButtonStyles = merge(
    {},
    styles.root,
    styles.cartButton,
    props.styles || {}
  );
  optionsList.push({
    component: () => <Component {...props} styles={cartButtonStyles} />
  });
}
