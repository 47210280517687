import {createSelector} from "reselect";

export function cartStateSelector(state) {
  return state.cart;
}

export const cartSelector = createSelector(
  cartStateSelector,
  cartState => cartState.data
);

export const cartErrorsSelector = createSelector(
  cartStateSelector,
  cartState => cartState.error
);

export function orderStateSelector(state) {
  return state.order;
}

export const orderSelector = createSelector(
  orderStateSelector,
  orderState => orderState.data
);

export function orderReviewStateSelector(state) {
  return state.orderReview;
}

export function onBoardingStateSelector(state) {
  return state.onBoarding;
}
