import * as CheckoutActions from "./state/actions";
import store from "./state/store";
import {bindActionCreators} from "redux";
import {enhanceActions} from "src/core/common/utils";
import {cartSelector, onBoardingStateSelector} from "src/core/checkout/state/selectors";
import ECommerceConfig from "src/core/checkout/config";

const {dispatch} = store;

function enhancer(actionCreator) {
  return bindActionCreators(actionCreator, dispatch);
}

const ECommerceActions = enhanceActions(
  {
    createOrderFromCart: CheckoutActions.requestCreateOrderFromCart,
    loadOrder: CheckoutActions.requestLoadOrder,
    loadOrdersList: CheckoutActions.requestOrdersList,
    createOrderReview: CheckoutActions.requestCreateOrderReview,
    clearOrder: CheckoutActions.clearOrder,
    validateCart: CheckoutActions.requestCartValidation,
    clearCart: CheckoutActions.clearCart,
    createCart: CheckoutActions.requestCreateCart,
    addItem: CheckoutActions.requestAddItem,
    deleteItem: CheckoutActions.requestDeleteItem,
    updateItem: CheckoutActions.requestUpdateItem,
    pay: CheckoutActions.requestPayment,
    loadCart: CheckoutActions.requestLoadCart,
    setPromoCode: CheckoutActions.requestSetPromoCode,
    setPromoCodes: CheckoutActions.requestSetPromoCodes,
    setReward: CheckoutActions.requestSetReward,
    setPaymentOption: CheckoutActions.requestSetPaymentOption,
    setSchedule: CheckoutActions.requestSetSchedule,
    setOnBoardingData: CheckoutActions.setOnBoardingData,
  },
  enhancer
);

ECommerceActions.getOnBoarding = () => onBoardingStateSelector(store.getState());
ECommerceActions.getCart = () => cartSelector(store.getState());
ECommerceActions.hasCart = () => {
  const backend = ECommerceConfig.getBackend();
  return backend?.hasCart();
};

export default ECommerceActions;
