import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import chunk from "lodash/chunk";
import SwipeableViews from "react-swipeable-views";
import media, {getCurrentDim, mediaDimensions} from "@ui/utils/media";
import widthLimited from "@ui/components/Decorators/widthLimited";
import HorizontalCardList from "@ui/components/HorizontalCardList";
import {DisplayOnly} from "@ui/components/Media";
import DotSlideIndicator from "@ui/components/SlideIndicator/DotSlideSindicator";
import NavigationButton from "@ui/components/ProductsList/CarouselProductsList/NavigationButton";
import spacing from "@ui/utils/spacing";
import LazyRender from "@ui/components/Decorators/LazyRender";
import merge from "lodash/merge";
import range from "lodash/range";
import CategoryHeader from "@ui/components/CategoryHeader/CategoryHeader";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";
import useMedia from "@ui/utils/useMedia";
import mediaHelper from "styled-media-helper";
import {useMediaQuery} from "react-responsive";

function CarouselCardList({
  styles,
  skeleton,
  entries = [],
  maxEntriesPerSlide,
  lazyRender,
  renderCard,
  showCategoryCard = false,
  categoryCardProps,
  SlideIndicatorComponent,
  onChangePreviousSlide,
  onChangeNextSlide,
  onChangeIndicator,
  onLastSlide,
  recommended = false,
  showIndicatorsDesktop = false,
  loading,
  CategoryHeaderComponent,
  HeaderComponent,
  showCategoryHeader,
  partiallyDisplayNextElement = false,
  displayNavigationDimensions = ["lg"],
  hasMore,
  LoadingComponent,
}) {
  const [index, setIndex] = useState(0);
  const {isMobile} = useMedia();

  const withCategoryCard = appendCategoryCard(entries);

  const slides = {
    lg: skeleton
      ? [range(maxEntriesPerSlide.lg)]
      : chunk(
          displayCategoryCard(showCategoryCard, "lg") ? withCategoryCard : entries,
          maxEntriesPerSlide.lg
        ),
    md: skeleton
      ? [range(maxEntriesPerSlide.md)]
      : chunk(
          displayCategoryCard(showCategoryCard, "md") ? withCategoryCard : entries,
          maxEntriesPerSlide.md
        ),
    mdsm: skeleton
      ? [range(maxEntriesPerSlide.md - 1)]
      : chunk(
          displayCategoryCard(showCategoryCard, "md") ? withCategoryCard : entries,
          maxEntriesPerSlide.md - 1
        ),
    sm: skeleton ? [range(maxEntriesPerSlide.sm)] : chunk(entries, maxEntriesPerSlide.sm),
  };
  const isMdSm = useMediaQuery({minWidth: 768, maxWidth: 880}) && !recommended;

  useEffect(() => {
    const slide = slides[getCurrentDim()];
    if (!slide[index] && index > 0) {
      setIndex(0);
    }
  }, [index, entries.length]);

  const onChangeIndex = ({newIndex, limit, type}) => {
    let _index = newIndex;
    if (!isNaN(limit) && _index > limit) {
      _index = limit;
      onLastSlide && onLastSlide(limit);
    }
    setIndex(_index);
    if (type === "previous" && onChangePreviousSlide) {
      onChangePreviousSlide && onChangePreviousSlide(index);
    } else if (type === "next" && onChangeNextSlide) {
      if (window.scheduler)
        window.scheduler.postTask(() => onChangeNextSlide(index), {
          priority: "user-visible",
        });
      else setTimeout(() => onChangeNextSlide(index));
    }
  };

  const onSwipeChangeIndex = useCallback(() => {
    if (onChangeIndicator) {
      if (window.scheduler) {
        window.scheduler.postTask(() => onChangeIndicator(), {
          priority: "background",
        });
      } else {
        setTimeout(() => onChangeIndicator());
      }
    }
  }, [onChangeIndicator]);

  const swipeableViewsProps = useMemo(() => {
    let nonUpdatingIndex = index;
    return {
      index,
      enableMouseEvents: true,
      resistance: true,
      onChangeIndex(newIndex) {
        setTimeout(() => setIndex(newIndex), 300);
        nonUpdatingIndex = newIndex;
      },
      onTransitionEnd: () => {
        if (nonUpdatingIndex + 2 >= slides[getCurrentDim()]?.length) {
          onSwipeChangeIndex();
        }
      },
      style: {
        padding: isMobile && partiallyDisplayNextElement ? "0 75px 0 20px" : "0",
      },
    };
  }, [isMobile, index]);

  const _styles = merge({}, defaultStyles, styles);

  const indicatorOnDimensions = showIndicatorsDesktop ? ["lg", "md", "sm"] : ["md", "sm"];

  return (
    <OuterContainer $loading={loading}>
      {HeaderComponent &&
        renderNodeOrComponent(HeaderComponent, {
          skeleton: false,
          styles: _styles.header,
        })}
      {showCategoryHeader && (
        <DisplayOnly dims={["sm"]} fullWidth>
          <CategoryHeaderComponent
            {...categoryCardProps}
            category={categoryCardProps?.title}
          />
        </DisplayOnly>
      )}
      <Container>
        {index > 0 && (
          <NavigationButtonContainer
            dims={displayNavigationDimensions}
            type={"previous"}
            overrideDimensions={!recommended ? {lg: 1140} : null}
          >
            <NavigationButton
              styles={_styles.navigationButton}
              back={true}
              onClick={() => onChangeIndex({newIndex: index - 1, type: "previous"})}
            />
          </NavigationButtonContainer>
        )}
        <ListContainer
          maxEntriesPerSlide={maxEntriesPerSlide}
          styles={_styles}
          recommended={recommended}
        >
          {["lg", "md", "sm"].map(dim => (
            <DisplayOnly
              dims={[dim]}
              key={dim}
              overrideDimensions={!recommended ? {lg: 1340, md: 880} : null}
            >
              <SwipeableViews {...swipeableViewsProps}>
                {slides[isMdSm ? "mdsm" : dim].map((slide, index) => (
                  <LazyRender
                    key={index}
                    triggerOnce={false}
                    forceDisplay={!lazyRender}
                    minWidth={"100%"}
                    minHeight={"300px"}
                  >
                    <HorizontalCardList
                      skeleton={skeleton}
                      key={`slide-${index}`}
                      styles={_styles}
                      dim={dim}
                      maxEntries={maxEntriesPerSlide[dim] + (isMdSm ? 1 : 0)}
                      entries={slide}
                      renderCard={renderCard}
                      categoryCardProps={categoryCardProps}
                      showLoader={
                        hasMore && index === slides[dim].length - 1 && index !== 0
                      }
                      LoadingComponent={LoadingComponent}
                    />
                  </LazyRender>
                ))}
              </SwipeableViews>
              {SlideIndicatorComponent && (
                <DisplayOnly dims={indicatorOnDimensions}>
                  <SlideIndicatorComponent
                    numSlides={slides[dim].length}
                    currentSlide={index}
                    styles={_styles.indicator}
                    onSelectSlide={newIndex => onChangeIndex({newIndex})}
                  />
                </DisplayOnly>
              )}
            </DisplayOnly>
          ))}
        </ListContainer>
        {!recommended &&
          displayNavigationDimensions.map(dim => (
            <NavigationButtonContainer
              key={dim}
              dims={[dim]}
              type={"next"}
              overrideDimensions={!recommended ? {lg: 1140} : null}
            >
              <NavigationButton
                styles={_styles.navigationButton}
                onClick={() =>
                  onChangeIndex({
                    newIndex: index + 1,
                    limit: slides[dim].length - 1,
                    type: "next",
                  })
                }
              />
            </NavigationButtonContainer>
          ))}
      </Container>
    </OuterContainer>
  );
}

function displayCategoryCard(showCategoryCardProp, dim) {
  if (typeof showCategoryCardProp === "object") {
    return showCategoryCardProp[dim];
  }

  return showCategoryCardProp;
}

function appendCategoryCard(list) {
  return [{isCategoryCard: true}, ...list];
}

const defaultStyles = {
  list: {
    justifyContent: "flex-start",
    padding: "14px 8px",
  },
  element: {
    maxWidth: {
      lg: "250px",
      md: "250px",
      sm: "250px",
    },
    minWidth: {
      lg: "150px",
      md: "140px",
      sm: "130px",
    },
  },
  header: {},
  indicator: {},
  navigationButton: {
    root: {
      width: "45px",
    },
  },
};

const NavigationButtonContainer = styled(DisplayOnly)`
  ${media.down("md")} {
    position: absolute;
    ${({type}) => (type === "previous" ? "left" : "right")}: 8px;
    z-index: 4;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: ${spacing(4)};
  margin: 0 auto;

  ${media.down("sm")} {
    row-gap: ${spacing(2)};
  }
`;

const customMedia = mediaHelper({...mediaDimensions, md: 1140, lg: 1280});
const Container = widthLimited(styled.div.attrs(() => ({
  className: "carousel-card-list carousel-card-list__container",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 0 !important;
`);
const ListContainer = styled.div`
  width: 100%;

  ${media.up("lg")} {
    max-width: min(
      calc(
        ${({styles}) => `100vw - 2 * ${styles.navigationButton.root.width}`} -
          ${spacing(4)}
      ),
      calc(1440px - 2 * ${({styles}) => styles.navigationButton.root.width})
    ) !important;
  }

  ${({recommended}) => (recommended ? media.down("md") : customMedia.down("md"))} {
    max-width: calc(${() => `100vw - ${spacing(3)}`}) !important;
  }

  ${media.down("sm")} {
    max-width: calc(
      ${({styles, maxEntriesPerSlide}) =>
        `${styles.element.maxWidth.sm} * ${maxEntriesPerSlide.sm}`}
    ) !important;
  }

  overflow: hidden;

  .simple-card-list.simple-card-list__list {
    box-sizing: border-box;
    margin: 0 !important;
    padding: ${({styles}) => styles.list.padding} !important;
    overflow: hidden !important;
  }

  .react-swipeable-view-container > div {
    overflow: hidden !important;
  }

  padding: 0 !important;
  margin: 0 auto;
`;

CarouselCardList.propTypes = {
  styles: PropTypes.object,
  loading: PropTypes.bool,
  entries: PropTypes.arrayOf(PropTypes.object),
  maxEntriesPerSlide: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
  }),
  renderCard: PropTypes.func,
  showCategoryCard: PropTypes.oneOf([
    PropTypes.bool,
    PropTypes.shape({
      lg: PropTypes.bool,
      md: PropTypes.bool,
      sm: PropTypes.bool,
    }),
  ]),
  categoryCardProps: PropTypes.object,
  SlideIndicatorComponent: PropTypes.elementType,
  onChangePreviousSlide: PropTypes.func,
  onChangeNextSlide: PropTypes.func,
  onLastSlide: PropTypes.func,
  onChangeIndicator: PropTypes.func,
  skeleton: PropTypes.bool,
  lazyRender: PropTypes.bool,
  recommended: PropTypes.bool,
  showIndicatorsDesktop: PropTypes.bool,
  CategoryHeaderComponent: PropTypes.elementType,
  HeaderComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  showCategoryHeader: PropTypes.bool,
  displayNavigationDimensions: PropTypes.arrayOf(PropTypes.string),
  hasMore: PropTypes.bool,
  LoadingComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
};
CarouselCardList.defaultProps = {
  styles: defaultStyles,
  SlideIndicatorComponent: DotSlideIndicator,
  CategoryHeaderComponent: CategoryHeader,
};

export default CarouselCardList;
