import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import {ChevronRightIcon, ChevronLeftIcon} from "../Icons";

export default function SideOption({
  styles,
  selected,
  option,
  LinkComponent,
  setSelectedOption,
  back,
  close,
}) {
  const Icon = option.icon;

  const onClickOption = () => {
    if (option.onClickHandler) {
      option.onClickHandler({
        close,
      });
    } else if (back) {
      setSelectedOption();
    } else if (option.options) {
      setSelectedOption(option);
    }
  };

  const section = (
    <Container
      Icon={Icon}
      styles={styles}
      selected={selected}
      back={back}
      onClick={onClickOption}
    >
      {back && <BackIcon styles={styles} />}
      {Icon && <Icon />}
      <OptionName styles={styles}>{option.label}</OptionName>
      {!back && option.options && <ForwardIcon styles={styles} />}
    </Container>
  );

  if (!option.url) return section;

  return (
    <LinkComponent external={false} underlined={false} to={option.url} onClick={close}>
      {section}
    </LinkComponent>
  );
}

const BackIcon = styled(ChevronLeftIcon).attrs(() => ({
  className: "side-option__back-icon",
}))`
  color: ${({styles}) => styles.color};
  padding: 0 10px 0 0;
`;

const ForwardIcon = styled(ChevronRightIcon).attrs(() => ({
  className: "side-option__forward-icon",
}))`
  color: ${({styles}) => styles.color};
`;

const Container = styled.div.attrs(() => ({
  className: "side-option__container",
}))`
  padding: ${({styles}) => styles.padding};
  display: flex;
  justify-content: ${({back, Icon}) => (back || Icon ? "flex-start" : "space-between")};
  gap: ${({Icon}) => Icon && "8px"};
  align-items: center;
  border: solid;
  border-width: ${({styles}) => styles.borderWidth};
  border-color: ${({selected, styles}) =>
    selected ? styles.selectedBorderColor : styles.borderColor};
`;

const OptionName = styled.div.attrs(() => ({
  className: "side-option__optionName",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};
  text-transform: ${({styles}) => styles.textTransform};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

SideOption.propTypes = {
  setSelectedOption: PropTypes.func,
  selected: PropTypes.bool,
  back: PropTypes.bool,
  option: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      onClickHandler: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ]),
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    padding: PropTypes.string,
    borderWidth: PropTypes.string,
    borderColor: PropTypes.string,
    selectedBorderColor: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    textTransform: PropTypes.string,
    fontSize: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
  }),
};
