export function hexToRgbA(hex, opacity = 1) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    );
  }
  return hex;
}

export function addAlpha(color, opacity = 0.75) {
  const tempColor = color.trim().toLowerCase();
  if (
    tempColor.startsWith('rgba') ||
    (tempColor.startsWith('#') &&
      (tempColor.length === 9 || tempColor.length === 5))
  ) {
    return color;
  }

  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}
