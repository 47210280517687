import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import Checkbox from "@ui/components/Checkbox";

export default function OnSaleGroup({label, isSelected, onChange, disabled, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container
      disabled={disabled}
      onClick={() => onChange(!isSelected)}
      styles={_styles.root}
    >
      <Label htmlFor={label} disabled={disabled} styles={_styles.label}>
        {label}
      </Label>
      <Checkbox checked={isSelected} styles={_styles.checkbox} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  margin: ${({styles}) => styles.margin.lg};

  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
  }
`;

const Label = styled.span`
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const defaultStyles = {
  root: {
    margin: {
      lg: "10px 10px 10px 0",
      sm: "10px 10px 10px 0",
    },
  },
  label: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      sm: "12px",
    },
    fontWeight: 600,
    padding: {
      lg: "0 10px 0 0",
      sm: "0 10px 0 0",
    },
  },
};

OnSaleGroup.defaultProps = {
  onChange: () => {},
  styles: defaultStyles,
};

OnSaleGroup.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.shape({}),
};
