import localStorage from "src/core/common/localStorage";
import useSite from "src/core/sites/hooks/useSite";
import {useEffect} from "react";
import {atom, useRecoilState} from "recoil";

const HAS_ALLOWED_AGE = "tymber-user-has-allowed-age";
export const hasAllowedAgeState = atom({
  key: "hasAllowedAgeState",
  default: localStorage.getItem(HAS_ALLOWED_AGE),
});

function useAgeGate() {
  const [hasAllowedAge, setHasAllowedAgeState] = useRecoilState(hasAllowedAgeState);
  const site = useSite();

  const setHasAllowedAge = value => {
    setHasAllowedAgeState(value);
    localStorage.setItem(HAS_ALLOWED_AGE, value);
  };

  useEffect(() => {
    if (site?.getOptions?.().inStoreExperienceEnabled()) {
      setHasAllowedAge(true);
    }
  }, [site, setHasAllowedAge]);

  return [
    hasAllowedAge || site?.getOptions?.().inStoreExperienceEnabled(),
    setHasAllowedAge,
  ];
}

export default useAgeGate;
