import React from "react";
import {useInView} from "react-intersection-observer";

function LazyRender({children, forceDisplay, minHeight, triggerOnce = true, ...props}) {
  const {ref, inView} = useInView({
    triggerOnce: triggerOnce,
    root: props.ref ? props.ref.current : undefined,
  });

  if (forceDisplay || inView || !children) return <>{children}</>;

  return <div ref={ref} style={minHeight ? {minHeight} : undefined} />;
}

export default LazyRender;
