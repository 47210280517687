import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '@ui/utils/media';
import { CheckmarkIcon } from '../Icons';

const TypeOption = ({ styles, isSelected, type, onChange }) => {
  return (
    <TypeText
      styles={styles}
      selected={isSelected}
      onClick={() => onChange(type)}
    >
      {type}
      <Checkmark selected={isSelected} styles={styles} />
    </TypeText>
  );
};

const TypeText = styled.div.attrs(() => ({
  className: 'type-option__typeText'
}))`
  text-transform: capitalize;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${({ styles }) => styles.borderColor};
  box-shadow: ${({ selected }) => selected && '0 0 0 1px inset #000'};
  border-radius: 8px;
  padding: 15px 50px;
  box-sizing: border-box;
  color: ${({ styles }) => styles.color};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  font-size: ${({ styles }) => styles.fontSize.lg};

  &:hover {
    border: 1px solid ${({ styles }) => styles.borderColor};
    text-shadow: 0 1px 1px ${({ styles }) => styles.textShadowColor};
    box-shadow: 0 0 0 1px inset ${({ styles }) => styles.borderColor};
  }

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

const Checkmark = styled(CheckmarkIcon)`
  color: ${({ styles }) => styles.color};
  position: absolute;
  visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  right: -1px;
  top: -2px;
  transform: translate(50%, -50%);
`;

TypeOption.propTypes = {
  styles: PropTypes.shape({
    textShadowColor: PropTypes.string,
    borderColor: PropTypes.string,
    color: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string
    })
  })
};

export default TypeOption;
