import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/media';

export function Pages({ footerPages, ExternalLinkComponent, styles }) {
  return (
    <LinksContainer styles={styles.root}>
      {footerPages.map((page, index) => (
        <LinkElementContainer key={`link-${index}`} styles={styles.element}>
          <ExternalLinkComponent
            to={{ pathname: page.linkTo }}
            external={page.external}
          >
            {page.label}
          </ExternalLinkComponent>
        </LinkElementContainer>
      ))}
    </LinksContainer>
  );
}

const LinksContainer = styled.div.attrs(() => ({
  className: 'links_container'
}))`
  border-top: 1px solid #dedddf;
  border-bottom: 1px solid #dedddf;
  margin-top: ${({ styles }) => styles.marginTop};
  margin-bottom: ${({ styles }) => styles.marginBottom};
  list-style-type: none;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${media.down('sm')} {
    align-items: start;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
`;
const LinkElementContainer = styled.div.attrs(() => ({
  className: 'link-element__container'
}))`
  display: flex;
  ${media.down('sm')} {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    &:first-child {
      border: 0;
    }
  }
  > * {
    margin: ${({ styles }) => styles.margin};
    font-weight: ${({ styles }) => styles.fontWeight};
    color: ${({ styles }) => styles.color};
    text-decoration: none;
    transition: 0.5s all;
    font-family: ${({ styles }) => styles.fontFamily};

    ${media.down('sm')} {
      margin: 5px;
      padding: 6px 0;
      width: 100%;
    }

    &:hover {
      text-decoration: underline;
      color: ${({ styles }) => styles.hoverLinkColor};
      transition: 0.5s all;
    }
    &:active {
      color: ${({ styles }) => styles.activeLinkColor};
    }
  }
`;

Pages.propTypes = {
  styles: PropTypes.object,
  footerPages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      linkTo: PropTypes.string,
      external: PropTypes.bool
    })
  ),
  ExternalLinkComponent: PropTypes.elementType
};
