import React from "react";

function DefaultShopImage() {
  return (
    <svg
      width="60"
      height="57"
      viewBox="0 0 60 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5811_40071)">
        <path
          d="M59.067 11.5333L52.7003 1.45556C52.1336 0.555556 51.1225 0 50.0447 0H10.4003C9.32252 0 8.31141 0.555556 7.74474 1.45556L1.36696 11.5333C-1.92193 16.7333 0.989185 23.9667 7.13363 24.8C7.57807 24.8556 8.03363 24.8889 8.47808 24.8889C11.3781 24.8889 13.9559 23.6222 15.7225 21.6667C17.4892 23.6222 20.067 24.8889 22.967 24.8889C25.867 24.8889 28.4447 23.6222 30.2114 21.6667C31.9781 23.6222 34.5559 24.8889 37.4559 24.8889C40.367 24.8889 42.9336 23.6222 44.7003 21.6667C46.4781 23.6222 49.0447 24.8889 51.9447 24.8889C52.4003 24.8889 52.8447 24.8556 53.2892 24.8C59.4559 23.9778 62.3781 16.7444 59.0781 11.5333H59.067Z"
          fill="#25282B"
        />
        <path
          d="M53.7441 28.3225H53.733C53.1441 28.4003 52.5441 28.4447 51.933 28.4447C50.5552 28.4447 49.233 28.2336 47.9997 27.8558V42.667H12.4441V27.8447C11.1997 28.2336 9.86634 28.4447 8.48856 28.4447C7.87745 28.4447 7.26634 28.4003 6.67745 28.3225H6.66634C6.21079 28.2558 5.76634 28.1781 5.33301 28.0669V42.667V49.7781C5.33301 53.7003 8.5219 56.8892 12.4441 56.8892H47.9997C51.9219 56.8892 55.1108 53.7003 55.1108 49.7781V42.667V28.0669C54.6663 28.1781 54.2219 28.2669 53.7441 28.3225Z"
          fill="#25282B"
          fill-opacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5811_40071">
          <rect width="60" height="57" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DefaultShopImage;
