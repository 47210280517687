const axios = require("axios");

class CacheAdapter {
  constructor(expiryTime = 5 * 60 * 1000) {
    this.expiryTime = expiryTime;
    this.cache = new Map();
    this.pendingRequests = new Map();
  }

  getCacheKey(config) {
    return `${config.method}:${config.url}:${JSON.stringify(config.params)}:${
      config.headers["X-Store"]
    }`;
  }

  shouldCache(config) {
    const ignoreMethods = ["post", "put", "delete", "patch"];
    const urlsToCache = [
      "/api/v1/store/site/integrations/",
      "/api/v1/products/",
      "/api/v2/products/",
      "/api/v1/store/",
      "/api/v2/store/",
      "/api/v1/group/",
    ];
    const urlsToIgnore = [
      "/api/v1/store/payments/",
      "/api/v1/store/rewards/",
      "/api/v2/store/settings/",
    ];

    return (
      !ignoreMethods.includes(config.method) &&
      urlsToCache.some(url => config.url.includes(url)) &&
      !urlsToIgnore.some(url => config.url.includes(url))
    );
  }

  get(config) {
    const key = this.getCacheKey(config);
    const cached = this.cache.get(key);
    if (cached) {
      const {response, timestamp} = cached;
      const isExpired = new Date().getTime() - timestamp > this.expiryTime;
      if (!isExpired) {
        return response;
      }
      this.cache.delete(key);
    }
    return null;
  }

  set(config, response) {
    if (!this.shouldCache(config)) return;

    const key = this.getCacheKey(config);
    this.cache.set(key, {
      response,
      timestamp: new Date().getTime(),
    });
  }

  async adapter(config) {
    const key = this.getCacheKey(config);

    if (this.pendingRequests.has(key)) {
      return this.pendingRequests.get(key);
    }

    const cachedResponse = this.get(config);

    if (cachedResponse) {
      return Promise.resolve({
        ...cachedResponse,
        config,
        request: {},
        fromCache: true,
      });
    }

    const requestPromise = axios.defaults
      .adapter(config)
      .then(async response => {
        this.set(config, response);
        this.pendingRequests.delete(key);
        return response;
      })
      .catch(error => {
        this.pendingRequests.delete(key);
        return Promise.reject(error);
      });

    this.pendingRequests.set(key, requestPromise);

    return requestPromise;
  }
}

module.exports = {
  CacheAdapter,
};
