import {Star as StarOutline} from "styled-icons/boxicons-regular/Star";
import {Star} from "styled-icons/boxicons-solid/Star";
import React from "react";
import styled from "styled-components";
import ReactRating from "react-rating";

function Rating({size = "40px", ...props}) {
  return (
    <ReactRating
      {...props}
      placeholderSymbol={<YellowOutlineStarIcon size={size} />}
      emptySymbol={<GrayStarIcon size={size} />}
      fullSymbol={<YellowStarIcon size={size} />}
    />
  );
}

const YellowOutlineStarIcon = styled(StarOutline)`
  width: ${({size}) => size};
  color: #f2c94c;
`;

const YellowStarIcon = styled(Star)`
  width: ${({size}) => size};
  color: #f2c94c;
`;

const GrayStarIcon = styled(Star)`
  width: ${({size}) => size};
  color: ${({theme}) => theme.v2.color.base.grey["400"]};
`;

export default Rating;
