import React from "react";
import useThemeConfig from "src/themes/useThemeConfig";
import useSite from "src/core/sites/hooks/useSite";

export default function CategoryBar(props) {
  const site = useSite();
  const forcedTheme = site.getUiConfiguration().categoryBar?.theme;
  const config = useThemeConfig(forcedTheme);
  const ThemedCategoryBar = config.components.CategoryBar;

  return <ThemedCategoryBar {...props} />;
}
