import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '@ui/utils/media';

const ModeSelector = ({
  styles,
  changeDeliveryMode,
  isSelected,
  mode,
  description
}) => {
  return (
    <Container
      styles={styles.mode}
      selected={isSelected}
      onClick={() => changeDeliveryMode(mode)}
    >
      <Selector selected={isSelected} />
      <ModeText styles={styles.mode}>{mode}</ModeText>
      <DescriptionText styles={styles.description}>
        {description}
      </DescriptionText>
    </Container>
  );
};

const Selector = styled.div.attrs(() => ({
  className: 'mode-selector__selector'
}))`
  border: ${({ selected }) =>
    selected ? '6px solid #000' : '1px solid #D9D9D9'};
  border-radius: 10px;
  width: ${({ selected }) => (selected ? '8px' : '18px')};
  height: ${({ selected }) => (selected ? '8px' : '18px')};
  margin-right: 10px;
`;

const ModeText = styled.div.attrs(() => ({
  className: 'mode-selector__modeText'
}))`
  color: ${({ styles }) => styles.color};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  font-size: ${({ styles }) => styles.fontSize.lg};
  line-height: 19px;
  text-transform: capitalize;

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

const DescriptionText = styled.div.attrs(() => ({
  className: 'mode-selector__descriptionText'
}))`
  color: ${({ styles }) => styles.color};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  font-size: ${({ styles }) => styles.fontSize.lg};
  line-height: 19px;
  padding-left: 10px;

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

const Container = styled.div.attrs(() => ({
  className: 'mode-selector__container'
}))`
  background: ${({ selected, styles }) =>
    selected ? styles.selectedColor : 'transparent'};
  cursor: pointer;
  border: solid;
  border-color: ${({ styles }) => styles.borderColor};
  border-width: 0px 1px 1px 1px;
  padding: 10px;
  display: flex;
  align-items: center;
  line-height: 1;

  &:first-of-type {
    border-radius: 8px 8px 0 0;
    border-width: 1px 1px 1px 1px;
  }

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  &:first-of-type:last-of-type {
    border-radius: 8px;
  }
`;

ModeSelector.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  })
};

export default ModeSelector;
