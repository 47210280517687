import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import TypeOption from "./TypeOption";

const DeliveryTypeSelector = ({
  styles,
  selectedDeliveryType,
  allowPickup,
  allowDelivery,
  onChange,
}) => {
  return (
    <Container>
      <Title styles={styles.title}>Shopping for</Title>
      <TypeContainer>
        {allowDelivery && (
          <TypeOption
            styles={styles.options}
            isSelected={selectedDeliveryType === "delivery"}
            type="delivery"
            onChange={onChange}
          />
        )}
        {allowPickup && (
          <TypeOption
            styles={styles.options}
            isSelected={selectedDeliveryType === "pickup"}
            type="pickup"
            onChange={onChange}
          />
        )}
      </TypeContainer>
    </Container>
  );
};

const Container = styled.div.attrs(() => ({
  className: "delivery-type-selector__container",
}))`
  display: flex;
  flex-direction: column;
`;

const TypeContainer = styled.div.attrs(() => ({
  className: "delivery-type-selector__typeContainer",
}))`
  column-gap: 10px;
  margin: 0 0 30px 0;
  display: flex;
  justify-content: center;
`;

const Title = styled.div.attrs(() => ({
  className: "delivery-type-selector__title",
}))`
  margin: 0 0 20px 0;
  text-align: center;
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

DeliveryTypeSelector.propTypes = {
  styles: PropTypes.shape({
    title: PropTypes.shape({
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    options: PropTypes.shape({
      textShadowColor: PropTypes.string,
      borderColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
};

export default DeliveryTypeSelector;
