import useShopGroup from "../../shops/hooks/useShopGroup";
import {useEffect} from "react";
import {DeliveryTypes, makeDeliveryType} from "../../common/models/DeliveryType";
import routes from "../../common/routes";
import useDeliveryType from "./useDeliveryType";
import useRouter from "../../common/hooks/useRouter";
import useAvailablePickupShops from "../hooks/useAvailablePickupShops";

function useSelectPickup({onSelect}) {
  const [, selectDeliveryType] = useDeliveryType();
  const [shops, Shop] = useShopGroup();
  const [availablePickupShops] = useAvailablePickupShops();
  const router = useRouter();

  /* eslint-disable */
  useEffect(() => {
    if (!shops.data) {
      Shop.loadShopGroup();
    }
  }, []);
  /* eslint-enable */

  const hasMoreStoresInGroup = availablePickupShops.length > 1;

  const pickup = makeDeliveryType(DeliveryTypes.PICK_UP);
  function selectStoreOrPickup() {
    if (availablePickupShops <= 1) {
      selectDeliveryType(pickup);
      onSelect && onSelect();
    } else {
      router.push(routes.storeSelection);
    }
  }

  function selectPickup() {
    selectDeliveryType(pickup);
    onSelect && onSelect();
  }

  return {hasMoreStoresInGroup, selectStoreOrPickup, selectPickup};
}

export default useSelectPickup;
