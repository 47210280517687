import {getProductDetailUrl} from "../../meta";
import makeOffer from "./offer";

function makeProductSchemaObject(product, site, ctx) {
  const productDetailUrl = getProductDetailUrl(product, ctx);
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    "@id": `${productDetailUrl}#product`,
    name: product.getName(),
    url: productDetailUrl,
    brand: {
      "@type": "Brand",
      name: product.getBrandName(),
    },
    description: product.getDescription(),
    image: product.getMainImage(),
    sku: product.getSku(),
    offers: [makeOffer(product, site, ctx)],
  };
}

export default makeProductSchemaObject;
