import React from "react";
import {makeTypeIndicator} from "src/themes/sense/elements/flowerType";

const SativaTypeSense = ({outerBgColor}) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.59022 19.1646C14.736 19.1646 18.9075 14.9931 18.9075 9.8473C18.9075 4.70151 14.736 0.530029 9.59022 0.530029C4.44443 0.530029 0.272949 4.70151 0.272949 9.8473C0.272949 14.9931 4.44443 19.1646 9.59022 19.1646ZM14.2488 9.84727C14.2488 12.4202 12.1631 14.5059 9.59019 14.5059C7.0173 14.5059 4.93155 12.4202 4.93155 9.84727C4.93155 7.27438 7.0173 5.18864 9.59019 5.18864C12.1631 5.18864 14.2488 7.27438 14.2488 9.84727ZM16.5781 9.84727C16.5781 13.7066 13.4495 16.8352 9.59019 16.8352C5.73085 16.8352 2.60224 13.7066 2.60224 9.84727C2.60224 5.98793 5.73085 2.85932 9.59019 2.85932C13.4495 2.85932 16.5781 5.98793 16.5781 9.84727Z"
        fill={outerBgColor}
      />
    </svg>
  );
};

export const SativaTypeIndicator = makeTypeIndicator(SativaTypeSense);
