import React from "react";
import useThemeConfig from "src/themes/useThemeConfig";
import {ModalHeader} from "./modal/modalComponents";

export default function Title(props) {
  const config = useThemeConfig();
  const ThemedTitle = config.components.Title || ModalHeader;

  return <ThemedTitle {...props} />;
}
