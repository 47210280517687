import React, { useState } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import styled from 'styled-components';
import media from '@ui/utils/media';
import DropdownPanel from '@ui/components/DropdownMenuButton/DropdownPanel';

export default function DropdownMenuButton({ label, MenuComponent, styles }) {
  const _styles = merge({}, defaultStyles, styles);

  const [isHover, setIsHover] = useState(false);
  const [offsetX, setOffsetX] = useState(0);

  const onHover = (e) => {
    const rect = e.target.getBoundingClientRect();
    setOffsetX(rect.x);
    setIsHover(true);
  };

  return (
    <Container
      onMouseEnter={onHover}
      onMouseLeave={() => setIsHover(false)}
      styles={_styles.root}
    >
      <Label styles={_styles.root}>{label}</Label>
      {isHover && (
        <MenuComponent offsetX={offsetX} offsetY={2} styles={_styles} />
      )}
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  display: inline-flex !important;
  position: relative;
  height: 100%;
  border-bottom: transparent solid 2px;

  padding: 0 !important;

  &:hover {
    border-bottom: ${({ styles }) => styles.selectedUnderlineColor} solid 2px;

    > span {
      text-shadow: 0 1px 1px ${({ styles }) => styles.selectedTextShadowColor};
    }
  }
`;

const Label = styled.span`
  cursor: pointer;
  padding-top: 2px;
  font-family: ${({ styles }) => styles.fontFamily};
  font-size: ${({ styles }) => styles.fontSize.lg};
  font-weight: ${({ styles }) => styles.fontWeight};
  text-transform: ${({ styles }) => styles.textTransform};
  color: ${({ styles }) => styles.color};

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }

  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  root: {
    fontFamily: 'sans-serif',
    fontSize: {
      lg: '16px',
      md: '16px',
      sm: '16px'
    },
    fontWeight: 600,
    textTransform: 'none',
    color: '#000',
    selectedTextShadowColor: '#f9d645',
    selectedUnderlineColor: '#000'
  }
};

DropdownMenuButton.defaultProps = {
  MenuComponent: DropdownPanel,
  styles: defaultStyles
};

DropdownMenuButton.propTypes = {
  label: PropTypes.string,
  MenuComponent: PropTypes.elementType,
  styles: PropTypes.shape({})
};
