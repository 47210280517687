import {getProductDetailUrl, getSiteURL} from "../../meta";

function makeWebPageSchemaObject(product, ctx) {
  const productDetailUrl = getProductDetailUrl(product, ctx);
  return {
    "@type": "WebPage",
    "@id": `${productDetailUrl}#webpage`,
    url: productDetailUrl,
    inLanguage: "en-US",
    name: product.getName(),
    isPartOf: {
      "@id": `${getSiteURL(ctx)}#website`,
    },
    primaryImageOfPage: {
      "@id": `${productDetailUrl}#primaryimage`,
    },
  };
}

export default makeWebPageSchemaObject;
