import React from 'react';
import styled from 'styled-components';
import merge from 'lodash/merge';
import SlideIndicator from '@ui/components/SlideIndicator/SlideIndicator';

function DotSlideIndicator(props) {
  const _styles = merge({}, defaultStyles, props.styles);

  return (
    <SlideIndicator {...props} SlideIndicatorComponent={Dot} styles={_styles} />
  );
}

const defaultStyles = {
  indicator: {
    color: '#272727'
  }
};

const Dot = styled.div.attrs(() => ({
  className: 'slide-dot-indicator slide-dot-indicator__indicator'
}))`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: ${({ styles }) => styles.color};
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  cursor: pointer;
`;

DotSlideIndicator.defaultProps = {
  styles: defaultStyles,
  maxIndicators: 5
};

export default DotSlideIndicator;
