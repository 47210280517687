import React from 'react';
import PropTypes from 'prop-types';

export default function GenericLogo({ item }) {
  return <img height={item.height} width={item.width} alt="" src={item.icon} />;
}

GenericLogo.propTypes = {
  item: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.string,
    type: PropTypes.string
  })
};
