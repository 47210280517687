import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import media from '@ui/utils/media';

export default function CheckoutOrderInfoSkeleton({ dimensions }) {
  return (
    <Container>
      <CustomSkeleton dimensions={dimensions.header} />
      <CustomSkeleton dimensions={dimensions.orderState} />
      <CustomSkeleton dimensions={dimensions.customerInfo} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
`;

const CustomSkeleton = styled(Skeleton).attrs(() => ({
  className: 'checkout-order-info__skeleton',
  'data-keep-cart': 'true'
}))`
  width: 100%;
  padding-bottom: ${({ dimensions }) =>
    (dimensions.lg.height / dimensions.lg.width) * 100}%;
  ${media.down('md')} {
    padding-bottom: ${({ dimensions }) =>
      (dimensions.md.height / dimensions.md.width) * 100}%;
  }
  ${media.down('sm')} {
    padding-bottom: ${({ dimensions }) =>
      (dimensions.sm.height / dimensions.sm.width) * 100}%;
  }
`;

CheckoutOrderInfoSkeleton.defaultProps = {
  dimensions: {
    header: {
      lg: {
        height: 50,
        width: 1340
      },
      md: {
        height: 50,
        width: 1340
      },
      sm: {
        height: 50,
        width: 360
      }
    },
    orderState: {
      lg: {
        height: 178,
        width: 1180
      },
      md: {
        height: 178,
        width: 1340
      },
      sm: {
        height: 242,
        width: 320
      }
    },
    customerInfo: {
      lg: {
        height: 330,
        width: 1180
      },
      md: {
        height: 330,
        width: 1340
      },
      sm: {
        height: 560,
        width: 320
      }
    }
  }
};

CheckoutOrderInfoSkeleton.propTypes = {
  dimensions: PropTypes.shape({
    header: PropTypes.shape({
      lg: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
      })
    }),
    orderState: PropTypes.shape({}),
    customerInfo: PropTypes.shape({})
  })
};
