export function addressSearchStateSelector(state) {
  return state.addressSearch;
}

export function verifyAddressStateSelector(state) {
  return state.verifyAddress;
}

export function addressVerificationByZipCode(state, zipCode) {
  return state.verifyAddress[zipCode] || {loading: false, error: null, data: null};
}

export function deliveryTypeSelector(state) {
  return state.deliveryType.type;
}

export function deliveryModeSelector(state) {
  return state.deliveryMode;
}

export function deliveryAddressSelector(state) {
  return state.deliveryAddress;
}
