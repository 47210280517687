import routes from "../../common/routes";
import useFeatureToggles from "../../common/hooks/useFeatureToggles";
import useDeliveryType from "../../deliveries/hooks/useDeliveryType";

function useDeliverySettingsRoutes() {
  const toggles = useFeatureToggles();
  const [deliveryType] = useDeliveryType();

  function getDeliveryTypeActionRoute() {
    if (toggles.groupDeliveriesEnabled()) {
      return routes.deliveryType;
    } else if (toggles.groupPickupEnabled()) {
      return routes.storeSelection;
    } else {
      return null;
    }
  }
  function getAddressSelectionRoute() {
    if (!deliveryType) return null;

    if (deliveryType.pickupAtShop()) {
      return routes.storeSelection;
    } else {
      return routes.deliveryCheck;
    }
  }

  return {
    deliveryTypeRoute: getDeliveryTypeActionRoute(),
    addressSelectionRoute: getAddressSelectionRoute(),
  };
}

export default useDeliverySettingsRoutes;
