import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '@ui/utils/media';
import ConfigurableImage from '../../ConfigurableImage';

export default function LogoImage({ styles, src }) {
  return <Logo styles={styles} source={src} />;
}

const Logo = styled(ConfigurableImage)`
  height: ${({ styles }) => styles.height.lg};

  ${media.down('md')} {
    height: ${({ styles }) => styles.height.md};
  }
  ${media.down('sm')} {
    height: ${({ styles }) => styles.height.sm};
  }
`;

LogoImage.propTypes = {
  src: PropTypes.string,
  styles: PropTypes.shape({})
};
