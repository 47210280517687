import get from "lodash/get";
import set from "lodash/set";

export function makeSizeTriad(sm, md, lg) {
  return {
    sm,
    md: md || sm,
    lg: lg || md || sm,
  };
}

const fontSizeScale = {
  "font-size-25": makeSizeTriad("10px", "10px", "10px"),
  "font-size-50": makeSizeTriad("10px", "10px", "12px"),
  "font-size-75": makeSizeTriad("10px", "12px", "14px"),
  "font-size-100": makeSizeTriad("12px", "14px", "16px"),
  "font-size-200": makeSizeTriad("14px", "16px", "18px"),
  "font-size-300": makeSizeTriad("16px", "18px", "20px"),
  "font-size-400": makeSizeTriad("18px", "20px", "24px"),
  "font-size-500": makeSizeTriad("20px", "24px", "28px"),
  "font-size-600": makeSizeTriad("24px", "28px", "32px"),
  "font-size-700": makeSizeTriad("28px", "32px", "38px"),
  "font-size-800": makeSizeTriad("32px", "36px", "40px"),
  "font-size-900": makeSizeTriad("36px", "38px", "42px"),
  "font-size-1000": makeSizeTriad("38px", "42px", "44px"),
  "font-size-1100": makeSizeTriad("42px", "44px", "46px"),
  "font-size-1200": makeSizeTriad("44px", "46px", "48px"),
  "font-size-1300": makeSizeTriad("46px", "48px", "50px"),
};
const staticFontSizeScale = {
  "static-font-size-10": makeSizeTriad("8px"),
  "static-font-size-25": makeSizeTriad("10px"),
  "static-font-size-50": makeSizeTriad("12px"),
  "static-font-size-75": makeSizeTriad("14px"),
  "static-font-size-100": makeSizeTriad("16px"),
  "static-font-size-200": makeSizeTriad("18px"),
  "static-font-size-300": makeSizeTriad("20px"),
  "static-font-size-400": makeSizeTriad("24px"),
  "static-font-size-500": makeSizeTriad("28px"),
  "static-font-size-600": makeSizeTriad("32px"),
  "static-font-size-700": makeSizeTriad("38px"),
  "static-font-size-800": makeSizeTriad("40px"),
  "static-font-size-900": makeSizeTriad("42px"),
  "static-font-size-1000": makeSizeTriad("44px"),
  "static-font-size-1100": makeSizeTriad("46px"),
  "static-font-size-1200": makeSizeTriad("48px"),
  "static-font-size-1300": makeSizeTriad("50px"),
};

const sizeScale = {
  "size-1": "4px",
  "size-2": "8px",
  "size-3": "12px",
  "size-4": "16px",
  "size-5": "20px",
  "size-6": "24px",
  "size-7": "28px",
  "size-8": "32px",
  "size-9": "36px",
  "size-10": "40px",
  "size-11": "44px",
  "size-12": "48px",
  "size-13": "52px",
  "size-14": "56px",
  "size-15": "60px",
  "size-16": "64px",
};

export function populateSize(obj, basePath) {
  const propValue = basePath ? get(obj, basePath, {}) : "";
  set(obj, basePath, getSize(propValue));
}

export function getSize(size) {
  if (typeof size !== "string") return size;

  const defaultSize = makeSizeTriad(size, size, size);

  const [, sizeType] = isSize(size);

  switch (sizeType) {
    case "font-size":
      return fontSizeScale[size] || defaultSize;
    case "size":
      return sizeScale[size] || size;
    case "static-font-size":
      return staticFontSizeScale[size] || defaultSize;
    default:
      return size;
  }
}

function isSize(size) {
  if (typeof size !== "string") return [false, null];

  const fontSizeRegExp = /^font-size-[0-9]+$/g;
  const sizeRegExp = /^size-[0-9]+$/g;
  const staticFontSizeRegExp = /^static-font-size-[0-9]+$/g;

  if (size.match(fontSizeRegExp)) {
    return [true, "font-size"];
  } else if (size.match(sizeRegExp)) {
    return [true, "size"];
  } else if (size.match(staticFontSizeRegExp)) {
    return [true, "static-font-size"];
  } else {
    return [false, null];
  }
}
