import useSite from "src/core/sites/hooks/useSite";

function useProductSortingOptions() {
  const site = useSite();

  return getOrderOptions({env: site?.env});
}

const getOrderOptions = () => [
  {label: "No Sorting", value: ""},
  {label: "New on the Menu", value: "-inserted_at"},
  {label: "Price: Low to High", value: "price"},
  {label: "Price: High to Low", value: "-price"},
  {label: "Alphabetical: A - Z", value: "name"},
  {label: "Alphabetical: Z - A", value: "-name"},
  {label: "Brand: A - Z", value: "brand"},
  {label: "Brand: Z - A", value: "-brand"},
  {label: "Weight: Low to High", value: "size"},
  {label: "Weight: High to Low", value: "-size"},
  {label: "CBD: Low to High", value: "cbd"},
  {label: "CBD: High to Low", value: "-cbd"},
  {label: "THC: Low to High", value: "thc"},
  {label: "THC: High to Low", value: "-thc"},
];

export default useProductSortingOptions;
