import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import spacing from '@ui/utils/spacing';
import merge from 'lodash/merge';
import SearchLogo from './SearchLogo';
import media from '@ui/utils/media';
import renderNodeOrComponent from '@ui/utils/RenderNodeOrComponent';

export default function NoResultsPlaceholder({
  styles,
  LogoIconComponent,
  TitleComponent,
  TextComponent
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container styles={_styles.root}>
      <LogoContainer styles={_styles.logo}>
        {LogoIconComponent ? (
          <LogoIconComponent color={_styles.logo.color} />
        ) : (
          <SearchLogo />
        )}
      </LogoContainer>
      <TextContainer styles={_styles.titleText}>
        {TitleComponent ? (
          renderNodeOrComponent(TitleComponent)
        ) : (
          <span> Oopsie! No matching results.</span>
        )}
      </TextContainer>
      <BottomTextContainer styles={_styles.detailText}>
        {TextComponent ? (
          renderNodeOrComponent(TextComponent)
        ) : (
          <>
            <span>
              {' '}
              It seems like we did not find what you are looking for.
            </span>
            <span>Try adjusting your filters or search query.</span>
          </>
        )}
      </BottomTextContainer>
    </Container>
  );
}

const defaultStyles = {
  root: {
    padding: {
      lg: `${spacing(8)} 16px`,
      md: `${spacing(1)} 0`,
      sm: `${spacing(1)} 0`
    }
  },
  logo: {
    backgroundColor: '#1E4526',
    borderRadius: '4px',
    color: '#000000'
  },
  titleText: {
    fontFamily: 'Poppins',
    color: '#0C2015',
    fontWeight: 600,
    fontSize: {
      lg: '20px',
      md: '20px',
      sm: '20px'
    }
  },
  detailText: {
    fontFamily: 'Lato',
    fontWeight: 400,
    color: '#9EA0A5',
    fontSize: {
      lg: '16px',
      md: '16px',
      sm: '12px'
    }
  }
};
const Container = styled.div.attrs(() => ({
  className: 'no-results-placeholder__container'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ styles }) => styles.backgroundColor};
  padding: ${({ styles }) => styles.padding.lg};
  row-gap: 16px;
`;
const LogoContainer = styled.div.attrs(() => ({
  className: 'no-results-placeholder__logo'
}))`
  display: flex;
  background-color: ${({ styles }) => styles.backgroundColor};
  border-radius: ${({ styles }) => styles.borderRadius};
`;
const TextContainer = styled.div.attrs(() => ({
  className: 'no-results-placeholder__title-text'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ styles }) => styles.backgroundColor};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  height: 28px;
  color: ${({ styles }) => styles.color};
  font-size: ${({ styles }) => styles.fontSize.lg};

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

const BottomTextContainer = styled.div.attrs(() => ({
  className: 'no-results-placeholder__detail-text'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ styles }) => styles.backgroundColor};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  height: 25.6px;
  color: ${({ styles }) => styles.color};
  font-size: ${({ styles }) => styles.fontSize.lg};

  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

NoResultsPlaceholder.defaultProps = {
  styles: defaultStyles
};

NoResultsPlaceholder.propTypes = {
  LogoIconComponent: PropTypes.elementType,
  TitleComponent: PropTypes.elementType,
  TextComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      })
    }),
    logo: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    titleText: PropTypes.shape({
      fontFamily: PropTypes.string,
      color: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      })
    }),
    detailText: PropTypes.shape({
      fontFamily: PropTypes.string,
      color: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      })
    })
  })
};
