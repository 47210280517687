import makeListItem from "./listItem";

function makeBreadCrumbList(items, ctx) {
  return {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: items.map((item, index) => makeListItem(item, index + 1, ctx)),
  };
}

export default makeBreadCrumbList;
