import ActionTypes from "./actionTypes";
import {combineReducers} from "redux";

const shopInitialState = {
  loading: false,
  error: null,
  data: null,
};
function shop(state = shopInitialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_SHOP_DETAILS:
      return {...state, loading: true, error: null};
    case ActionTypes.REQUEST_SHOP_DETAILS_FAILURE:
      return {...state, loading: false, error: action.payload.error};
    case ActionTypes.SET_SHOP:
    case ActionTypes.REQUEST_SHOP_DETAILS_SUCCESSFUL:
      return {...state, loading: false, error: null, data: action.payload.shop};
    default:
      return state;
  }
}
const groupInitialState = {
  loading: false,
  error: null,
  data: null,
};
function group(state = groupInitialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_SHOP_GROUP:
      return {...state, loading: true};
    case ActionTypes.REQUEST_SHOP_GROUP_FAILURE:
      return {...state, loading: false, error: action.payload.error};
    case ActionTypes.SET_SHOP:
    case ActionTypes.REQUEST_SHOP_GROUP_SUCCESSFUL:
      return {...state, loading: false, error: null, data: action.payload.shops};
    default:
      return state;
  }
}

export default combineReducers({
  shop,
  group,
});
