import {combineReducers} from "redux";
import ActionTypes from "./actionTypes";
import {DeliveryModes} from "../../common/models/deliveryMode";
import get from "lodash/get";

const initialAddressAutoCompleteState = {
  loading: false,
  error: null,
  data: undefined,
};
function addressSearch(state = initialAddressAutoCompleteState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_ADDRESS_AUTO_COMPLETE:
      return {...state, loading: true, error: null};
    case ActionTypes.REQUEST_ADDRESS_AUTO_COMPLETE_FAILURE:
      return {...state, loading: false, data: null, error: action.payload.error};
    case ActionTypes.REQUEST_ADDRESS_AUTO_COMPLETE_SUCCESSFUL:
      return {...state, loading: false, data: action.payload};
    default:
      return state;
  }
}

const initialVerifyAddressState = {};
function verifyAddress(state = initialVerifyAddressState, action) {
  let address = null;
  switch (action.type) {
    case ActionTypes.REQUEST_VERIFY_ADDRESS:
      address = action.payload.address;
      if (state[address.zipCode]) {
        return {
          ...state,
          [address.zipCode]: {
            ...state[address.zipCode],
            loading: true,
            error: null,
          },
        };
      } else {
        return {...state, [address.zipCode]: {data: null, loading: true, error: null}};
      }
    case ActionTypes.REQUEST_VERIFY_ADDRESS_FAILURE:
      address = action.payload.address;
      return {
        ...state,
        [address.zipCode]: {
          ...state[address.zipCode],
          loading: false,
          error: action.payload.error,
        },
      };
    case ActionTypes.REQUEST_VERIFY_ADDRESS_SUCCESSFUL:
      address = action.payload.address;
      return {
        ...state,
        [address.zipCode]: {
          data: action.payload.shops,
          loading: false,
          error: null,
        },
      };
    default:
      return state;
  }
}

const initialDeliveryType = {
  type: null,
  isDefault: true,
};
function deliveryType(state = initialDeliveryType, action) {
  switch (action.type) {
    case ActionTypes.SELECT_DELIVERY_TYPE:
      const isDefault = get(action.payload.options, "isDefault", false);
      if (isDefault && state.isDefault) {
        return {...state, type: action.payload.deliveryType};
      } else if (!isDefault) {
        return {isDefault: false, type: action.payload.deliveryType};
      } else {
        return state;
      }
    default:
      return state;
  }
}

const initialDeliveryMode = null;
function deliveryMode(state = initialDeliveryMode, action) {
  switch (action.type) {
    case ActionTypes.SET_DELIVERY_MODE:
      return action.payload.mode || DeliveryModes.SCHEDULED;
    default:
      return state;
  }
}

const initialDeliveryAddress = null;
function deliveryAddress(state = initialDeliveryAddress, action) {
  switch (action.type) {
    case ActionTypes.SELECT_DELIVERY_ADDRESS_SUCCESSFUL:
      return action.payload.deliveryAddress;
    default:
      return state;
  }
}

export default combineReducers({
  addressSearch,
  verifyAddress,
  deliveryType,
  deliveryAddress,
  deliveryMode,
});
