import React from 'react';
import PropTypes from 'prop-types';

const RegexInput = React.forwardRef(
  (
    {
      type,
      regex,
      placeholder,
      hasErrors,
      clickable,
      disableAutoComplete,
      disabled,
      onChange,
      onFocus,
      ...props
    },
    ref
  ) => {
    const _onChange = regex ? validate(regex, onChange) : onChange;

    const _onFocus = (event) => {
      disableAutoComplete && event.target.setAttribute('autocomplete', 'off');
      if (onFocus) {
        onFocus(event);
      }
    };

    return (
      <input
        {...props}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        ref={ref}
        onChange={_onChange}
        onFocus={_onFocus}
      />
    );
  }
);

const validate = (regexStr, onChange) => (evt) => {
  const regex = new RegExp(regexStr, 'g');
  if (onChange && (regex.test(evt.target.value) || evt.target.value === '')) {
    onChange(evt);
  }
};

const defaultStyles = {};

RegexInput.defaultProps = {
  type: 'text',
  styles: defaultStyles
};

RegexInput.propTypes = {
  type: PropTypes.string,
  regex: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  placeholder: PropTypes.string,
  hasErrors: PropTypes.bool,
  clickable: PropTypes.bool,
  disableAutoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  styles: PropTypes.shape({})
};

export default RegexInput;
