import makeOrganizationSchemaObject from "../objects/organization";
import makeWebSiteSchemaObject from "../objects/website";
import makeImageSchemaSchemaObject from "../objects/image";
import makeWebPageSchemaObject from "../objects/webpage";

function makeSiteDetailSchema(product, site, ctx) {
  return {
    "@context": "https://schema.org/",
    "@graph": [
      makeOrganizationSchemaObject(site, ctx),
      makeWebSiteSchemaObject(site, ctx),
      makeImageSchemaSchemaObject(product, ctx),
      makeWebPageSchemaObject(product, ctx),
    ],
  };
}

export default makeSiteDetailSchema;
