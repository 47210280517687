function filterEnv(env) {
  return Object.keys(env).reduce(function (filtered, key) {
    if (key.startsWith("NEXT_PUBLIC") || key.startsWith("REACT_APP")) {
      filtered[key] = env[key];
    }
    return filtered;
  }, {});
}

module.exports = {
  filterEnv,
};
