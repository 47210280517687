import React from "react";
import {makeTypeIndicator} from "src/themes/sense/elements/flowerType";

const IndicaTypeSense = ({outerBgColor}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4297 9.72161C13.8639 8.89367 12.9457 8.1169 12.0131 8.18244C11.8668 8.19272 11.719 8.19795 11.57 8.19795C8.12923 8.19795 5.33993 5.40866 5.33993 1.96789C5.33993 1.81968 5.34511 1.67267 5.35529 1.52705C5.42048 0.594444 4.64336 -0.323467 3.81559 0.111031C1.54726 1.30167 0 3.67988 0 6.41947C0 10.3518 3.18776 13.5395 7.12007 13.5395C9.86061 13.5395 12.2395 11.9912 13.4297 9.72161Z"
        fill={outerBgColor}
      />
    </svg>
  );
};

export const IndicaTypeIndicator = makeTypeIndicator(IndicaTypeSense);
