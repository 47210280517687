import React from "react";
import ConfigurableImage from "../../../../../common/components/elements/ConfigurableImage";
import styled from "styled-components";

const TymberLogo = styled(ConfigurableImage).attrs(() => ({className: "logo"}))`
  max-height: 50px;

  @media (max-width: 768px) {
    max-width: 25vw;
    height: auto;
  }
`;

function Logo({src, height, maxHeight, ...props}) {
  return (
    <TymberLogo
      source={src}
      height={height || 60}
      maxHeight={maxHeight || 25}
      {...props}
    />
  );
}

export default Logo;
