import styled from 'styled-components';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import media from '@ui/utils/media';
import React from 'react';

export default function PromotionalBannerSkeleton({ dimensions }) {
  return <CustomSkeleton dimensions={dimensions} />;
}

const CustomSkeleton = styled(Skeleton)`
  width: 100%;
  padding-bottom: ${({ dimensions }) =>
    (dimensions.lg.height / dimensions.lg.width) * 100}%;
  ${media.down('md')} {
    padding-bottom: ${({ dimensions }) =>
      (dimensions.md.height / dimensions.md.width) * 100}%;
  }
  ${media.down('sm')} {
    padding-bottom: ${({ dimensions }) =>
      (dimensions.sm.height / dimensions.sm.width) * 100}%;
  }
  border-radius: 0;
`;

PromotionalBannerSkeleton.defaultProps = {
  dimensions: {
    lg: {
      height: 180,
      width: 1340
    },
    md: {
      height: 162,
      width: 360
    },
    sm: {
      height: 162,
      width: 360
    }
  }
};

PromotionalBannerSkeleton.propTypes = {
  dimensions: PropTypes.shape({
    lg: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number
    }),
    md: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number
    }),
    sm: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number
    })
  })
};
