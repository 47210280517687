import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import spacing from '@ui/utils/spacing';

export default function PotencyTags({
  potencyTags,
  sizes,
  sizesPosition,
  PotencyTagComponent,
  styles
}) {
  let _potencyTags = potencyTags || [];
  if (sizesPosition === 'potency-tags' && sizes) {
    _potencyTags = sizes
      .concat(potencyTags)
      .filter((s) => typeof s === 'string' && s !== '');
  }

  if (_potencyTags?.length === 0) return null;

  return (
    <PotencyTagsContainer styles={styles}>
      {_potencyTags.slice(0, 2).map((text, index) => (
        <PotencyTagComponent key={index} text={text} styles={styles} />
      ))}
      {_potencyTags.length > 2 && (
        <PotencyTagComponent text={'...'} styles={styles} />
      )}
    </PotencyTagsContainer>
  );
}

const PotencyTagsContainer = styled.div.attrs(() => ({
  className: 'product-card__potency-tags-container',
  'data-keep-cart': 'true'
}))`
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  column-gap: ${({ styles }) => styles.columnGap};
  row-gap: ${({ styles }) => styles.rowGap};
  > * {
    margin-right: ${spacing(0.5)};
  }

  @media (max-width: 359px) {
    flex-flow: row wrap;
  }
`;

PotencyTags.propTypes = {
  styles: PropTypes.shape({
    columnGap: PropTypes.string,
    rowGap: PropTypes.string,
    padding: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string
    })
  }),
  potencyTags: PropTypes.arrayOf(PropTypes.string),
  sizes: PropTypes.arrayOf(PropTypes.string),
  hasProductSizes: PropTypes.bool,
  sizesPosition: PropTypes.oneOf(['below-potency-tags', 'above-potency-tags']),
  PotencyTagComponent: PropTypes.elementType
};
