import {getProductDetailUrl} from "../../meta";

function makeImageSchemaSchemaObject(product, ctx) {
  return {
    "@type": "ImageObject",
    "@id": `${getProductDetailUrl(product, ctx)}#primaryimage`,
    url: product.getMainImage(),
  };
}

export default makeImageSchemaSchemaObject;
