export default {
  REQUEST_SHOP_DETAILS: "shops/request-shop-details",
  REQUEST_SHOP_DETAILS_FAILURE: "shops/request-shop-details-failure",
  REQUEST_SHOP_DETAILS_SUCCESSFUL: "shops/request-shop-details-successful",

  REQUEST_SHOP_GROUP: "shops/request-shop-group",
  REQUEST_SHOP_GROUP_FAILURE: "shops/request-shop-group-failure",
  REQUEST_SHOP_GROUP_SUCCESSFUL: "shops/request-shop-group-successful",

  SET_SHOP: "shops/set-shop",
};
