import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import styled from 'styled-components';
import media from '@ui/utils/media';
import LinkComponent from '@ui/components/Footer/LinkComponent/LinkComponent';

export default function DropdownList({
  options,
  offsetY,
  LinkComponent,
  styles
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container offsetY={offsetY} styles={_styles.list}>
      <Options styles={_styles.list}>
        {options.map((option) => (
          <Option key={option.label} styles={_styles.option}>
            <LinkComponent to={option.url} external>
              <span>{option.label}</span>
            </LinkComponent>
          </Option>
        ))}
      </Options>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  z-index: 5;
  left: -${({ styles }) => styles.left};
  top: calc(100% + ${({ offsetY }) => offsetY}px);
  display: flex;
  min-width: 205px;
  background-color: ${({ styles }) => styles.backgroundColor};
  box-shadow: 0 0 4px rgb(0 0 0 / 50%);
`;

const Options = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin: ${({ styles }) => styles.margin};
  padding: ${({ styles }) => styles.padding};
  line-height: ${({ styles }) => styles.lineHeight};
  white-space: nowrap;
`;

const Option = styled.li`
  cursor: pointer;
  padding: ${({ styles }) => styles.padding};
  width: 100%;

  &:hover {
    text-shadow: 0 1px 1px ${({ styles }) => styles.selectedTextShadowColor};
    transition: 0.1s ease;
  }

  > a {
    display: block;
    padding: 0;
    text-decoration: none;
    font-family: ${({ styles }) => styles.fontFamily};
    font-size: ${({ styles }) => styles.fontSize.lg};
    font-weight: ${({ styles }) => styles.fontWeight};
    text-transform: ${({ styles }) => styles.textTransform};
    color: ${({ styles }) => styles.color};

    ${media.down('md')} {
      font-size: ${({ styles }) => styles.fontSize.md};
    }

    ${media.down('sm')} {
      font-size: ${({ styles }) => styles.fontSize.sm};
    }
  }
`;

const defaultStyles = {
  list: {
    margin: '0',
    left: '20px',
    padding: '0 50px 0 0',
    backgroundColor: '#fff',
    lineHeight: '24px'
  },
  option: {
    padding: '8px 20px',
    fontFamily: 'sans-serif',
    fontSize: {
      lg: '16px',
      md: '16px',
      sm: '16px'
    },
    fontWeight: 400,
    textTransform: 'none',
    color: '#000',
    selectedTextShadowColor: '#f9d645'
  }
};

DropdownList.defaultProps = {
  offsetY: 0,
  options: [],
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({ to, children }) => (
    <LinkComponent to={{ pathname: to }}>{children}</LinkComponent>
  ),
  styles: defaultStyles
};

DropdownList.propTypes = {
  offsetY: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string
    })
  ),
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({})
};
