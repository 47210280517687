import styled from "styled-components";

const BaseOption = styled.li`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: ${({theme}) => theme.v2.typography.sizing.s.lg};
  padding: ${({theme}) => theme.v2.spacing(2)};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  margin: 0;

  &:last-child {
    padding-right: 0;
  }
`;

export default BaseOption;
