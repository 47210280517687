import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

function TranslucentOnHover({children, opacityOnHover, disableEffect, onClick}) {
  return (
    <Container
      opacityOnHover={opacityOnHover}
      onClick={onClick}
      disableEffect={disableEffect}
    >
      {children}
    </Container>
  );
}

const Container = styled.div`
  cursor: ${({onClick, disableEffect}) =>
    onClick && !disableEffect ? "pointer" : "default"};
  opacity: 1;
  &:hover {
    opacity: ${({opacityOnHover, disableEffect, onClick}) =>
      disableEffect || !onClick ? "1.0" : opacityOnHover};
  }
  &:active {
    opacity: 1;
    filter: ${({disableEffect, onClick}) =>
      disableEffect || !onClick ? "brightness(100%)" : "brightness(90%)"};
  }

  svg {
    pointer-events: none;
  }
`;

TranslucentOnHover.propTypes = {
  opacityOnHover: PropTypes.number,
  onClick: PropTypes.func,
  disableEffect: PropTypes.bool,
};
TranslucentOnHover.defaultProps = {
  opacityOnHover: 0.75,
  disableEffect: false,
};

export default TranslucentOnHover;
