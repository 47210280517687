import {useEffect, useState} from "react";
import {getCurrentDim} from "@ui/utils/media";

export default function useMedia() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(getCurrentDim() === "sm");
    const resizeListener = () => {
      setIsMobile(getCurrentDim() === "sm");
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return {isMobile};
}
