import React from "react";
import {makeTypeIndicator} from "src/themes/sense/elements/flowerType";

const HybridTypeSense = ({outerBgColor}) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.31727"
        cy="9.31727"
        r="8.15261"
        stroke="#757575"
        stroke-width="2.32932"
      />
      <path
        d="M15.9056 15.9056C16.7708 15.0404 17.4571 14.0133 17.9253 12.8828C18.3935 11.7524 18.6345 10.5408 18.6345 9.31727C18.6345 8.09371 18.3935 6.88213 17.9253 5.7517C17.4571 4.62128 16.7708 3.59415 15.9056 2.72897C15.0404 1.86378 14.0133 1.17747 12.8828 0.709235C11.7524 0.240998 10.5408 1.21389e-09 9.31727 0C8.09371 -1.21389e-09 6.88213 0.240998 5.7517 0.709235C4.62128 1.17747 3.59415 1.86378 2.72896 2.72897L9.31727 9.31727L15.9056 15.9056Z"
        fill={outerBgColor}
      />
    </svg>
  );
};

export const HybridTypeIndicator = makeTypeIndicator(HybridTypeSense);
