import useCartState from "src/core/checkout/hooks/useCartState";
import get from "lodash/get";
import {useMemo} from "react";

function useErrors(type, criteria) {
  const [cartState] = useCartState();
  let errors = get(cartState.error, "data.errors", []);
  const code = typeof criteria === "string" ? criteria : null;
  const filter = typeof criteria === "function" ? criteria : null;
  return useMemo(() => {
    let results = [];
    if (Array.isArray(errors) && type) {
      results = errors.filter(error => error.fields && error.fields.indexOf(type) > -1);
    } else if (Array.isArray(errors) && code) {
      results = errors.filter(error => error.code && error.code === code);
    } else if (Array.isArray(errors) && filter) {
      results = errors.filter(filter);
    } else {
      results = errors;
    }
    return [results];
  }, [errors, type, code, filter]);
}

export default useErrors;
