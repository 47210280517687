import Backend from "./models/backend";
import eCommerceMiddleware from "./eCommerceMiddleware";

class ECommerceConfig {
  config = {
    backend: new Backend(),
  };

  setConfig(config) {
    this.config = {...this.config, ...config};
  }

  setBackend(backend) {
    this.config.backend = backend;
    return this;
  }
  getBackend() {
    return this.config.backend;
  }

  addMiddleware(middleware) {
    eCommerceMiddleware.run(middleware);
  }
}

export default new ECommerceConfig();
