import autoBind from "auto-bind";
import {CheckoutDocumentConstraint} from "./checkoutConstraints";

class Options {
  constructor(optionsObject) {
    this.optionsObject = optionsObject;
    autoBind(this);
  }

  useRegionGeoZonesRestrictions() {
    return Boolean(this.optionsObject.use_region_geo_zones_restrictions);
  }

  guestCheckoutEnabled() {
    return Boolean(this.optionsObject.allow_express_checkout);
  }

  deliveriesEnabled() {
    return Boolean(this.optionsObject.allow_deliveries);
  }

  pickupEnabled() {
    return Boolean(this.optionsObject.allow_pickup);
  }

  groupPickupEnabled() {
    return Boolean(this.optionsObject.group_allow_pickup);
  }

  groupDeliveriesEnabled() {
    return Boolean(this.optionsObject.group_allow_deliveries);
  }

  groupExpressDeliveriesEnabled() {
    return Boolean(this.optionsObject.group_delivery_express_orders_allowed);
  }

  groupScheduledDeliveriesEnabled() {
    return Boolean(this.optionsObject.group_delivery_scheduled_orders_allowed);
  }

  expressDeliveriesEnabled() {
    return Boolean(this.optionsObject.delivery_express_orders_allowed);
  }

  authenticationWithoutAgeConfirmation() {
    return Boolean(this.optionsObject.allow_authentication_without_age_confirmation);
  }

  allowRecreationalCustomers() {
    return Boolean(this.optionsObject.allow_recreational_customers);
  }

  allowMedicalCustomers() {
    return Boolean(this.optionsObject.allow_medical_customers);
  }

  allowNameAndDobUpdate() {
    return Boolean(this.optionsObject.allow_name_and_dob_update);
  }

  allowDeliveriesInGroup() {
    return (
      Boolean(this.optionsObject.allow_change_user_address) || this.deliveriesEnabled()
    );
  }

  requireMarketingSourceOnSignUp() {
    return Boolean(this.optionsObject.require_marketing_source);
  }

  allowMarketingSourceOnSignUp() {
    return Boolean(this.optionsObject.allow_marketing_source);
  }

  poweredByText() {
    return this.optionsObject.powered_by_text;
  }

  hidePoweredBy() {
    return this.optionsObject.hide_powered_by;
  }

  allowDocumentsOnPersonalInformation() {
    return Boolean(this.optionsObject.allow_documents);
  }

  getRegistrationAnnouncement() {
    return this.optionsObject.registration_announcement;
  }

  getRegistrationAnnouncementAcknowledgement() {
    return this.optionsObject.authentication_without_age_confirmation_text;
  }

  getMinimumAge() {
    const allowRecreationalCustomers = this.allowRecreationalCustomers();
    const minimumAgeRecreational = this.minimumAgeRecreational() || 21;
    const allowMedicalCustomers = this.allowMedicalCustomers();
    const minimumAgeMedical = this.minimumAge() || 18;
    if (allowRecreationalCustomers && allowMedicalCustomers) {
      return Math.max(minimumAgeMedical, minimumAgeRecreational);
    }

    if (allowMedicalCustomers && !allowRecreationalCustomers) {
      return minimumAgeMedical;
    }

    return minimumAgeRecreational;
  }

  getScheduleAnnouncement() {
    return this.optionsObject.schedule_for_later_announcement;
  }

  getCheckoutDisclaimer() {
    return this.optionsObject.checkout_disclaimer;
  }

  getCheckoutLegalWarning() {
    return this.optionsObject.checkout_warning;
  }

  emailOnSignUpMode() {
    return this.optionsObject.registration_with_email;
  }

  phoneOnSignUpMode() {
    return this.optionsObject.registration_with_phone;
  }

  minimumAgeRecreational() {
    return this.optionsObject.minimum_age_recreational_allowed;
  }

  minimumAge() {
    return this.optionsObject.minimum_age_allowed;
  }

  schedulePageSize() {
    return this.optionsObject.schedule_page_size;
  }

  enabledFilters() {
    return this.optionsObject.enabled_filters;
  }

  priceFilterVisible() {
    return this.optionsObject.price_filter_visible;
  }

  getMedicalIdMode() {
    return this.optionsObject.medical_id_collect_mode;
  }

  saveCheckoutInfoByDefault(opts) {
    if (this.optionsObject.kiosk) return false;

    return (
      this.checkoutRequiresDocuments(opts) ||
      this.optionsObject.save_checkout_info_by_default
    );
  }

  checkoutDocumentConstraints({deliveryType, inventoryType}) {
    const {medicalId, driversLicense, selfieId} =
      CheckoutDocumentConstraint.DocumentTypes;

    return [
      {
        type: medicalId,
        constraint: this.getMedicalIdConstraintType(inventoryType),
      },
      {
        type: driversLicense,
        constraint: this.getDriversLicenceConstraintType(deliveryType),
      },
      {
        type: selfieId,
        constraint: this.getSelfieIdConstraintType(deliveryType),
      },
    ].map(obj => new CheckoutDocumentConstraint(obj.type, obj.constraint));
  }

  getDriversLicenceConstraintType = deliveryType => {
    let deliveryTypeCode =
      typeof deliveryType === "string" ? deliveryType : deliveryType?.code;
    return deliveryTypeCode === "delivery"
      ? this.getRequirementStatus(this.deliveryCheckoutDriversLicenseRequired())
      : this.getRequirementStatus(this.pickupCheckoutDriversLicenseRequired());
  };

  getMedicalIdConstraintType = inventoryType => {
    const {optional, required} = CheckoutDocumentConstraint.ConstraintTypes;
    const isRequired =
      (this.strictMedicalOrdersEnabled() && inventoryType === "medical") ||
      (this.allowMedicalCustomers() && !this.allowRecreationalCustomers());

    return isRequired ? required : optional;
  };

  getSelfieIdConstraintType = deliveryType => {
    let deliveryTypeCode =
      typeof deliveryType === "string" ? deliveryType : deliveryType?.code;
    return deliveryTypeCode === "delivery"
      ? this.getRequirementStatus(this.deliveryCheckoutSelfieIdUpload() === "required")
      : this.getRequirementStatus(this.pickupCheckoutSelfieIdUpload() === "required");
  };

  getRequirementStatus = requiredOption => {
    const {optional, required} = CheckoutDocumentConstraint.ConstraintTypes;
    return requiredOption ? required : optional;
  };

  checkoutRequiresDocuments(options) {
    return Boolean(this.checkoutDocumentConstraints(options).find(c => c.isRequired()));
  }

  deliveryCheckoutDriversLicenseRequired() {
    return this.optionsObject.delivery_checkout_drivers_license_required;
  }

  pickupCheckoutDriversLicenseRequired() {
    return this.optionsObject.pickup_checkout_drivers_license_required;
  }

  deliveryCheckoutSelfieIdUpload() {
    return this.optionsObject.delivery_checkout_with_selfie_id_upload;
  }

  pickupCheckoutSelfieIdUpload() {
    return this.optionsObject.pickup_checkout_with_selfie_id_upload;
  }

  scheduledPickupsEnabled() {
    return this.optionsObject.allow_scheduled_pickups;
  }

  scheduledDeliveriesEnabled() {
    return this.optionsObject.allow_scheduled_deliveries;
  }

  asapPickupsEnabled() {
    return this.optionsObject.pickup_asap_orders_allowed;
  }

  asapDeliveriesEnabled() {
    return this.optionsObject.delivery_asap_orders_allowed;
  }

  loyaltyTiersEnabled() {
    return this.optionsObject.loyalty_tiers_allowed;
  }

  loyaltyPointsEnabled() {
    return this.optionsObject.loyalty_points_allowed;
  }

  getLoginMode() {
    return this.optionsObject.login_mode;
  }

  isOffSiteKiosk() {
    return Boolean(this.optionsObject.offsite_kiosk);
  }

  getKioskUrl() {
    return this.optionsObject.kiosk_base_url;
  }

  getPersonalInfoDisclaimer() {
    return this.optionsObject.personal_info_disclaimer;
  }

  getOrderNumberSource() {
    return this.optionsObject.order_number_source;
  }

  getMarketingConsentSmsText() {
    return (
      this.optionsObject.marketing_consent_sms_text || "I want to receive marketing SMS."
    );
  }

  getMarketingConsentEmailText() {
    return (
      this.optionsObject.marketing_consent_email_text ||
      "I want to receive marketing emails."
    );
  }

  getRewardsText() {
    return this.optionsObject.rewards_text || "";
  }

  getTransactionTypeText() {
    return this.optionsObject.transaction_type_text;
  }

  allowTips() {
    return this.optionsObject.allow_tips;
  }

  deliveriesPath() {
    return this.optionsObject.deliveries_path;
  }

  getProductsDefaultOrder() {
    return this.optionsObject.products_default_order;
  }

  getCategoryDefaultProductOrder(categoryName) {
    const fallback = this.getProductsDefaultOrder();
    const getCategoryDefaultProductOrder =
      this.optionsObject.getCategoryDefaultProductOrder;

    if (typeof getCategoryDefaultProductOrder !== "function") return fallback;

    return getCategoryDefaultProductOrder(categoryName, fallback);
  }

  getShowDisclaimerCheckout() {
    return this.optionsObject.show_checkout_disclaimer;
  }

  getAsapHelperText() {
    return this.optionsObject.asap_helper_text;
  }

  getExpressHelperText() {
    return this.optionsObject.express_helper_text;
  }

  getScheduledHelperText() {
    return this.optionsObject.scheduled_helper_text;
  }

  getCategoryTitleTemplate() {
    return (
      this.optionsObject.category_title_template ||
      "%page_title% archives at %site_title%"
    );
  }

  getBrandTitleTemplate() {
    return (
      this.optionsObject.brand_title_template || "%page_title% archives at %site_title%"
    );
  }

  getDealTitleTemplate() {
    return (
      this.optionsObject.deal_title_template || "%page_title% archives at %site_title%"
    );
  }

  couponsDisabled() {
    return this.optionsObject.disabled_coupons || !this.optionsObject.enabled_coupons;
  }

  rewardsDisabled() {
    return this.optionsObject.disabled_rewards || !this.optionsObject.enabled_rewards;
  }

  marketingSignatureRequired() {
    return Boolean(this.optionsObject.marketing_signature_required);
  }

  showRecommendedProductsInCart() {
    return this.optionsObject.show_recommended_products_in_cart;
  }

  getDirectToConsumerFallbackUrl() {
    return this.optionsObject.brand_dtc_fallback_url;
  }

  allowMultiCoupons() {
    return this.optionsObject.supports_multiple_promotions;
  }

  getDefaultCountry() {
    return this.optionsObject.default_country;
  }

  allowPickupCheckoutNotes() {
    return this.optionsObject.allow_pickup_checkout_notes;
  }

  strongholdOnBoardingEnabled() {
    return this.optionsObject.stronghold_onboarding === "true";
  }

  strictMedicalOrdersEnabled() {
    return this.optionsObject.strict_medical_orders;
  }

  registrationWithDriversLicenseID() {
    return this.optionsObject.registration_with_drivers_license_id;
  }

  getAllowedCountryPhoneNumbers() {
    if (this.optionsObject.allowed_country_phone_numbers) {
      return this.optionsObject.allowed_country_phone_numbers.split(",");
    } else {
      return null;
    }
  }

  getOrderBrandsAlphabeticallyInFilters() {
    return this.optionsObject.order_brands_alphabetically_in_filters;
  }

  registrationWithStateResidency() {
    return this.optionsObject?.registration_with_state_residency;
  }

  defaultStateResidency() {
    return this.optionsObject?.state_residency_default;
  }

  forceDeliveryTypeSelection() {
    return this.optionsObject?.force_delivery_type_selection;
  }

  getIdentityVerificationService() {
    return this.optionsObject.identity_verification_service;
  }

  hideCheckoutOrderNotes() {
    return Boolean(this.optionsObject?.hide_order_checkout_notes);
  }

  showAndroidMobileAppDownloadPrompt() {
    return this.optionsObject.android_menu_app_promotion;
  }

  showIOSMobileAppDownloadPrompt() {
    return this.optionsObject.ios_menu_app_promotion;
  }

  iosShowSmartBanner() {
    return this.optionsObject.ios_show_smart_banner;
  }

  androidShowSmartBanner() {
    return this.optionsObject.android_show_smart_banner;
  }

  appStoreUrl() {
    return this.optionsObject.ios_app_public_url;
  }

  googlePlayUrl() {
    return this.optionsObject.android_app_public_url;
  }

  appStoreAppId() {
    return this.appStoreUrl()?.split("/id").pop();
  }

  googlePlayAppId() {
    return this.googlePlayUrl()?.split("?id=").pop();
  }

  inStoreExperienceEnabled() {
    return this.optionsObject.enable_scan_to_shop;
  }

  hasInStoreExperienceEventFlow() {
    return (
      this.optionsObject.enable_scan_to_shop &&
      !this.optionsObject.allow_scan_to_shop_menu_web
    );
  }

  hasAnnouncementMessage() {
    return this.optionsObject?.allow_announcement_message;
  }

  announcementMessageTitle() {
    return this.optionsObject?.announcement_message_title;
  }

  announcementMessageDescription() {
    return this.optionsObject?.announcement_message_description;
  }

  includeTipInOrderTotal() {
    return this.optionsObject?.include_tip_in_pos_order;
  }

  showDownloadIOSAppAfterCheckout() {
    return this.optionsObject?.ios_checkout_app_promotion;
  }

  showDownloadAndroidAppAfterCheckout() {
    return this.optionsObject?.android_checkout_app_promotion;
  }

  showDealsList() {
    return this.optionsObject?.show_menu_deals;
  }

  allowOnlySingleUnitCartItems() {
    return this.optionsObject?.allow_only_single_unit_cart_items;
  }
}

export default Options;
