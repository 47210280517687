import {getSiteURL} from "../../meta";
import dynamic from "next/dynamic";

function makeLogoSchemaObject(site, ctx) {
  const logo = dynamic(() => document.getElementById("logo"), {ssr: false});

  return {
    "@type": "ImageObject",
    "@id": `${getSiteURL(ctx)}#logo`,
    url: logo ? logo.src : undefined,
    width: logo ? logo.width : undefined,
    height: logo ? logo.height : undefined,
    caption: site.getName(),
  };
}

export default makeLogoSchemaObject;
